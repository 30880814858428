import { ShopState } from "@/graphql/Fragments/Shop";

const USER = {
  authToken: "auth-token",
  isLoggedIn: "isLoggedIn",
};

const SHOP = {
  SECTIONS: {
    orders: "orders",
    machines: "machines",
    materials: "materials",
    info: "info",
    preferences: "preferences",
    SETUP: {
      general: "general",
    },
    jobs: "jobs",
  },
  DRAFT_LABEL: ShopState.DRAFT,
};

export const ORDER = {
  ITEM: {
    MAX_QUANTITY: 9999,
    MAX_PRICE_IN_FRACTIONAL_UNITS: 2100000000,
  },
};

type UrlParamType = number | string | undefined;

const ERRORS = {
  UNAUTHENTICATED: "UNAUTHENTICATED",
  INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
  FORBIDDEN: "FORBIDDEN",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  NOT_FOUND: "NOT_FOUND",
  COMPANY_NOT_FOUND: "COMPANY_NOT_FOUND",
  // Must match shop-server LicenseExpiredError
  LICENSE_EXPIRED: "LICENSE_EXPIRED",
  // Must match shop-server NoLicenseError
  NO_LICENSE: "NO_LICENSE",
  NO_CUSTOMER_LICENSE: "NO_CUSTOMER_LICENSE",
};

const SHOP_ROUTES = (shopId?: UrlParamType, isSetup: boolean = false) => {
  const shopRoot = `/shops${isSetup ? "/setup" : ""}${shopId ? `/${shopId}` : ""}`;
  const machinesRoot = `${shopRoot}/machines`;
  return {
    GENERAL: shopRoot,
    INFO: `${shopRoot}/info`,
    PREFERENCES: `${shopRoot}/preferences`,
    ORDER: {
      SHOW: (orderId?: UrlParamType) => `${shopRoot}/${SHOP.SECTIONS.orders}/${orderId}`,
      NEW: `${shopRoot}/${SHOP.SECTIONS.orders}/new`,
    },
    ORDERS: `${shopRoot}`,
    MACHINES: {
      SHOW: (
        {
          subSection,
          machineId,
        }: {
          subSection?: UrlParamType;
          machineId?: UrlParamType;
        } = {
          subSection: ":subSection?",
          machineId: ":machineId?",
        }
      ) =>
        `${machinesRoot}${machineId ? `/${machineId}` : ""}${subSection ? `/${subSection}` : ""}`,
      EDIT: (machineId?: UrlParamType) =>
        `/shops/${shopId}/machines${machineId ? `/${machineId}` : ""}`,
      NEW: `${machinesRoot}/new`,
      INDEX: `${machinesRoot}/technology`,
      TECHNOLOGY: (technologyId: UrlParamType) => `${machinesRoot}/technology/${technologyId}`,
      MACHINE: (technologyId: UrlParamType, machineId: UrlParamType) =>
        `${machinesRoot}/technology/${technologyId}/machine/${machineId}`,
    },
    JOBS: {
      LIST: `${shopRoot}/${SHOP.SECTIONS.jobs}`,
      NEW: `${shopRoot}/${SHOP.SECTIONS.jobs}/new`,
      SHOW: (jobId?: UrlParamType) => `${shopRoot}/${SHOP.SECTIONS.jobs}/${jobId}`,
    },
  };
};

const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  SHOP: (shopId: UrlParamType, isSetup?: boolean) => SHOP_ROUTES(shopId, isSetup),
  NOT_FOUND: "/404",
  EXPIRED: "/expired",
  NO_LICENSE: "/no_license",
  UNSUBSCRIBE: (orderId: UrlParamType) => `/unsubscribe/order/${orderId}`,
};

export { USER, SHOP, ERRORS, ROUTES };

/**
 * The values in this enum match the values of AppTechnology.name as seeded by the db migration scripts
 * What's the point of having them both as enum values and db entries you ask?
 * Good question.
 * There is a possibility of there being more values in the database than in this enum. This
 * enum exists in able to define specific behavior for certain technologies.
 * Arguably such "metadata" about the technologies should also be included in the
 * AppTechnology class itself but for moment since this is confined to front end code we are
 * doing it this way
 */
export const enum TECHNOLOGY {
  POLYJET = "PolyJet",
  FDM = "FDM",
  SAF = "SAF",
  P3 = "P3",
  CNC = "CNC",
  VAT_PHOTOPOLYMERIZATION = "Vat Photopolymerization",
  LASER_PROCESSING = "Laser Processing",
  WATERJET = "Waterjet",
  BINDER_JETTING = "Binder Jetting",
  DED = "DED",
  RESIN_BASED = "Resin-based (SLA, DLP)",
  POWDER_BED = "Powder Bed (SLS)",
  MATERIAL_EXTRUSION = "Material Extrusion",
  MATERIAL_JETTING = "Material Jetting",
  // The following exist in the db. It is debatable whether they should be defined here or not...
  CUSTOM = "Custom",
  ANY = "Any",
}

// This is in the reverse order of what users see at the top of sorted lists. See ssysTechnologiesToBeginning
export const STRATASYS_TECHNOLOGIES = [
  TECHNOLOGY.P3,
  TECHNOLOGY.SAF,
  TECHNOLOGY.POLYJET,
  TECHNOLOGY.FDM,
];

export const SUPPORTLESS_TECHNOLOGIES = [
  TECHNOLOGY.CNC,
  TECHNOLOGY.VAT_PHOTOPOLYMERIZATION,
  TECHNOLOGY.LASER_PROCESSING,
  TECHNOLOGY.WATERJET,
];

export enum TIME_UNITS {
  HOURS = "HOURS",
  MINUTES = "MINUTES",
}

export enum MATERIAL_UNITS {
  KG = "KG",
  G = "G",
  LB = "LB",
  OZ = "OZ",
  IN3 = "IN3",
  CM3 = "CM3",
  L = "L",
  ML = "ML",
  IN2 = "IN2",
  CM2 = "CM2",
  IN = "IN",
  CM = "CM",
}

// A bit confused because KG is a unit of mass, not weight. "pound" is a bit ambiguous
export const WEIGHT_UNITS = [
  MATERIAL_UNITS.KG,
  MATERIAL_UNITS.G,
  MATERIAL_UNITS.LB,
  MATERIAL_UNITS.OZ,
];
export const LENGTH_UNITS = [MATERIAL_UNITS.IN, MATERIAL_UNITS.CM];
export const AREA_UNITS = [MATERIAL_UNITS.IN2, MATERIAL_UNITS.CM2];
export const VOLUME_UNITS = [
  MATERIAL_UNITS.IN3,
  MATERIAL_UNITS.CM3,
  MATERIAL_UNITS.L,
  MATERIAL_UNITS.ML,
];

export type WEIGHT_UNIT =
  | MATERIAL_UNITS.KG
  | MATERIAL_UNITS.G
  | MATERIAL_UNITS.LB
  | MATERIAL_UNITS.OZ;
export type LENGTH_UNIT = MATERIAL_UNITS.IN | MATERIAL_UNITS.CM;
export type AREA_UNIT = MATERIAL_UNITS.IN2 | MATERIAL_UNITS.CM2;
export type VOLUME_UNIT =
  | MATERIAL_UNITS.IN3
  | MATERIAL_UNITS.CM3
  | MATERIAL_UNITS.L
  | MATERIAL_UNITS.ML;

/**
 * "Quantity" seems to be the most used word for the property that is measured in a unit
 * See table here for example https://en.wikipedia.org/wiki/International_System_of_Units
 */
export enum MATERIAL_QUANTITY {
  VOLUME = "VOLUME",
  WEIGHT = "WEIGHT", // should be mass really
  LENGTH = "LENGTH",
  AREA = "AREA",
}

export const ALL_MATERIAL_QUANTITIES = [
  MATERIAL_QUANTITY.VOLUME,
  MATERIAL_QUANTITY.WEIGHT,
  MATERIAL_QUANTITY.AREA,
  MATERIAL_QUANTITY.LENGTH,
];

/**
 * This tells you what are the appropriate units for measuring a given quantity
 * The use of this is that for a printer whose material is measured in volume
 * the appropriate units are VOLUME_UNITS, i.e. IN3 etc.
 */
export const MATERIAL_QUANTITY_UNITS: Record<MATERIAL_QUANTITY, Array<MATERIAL_UNITS>> = {
  VOLUME: VOLUME_UNITS,
  WEIGHT: WEIGHT_UNITS,
  LENGTH: LENGTH_UNITS,
  AREA: AREA_UNITS,
};

/**
 * This tells us what quantities (weight, volume) a given technology's
 * material is measured in. This then gives us the units.
 * I think there really only ought to be one quantity per technology and the fact that there
 * is an array for some just means that we don't know for sure.
 * Anything that is not specified here will end up with the user being being able to choose any quantity
 */
const TECHNOLOGY_MATERIAL_QUANTITY: Partial<Record<TECHNOLOGY, MATERIAL_QUANTITY[]>> = {
  FDM: [MATERIAL_QUANTITY.VOLUME],
  PolyJet: [MATERIAL_QUANTITY.WEIGHT],
  SAF: [MATERIAL_QUANTITY.WEIGHT],
  P3: [MATERIAL_QUANTITY.VOLUME, MATERIAL_QUANTITY.WEIGHT],
};
const TECHNOLOGY_SUPPORT_QUANTITY: Partial<Record<TECHNOLOGY, MATERIAL_QUANTITY[]>> = {
  ...TECHNOLOGY_MATERIAL_QUANTITY,
  SAF: [MATERIAL_QUANTITY.VOLUME],
};

/**
 * This overrides TECHNOLOGY_MATERIAL_QUANTITY for specific machines.
 * The machine names are notionally data in a db table so strictly speaking we should
 * avoid referencing particular values in code but this is such a niche special case
 * for Makerbot that it didn't seem worth doing by adding this property as data.
 * The keys are capitalized because we are inconsistently writing "Makerbot" vs "MakerBot"
 * at the time of writing.
 */
const MACHINE_MATERIAL_QUANTITY_OVERRIDES: Partial<Record<string, MATERIAL_QUANTITY[]>> = {
  MAKERBOT: [MATERIAL_QUANTITY.WEIGHT],
  "MAKERBOT METHOD SERIES": [MATERIAL_QUANTITY.WEIGHT],
  "MAKERBOT REPLICATOR SERIES": [MATERIAL_QUANTITY.WEIGHT],
  "MAKERBOT SKETCH": [MATERIAL_QUANTITY.WEIGHT],
};

const MACHINE_SUPPORT_QUANTITY_OVERRIDES = MACHINE_MATERIAL_QUANTITY_OVERRIDES;

export const DEFAULT_TIME_UNIT = TIME_UNITS.MINUTES;
export const QUANTITY_DEFAULT_UNIT: Record<MATERIAL_QUANTITY, MATERIAL_UNITS> = {
  VOLUME: MATERIAL_UNITS.IN3,
  WEIGHT: MATERIAL_UNITS.KG,
  AREA: MATERIAL_UNITS.IN2,
  LENGTH: MATERIAL_UNITS.IN,
};

/**
 * See definition of consts above.
 * I put these functions here in a "consts" file as it was a way of not exporting the consts from
 * this file and forcing developers to use these methods instead
 */
export const getMachineTechnologyMaterialQuantity = (
  technology?: TECHNOLOGY,
  machineDisplayName?: string
): MATERIAL_QUANTITY[] | undefined => {
  if (
    machineDisplayName &&
    MACHINE_MATERIAL_QUANTITY_OVERRIDES[machineDisplayName?.toUpperCase()]
  ) {
    return MACHINE_MATERIAL_QUANTITY_OVERRIDES[machineDisplayName?.toUpperCase()];
  }
  if (technology && TECHNOLOGY_MATERIAL_QUANTITY[technology]) {
    return TECHNOLOGY_MATERIAL_QUANTITY[technology];
  }
  return undefined;
};

export const getMachineTechnologyDefaultMaterialUnit = (
  technology?: TECHNOLOGY,
  machineDisplayName?: string
): MATERIAL_UNITS => {
  const materialQuantities = getMachineTechnologyMaterialQuantity(
    technology,
    machineDisplayName
  ) ?? [MATERIAL_QUANTITY.WEIGHT];
  return QUANTITY_DEFAULT_UNIT[materialQuantities[0]];
};

export const getMachineTechnologySupportQuantity = (
  technology?: TECHNOLOGY,
  machineDisplayName?: string
): MATERIAL_QUANTITY[] | undefined => {
  if (machineDisplayName && MACHINE_SUPPORT_QUANTITY_OVERRIDES[machineDisplayName?.toUpperCase()]) {
    return MACHINE_SUPPORT_QUANTITY_OVERRIDES[machineDisplayName?.toUpperCase()];
  }
  if (technology && TECHNOLOGY_SUPPORT_QUANTITY[technology]) {
    return TECHNOLOGY_SUPPORT_QUANTITY[technology];
  }
  return undefined;
};

export const getMachineTechnologyDefaultSupportUnit = (
  technology?: TECHNOLOGY,
  machineDisplayName?: string
): MATERIAL_UNITS => {
  if (technology === "SAF") {
    return MATERIAL_UNITS.ML;
  }
  const supportQuantities = getMachineTechnologySupportQuantity(technology, machineDisplayName) ?? [
    MATERIAL_QUANTITY.WEIGHT,
  ];
  return QUANTITY_DEFAULT_UNIT[supportQuantities[0]];
};

export const MAX_UPLOAD_FILE_SIZE = 3 * 1024; // in megabytes, = 3GB
