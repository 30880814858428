import React, { createContext, useState, useContext } from "react";
import { Grid, GridRow, GridColumn } from "grabcad-ui-elements";
import { SHOP } from "@/shopConstants";
import { General } from "@/screens/Shop/New/Sections";
import { RouteComponentProps, withRouter } from "react-router";
import { ShopSetupFooter } from "@/components/Shop/Setup/Footer";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ScreensShopMachines } from "../Machine/Machines";

interface IScreensShopNewProps {
  section: string;
  shopId: string | undefined;
}

interface IShopFormContext {
  generalCompleted: boolean;
  machinesCompleted: boolean;
  materialsCompleted: boolean;
  setGeneralCompleted: (value: boolean) => void;
  setMachinesCompleted: (value: boolean) => void;
  section: string;
}

export const ShopFormContext = createContext({} as IShopFormContext);
ShopFormContext.displayName = "ShopFormContext";

const getSection = (section: string): JSX.Element => {
  switch (section) {
    case SHOP.SECTIONS.SETUP.general:
      return <General />;

    case SHOP.SECTIONS.machines:
      return <ScreensShopMachines isSetup={true} />;

    default:
      return <General />;
  }
};

export const ScreensShopNew = withRouter(
  ({
    match: {
      params: { shopId },
    },
    section,
  }: RouteComponentProps<IScreensShopNewProps> & { section?: string }) => {
    const selectedSectionForm = getSection(section || SHOP.SECTIONS.SETUP.general);
    const { currentShop } = useContext(ApplicationContext);

    const [generalCompleted, setGeneralCompleted] = useState(!!currentShop);
    const [machinesCompleted, setMachinesCompleted] = useState(
      (currentShop?.shopMachines?.length || 0) > 0
    );
    const materialsCompleted = !!currentShop?.shopMachines?.some(
      shopMachine => shopMachine.materials.length > 0
    );

    return (
      <ShopFormContext.Provider
        value={{
          generalCompleted,
          machinesCompleted,
          materialsCompleted,
          setGeneralCompleted,
          setMachinesCompleted,
          section: section || SHOP.SECTIONS.SETUP.general,
        }}
      >
        <Grid>
          <GridRow>
            <GridColumn>{selectedSectionForm}</GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              <ShopSetupFooter />
            </GridColumn>
          </GridRow>
        </Grid>
      </ShopFormContext.Provider>
    );
  }
);
ScreensShopNew.displayName = "ScreensShopNew";
