import React, { useContext, useState, useEffect, SyntheticEvent } from "react";
import { Notifier } from "@/utils/Notifier";
import { ApplicationContext, TranslateFunction } from "@/components/ApplicationProvider";
import { CadDropZone, IUploadContainer } from "@/components/Upload/CadDropZone";
import {
  REMOVE_ORDER_ITEMS_DEPENDENCIES,
  UPDATE_ORDER_ITEM_DEPENDENCIES,
} from "@/graphql/Mutations/OrderItem";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { ApolloConsumer, ApolloError, DataProxy, useApolloClient } from "@apollo/client";
import {
  isCadModelFilter,
  isGenericFileFilter,
  isImageFileFilter,
} from "@/components/Order/Form/Form";
import { ICadFile, IGenericFile } from "@/graphql/Mutations/Upload";
import { IImage } from "@/graphql/Fragments/Image";
import styled, { Icon, Image, Message, Popup, TableCell, TableRow } from "grabcad-ui-elements";
import { ORDER_DETAILS } from "@/graphql/Queries";
import { AssetType, getUrlAndDownload, IUrlDownloadProps } from "./DownloadButton";
import ReactGA from "react-ga";
import { ConfirmationModal } from "./ConfirmationModal";
import FileTypeCad from "@/assets/icons/file-type-cad.svg";
import FileTypeCmb from "@/assets/icons/file-type-cmb.svg";
import FileTypeImage from "@/assets/icons/file-type-image.svg";
import FileTypePdf from "@/assets/icons/file-type-pdf.svg";
import FileTypePrint from "@/assets/icons/file-type-print.svg";
import FileTypeSpreadSheet from "@/assets/icons/file-type-spreadsheet.svg";
import FileTypeTextDoc from "@/assets/icons/file-type-text-doc.svg";
import FileTypeZip from "@/assets/icons/file-type-zip.svg";
import Download from "@/assets/icons/download.svg";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { getCadModelName, isGcpAssetFileName } from "@/utils/GeneralUtils";
import { StyledProgress } from "./StyledProgress";

export const IconButton = styled.div`
  cursor: pointer;
  padding: 1px;
  border-radius: 5px;
  height: 24px;
  width: 24px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    background-color: #e0ecfc;
  }
`;

export const DownloadIconButton = styled(IconButton)`
  &:hover {
    opacity: 1;
    background-color: #e8e8e8;
  }
`;

export const DependencyFileRow = styled.div`
  .ui.message {
    box-shadow: none;
    padding: 0;
    background: #f4f5f7;
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .image-container {
      flex: 60px 0 0;
      height: 30px;
      padding: 3px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .name-n-status {
      flex: auto 1 1;
      overflow: hidden;
      strong,
      div {
        word-break: break-word;
        @supports (-ms-ime-align: auto) {
          word-break: break-all;
        }
      }
    }
    .quantity {
      flex: 70px 0 0;
      margin-left: 5px;
    }
  }
`;

const DependenciesCell = styled(TableCell)`
  width: 100%;
  border: none !important;
`;

interface IDependenciesToRemove {
  orderItemIdsToRemove?: number[];
  imageIdsToRemove?: number[];
  genericFileIdsToRemove?: number[];
}

const composeFileRow = (
  id: number,
  fileName: string,
  imgSrc: string,
  deleteButton: JSX.Element | null,
  assetType: AssetType,
  cadModelId?: number
) => (
  <DependencyFileRow
    key={`dependencyRow_${id}`}
    className={"qa-dependencyCadRow"}
    id={`qa-dependencyRow_${id}`}
    onClick={(event: SyntheticEvent) => {
      event.stopPropagation();
    }}
  >
    <Message>
      <div className="image-container" data-testid="dependencyRowMessageImageContainer">
        <Image src={imgSrc} />
      </div>
      <div className="name-n-status" data-testid="dependencyRowMessageNameNStatus">
        <strong className="">{fileName}</strong>
        {/* This space can be used to show bounding box dimensions if required later */}
        {/* <div className="status">&nbsp;</div> */}
      </div>
      <DownloadItemButton id={cadModelId ? cadModelId : id} assetType={assetType} />
      {deleteButton}
    </Message>
  </DependencyFileRow>
);

export const composeDependencyUploadRows = (
  uploadedFiles: IUploadContainer[],
  t: TranslateFunction
): JSX.Element => {
  const result: JSX.Element[] = [];
  uploadedFiles.map((container: IUploadContainer) => {
    const { cadFile, file, cancel } = container;

    const imgSrc = getFileTypeIcon(file.name);
    let statusContent = <>{t("order.form.uploader.converted")}</>;
    let uploadProgress = <></>;

    if (!cadFile) {
      // file upload is in progress
      const { progress, loaded, total, error } = container;
      statusContent = (
        <>
          {t("order.form.uploader.uploading")}
          <span className="kb">{` (${Math.round(loaded / 1024)} / ${Math.round(
            total / 1024
          )} KB)`}</span>
        </>
      );
      uploadProgress = (
        <StyledProgress
          percent={progress}
          size="tiny"
          color={error ? "red" : cadFile ? "green" : "blue"}
          attached="bottom"
        />
      );
    }
    result.push(
      <DependencyFileRow
        key={`uploadRow_${container.key}`}
        className={"qa-uploadRow_"}
        id={`qa-dependencyRow_${container.key}`}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();
        }}
      >
        <Message>
          <div className="image-container">
            <Image src={imgSrc} />
          </div>
          <div className="name-n-status">
            <strong className="">{file.name}</strong>
            <div className="status">{statusContent}</div>
          </div>
          <Icon
            onClick={(event: SyntheticEvent) => {
              cancel();
              event.stopPropagation();
            }}
            name={"cancel"}
          />
          {uploadProgress}
        </Message>
      </DependencyFileRow>
    );
  });
  return <>{result}</>;
};

const DownloadItemButton = (props: IUrlDownloadProps) => {
  const { currentShop, t } = useContext(ApplicationContext);
  return (
    <ApolloConsumer>
      {client => (
        <Popup
          trigger={
            <IconButton
              key={`downloadBtn_${props.id}`}
              className={"qa-download-orderItemDependency"}
              style={{ marginRight: "15px" }}
              onClick={(event: SyntheticEvent) => {
                getUrlAndDownload(client, props);
                ReactGA.event({
                  category: "GcShop File Dependency Download",
                  action: "OrderItem file Dependency downloaded",
                  label: `Shop ${currentShop?.id}`,
                });
                event.stopPropagation();
              }}
              data-testid="downloadOrderItemDependencyButton"
            >
              <Image src={Download} style={{ paddingTop: "3px", paddingLeft: "5px" }} />
            </IconButton>
          }
          content={t("order.items.dependency.download")}
          offset={[0, 5]}
          position={"bottom right"}
          inverted
        />
      )}
    </ApolloConsumer>
  );
};

const getFileTypeIcon = (filename: string): string => {
  if (filename.toLowerCase().endsWith(".cmb.gz")) {
    return FileTypeCmb;
  }
  const extension = filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
  switch (extension.toLowerCase()) {
    case "cmb":
      return FileTypeCmb;
    case "pdf":
      return FileTypePdf;
    case "print":
    case "print-fdm":
      return FileTypePrint;
    case "csv":
    case "xls":
    case "xlsx":
      return FileTypeSpreadSheet;
    case "txt":
    case "doc":
    case "docx":
      return FileTypeTextDoc;
    case "zip":
    case "rar":
    case "7z":
      return FileTypeZip;
    default:
      return FileTypeCad;
  }
};

export const OrderItemDependenciesPanel = ({
  orderId,
  orderItem,
  openFileDialogCounter,
  open,
  isWritable,
}: {
  orderId: number;
  orderItem: IOrderItem;
  openFileDialogCounter: number;
  open: boolean;
  isWritable: boolean;
}): JSX.Element => {
  const { currentShop, t } = useContext(ApplicationContext);
  const [files, setFiles] = useState<IUploadContainer[]>([]);
  const [allUploaded, setAllUploaded] = useState<boolean>(false);
  const [itemsToDelete, setItemsToDelete] = useState<IDependenciesToRemove | undefined>(undefined);
  const client = useApolloClient();
  const composeDeleteButton = (input: IDependenciesToRemove) => (
    <Popup
      trigger={
        <IconButton
          key={`deleteBtn_${orderItem.id}`}
          style={{ marginRight: "15px" }}
          className={"qa-delete-orderItemDependency"}
          onClick={(event: SyntheticEvent) => {
            setItemsToDelete(input);
            event.stopPropagation();
          }}
          data-testid="deleteOrderItemDependencyButton"
        >
          <Icon name={"trash"} style={{ paddingTop: "1px", paddingLeft: "4px" }} />
        </IconButton>
      }
      content={t("order.items.dependency.remove")}
      offset={[0, 5]}
      position={"bottom right"}
      inverted
    />
  );

  const cadDropZone = (
    <CadDropZone
      key={`dropZoneOrderItem_${orderItem.id}`}
      shop={currentShop}
      onFileAction={cadContainer => {
        setFiles(prevFiles => {
          const index = prevFiles.findIndex(i => i.key === cadContainer.key);
          let file = prevFiles[index];
          if (file) {
            file = { ...file, ...cadContainer };
            const newState = [...prevFiles.slice(0, index), file, ...prevFiles.slice(index + 1)];
            setAllUploaded(
              newState.every(item => item.uploaded && !!item.cadFile) &&
                newState.filter(item => !item.cancelled).length > 0
            );
            return newState;
          }
          return [...prevFiles, cadContainer];
        });
      }}
      openFileDialogCounter={openFileDialogCounter}
      uploadedFiles={files}
      showCTA={false}
      isOrderDependencyUpload={true}
    />
  );

  const updateOrderItemDependencies = async () => {
    if (files.length > 0 && allUploaded) {
      const orderItemIdsToAdd = files
        .filter(isCadModelFilter)
        .map(filtered => (filtered.cadFile as ICadFile).id);
      const imageIdsToAdd = files
        .filter(isImageFileFilter)
        .map(filtered => (filtered.cadFile as IImage).id);
      const genericFileIdsToAdd = files
        .filter(isGenericFileFilter)
        .map(filtered => (filtered.cadFile as IGenericFile).id);

      if (!orderItemIdsToAdd?.length && !imageIdsToAdd?.length && !genericFileIdsToAdd?.length) {
        return;
      }

      try {
        // generate OrderItem instances for the supplied CAD files.
        await client.mutate({
          mutation: UPDATE_ORDER_ITEM_DEPENDENCIES,
          refetchQueries: [{ query: ORDER_DETAILS, variables: { orderId } }],
          update: async (cache: DataProxy) => fetchNewOrderEvents(cache, client, orderId),
          variables: {
            id: orderItem.id,
            input: { orderItemIdsToAdd, imageIdsToAdd, genericFileIdsToAdd },
          },
        });

        Notifier.success(
          <>
            {files.length}{" "}
            {files.length === 1
              ? t("order.items.added.success.singular")
              : t("order.items.added.success.plural")}
          </>
        );
        setFiles([]);
      } catch (error) {
        if (error instanceof ApolloError) {
          Notifier.error({ graphQLErrors: error.graphQLErrors });
        } else {
          throw error;
        }
      }
    }
  };

  const removeOrderItemDependencies = async () => {
    if (!itemsToDelete) {
      return;
    }
    const input: IDependenciesToRemove = itemsToDelete;
    const { orderItemIdsToRemove, imageIdsToRemove, genericFileIdsToRemove } = input;

    let removeDependenciesCount = 0;
    removeDependenciesCount += orderItemIdsToRemove?.length ? orderItemIdsToRemove.length : 0;
    removeDependenciesCount += imageIdsToRemove?.length ? imageIdsToRemove.length : 0;
    removeDependenciesCount += genericFileIdsToRemove?.length ? genericFileIdsToRemove.length : 0;

    if (removeDependenciesCount === 0) {
      return;
    }

    try {
      await client.mutate({
        mutation: REMOVE_ORDER_ITEMS_DEPENDENCIES,
        // TODO: Avoid this refetch?
        refetchQueries: [{ query: ORDER_DETAILS, variables: { orderId } }],
        update: async (cache: DataProxy) => fetchNewOrderEvents(cache, client, orderId),
        variables: {
          id: orderItem.id,
          input: { orderItemIdsToRemove, imageIdsToRemove, genericFileIdsToRemove },
        },
      });

      Notifier.success(
        <>
          {removeDependenciesCount}{" "}
          {removeDependenciesCount === 1
            ? t("order.items.removed.success.singular")
            : t("order.items.removed.success.plural")}
        </>
      );
      setItemsToDelete(undefined);
    } catch (error) {
      if (error instanceof ApolloError) {
        Notifier.error({ graphQLErrors: error.graphQLErrors });
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    updateOrderItemDependencies();
  }, [files, allUploaded]);

  const children = orderItem.children?.length ? orderItem.children : [];
  const childImages = orderItem.childImages?.length ? orderItem.childImages : [];
  const childFiles = orderItem.childFiles?.length ? orderItem.childFiles : [];
  const hasDependencies = children.length + childImages.length + childFiles.length > 0;

  const childrenRows: JSX.Element[] = [];

  if (hasDependencies) {
    children.map((child: IOrderItem) => {
      const { cadModel } = child;
      const cadModelId = cadModel?.original ? cadModel.id : undefined;
      const filename = getCadModelName(child) || "";
      const imgSrc = isGcpAssetFileName(filename)
        ? getFileTypeIcon(filename)
        : cadModel?.preview
        ? cadModel.preview.small.location
        : FileTypeCad;
      const deleteButton = isWritable
        ? composeDeleteButton({ orderItemIdsToRemove: [child.id] })
        : null;
      childrenRows.push(
        composeFileRow(child.id, filename, imgSrc, deleteButton, "cad_model", cadModelId)
      );
    });

    childImages.map((img: IImage) => {
      const imgSrc = img?.small?.location ? img.small.location : FileTypeImage;
      const filename = img?.original?.originalName;
      const deleteButton = isWritable ? composeDeleteButton({ imageIdsToRemove: [img.id] }) : null;
      childrenRows.push(composeFileRow(img.id, filename, imgSrc, deleteButton, "image"));
    });

    childFiles.map((file: IGenericFile) => {
      const deleteButton = isWritable
        ? composeDeleteButton({ genericFileIdsToRemove: [file.id] })
        : null;
      const filename = file.asset.originalName;
      const imgSrc = getFileTypeIcon(filename);
      childrenRows.push(composeFileRow(file.id, filename, imgSrc, deleteButton, "generic_file"));
    });
  }

  const confirmModal = (
    <ConfirmationModal
      headerIcon={"trash"}
      key={`confirm_${orderItem.id}`}
      bodyCopy={t("order.items.dependency.remove.confirm.singular")}
      headerCopy={t("order.items.dependency.remove.confirm")}
      cancelTranslationKey={"order.items.delete.cancel"}
      confirmTranslationKey={"order.items.delete"}
      open={!!itemsToDelete}
      onClose={() => {
        setItemsToDelete(undefined);
      }}
      submitAction={() => {
        removeOrderItemDependencies();
      }}
    />
  );

  // when files length is bigger than 0 it indicates file(s) being uploaded
  const display = files.length > 0 || (open && hasDependencies) ? "block" : "none";

  return (
    <TableRow>
      <DependenciesCell className="dependenciesCell" style={{ display }}>
        <div>{childrenRows}</div>
        <div className="qa-dependencyDropZone" data-testid="dependencyDropZone">
          {cadDropZone}
        </div>
        {confirmModal}
      </DependenciesCell>
    </TableRow>
  );
};
