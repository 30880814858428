import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { config } from "./config";
import { onError } from "@apollo/client/link/error";
import { ERRORS, ROUTES } from "@/shopConstants";
import { customFetch } from "@/utils/uploaderProgress";
import { ConnectionParams } from "subscriptions-transport-ws";
import { OperationDefinitionNode } from "graphql";
import { v4 } from "uuid";

// TODO: Generate possibleTypes?
// https://www.apollographql.com/docs/react/data/fragments/
export const possibleTypes = {
  OrderItemWithImageAndFiles: ["OrderItem", "Image", "GenericFile"],
  EventMetadata: [
    "StatusChangedEventMetadata",
    "FilesAddedEventMetadata",
    "OrderItemDeletedMetaData",
    "OrderDetailsChangedEventMetadata",
    "OrderOperatorChangedEventMetadata",
  ],
  UploadResult: ["Image", "CadModel", "GenericFile"],
};
const cache = new InMemoryCache({
  possibleTypes,
});

// FIXME GC-55392 Drop this and all references
export const COMPANY_SERVER_AUTH_ENABLED = true;

// createUploadLink has sample options as createHttpLink.
// https://github.com/jaydenseric/apollo-upload-client#function-createuploadlink
const uploadLink = createUploadLink({
  uri: ({ operationName }) =>
    (window as any).Cypress && operationName
      ? `${config.REACT_APP_SHOP_API_URL}?${operationName}`
      : config.REACT_APP_SHOP_API_URL || "",

  fetch: customFetch as any,
  // Company server authentication uses cookies.
  // Company server generates them on redirects.
  // Shop server must receive them on GQL calls.
  // See https://www.apollographql.com/docs/react/recipes/authentication#cookie
  credentials: "include",
});

// Must match @grabcad/cls-middleware NPM module
const X_REQUEST_HEADER_NAME = "X-Request-ID";

function nextRequestId() {
  return `c:${v4().replace(/-/g, "")}`;
}

const authLink = setContext((_, { headers }) => ({
  headers: {
    // Include request ID to help debug requests end-to-end between client + server
    [X_REQUEST_HEADER_NAME]: nextRequestId(),
    ...headers,
    // non-Company server authentication uses HTTP headers
  },
}));

export let unblockWebSocketReady: () => void = () => {};

const webSocketReady = new Promise(res => {
  // Unblock WebSocketLink.
  // We don't need to worry about unblocking more than once
  // because the app is recreated on redirects between
  // company-server and shop-server (essentially resetting the gating).
  unblockWebSocketReady = res as any;
});

const wsLink = new WebSocketLink({
  uri: config.REACT_APP_SHOP_WS_URL || "ws://localhost",
  options: {
    reconnect: true,
    timeout: 40 * 1000,
    connectionParams: async (): Promise<ConnectionParams> => {
      // Stall creation of WebSockets until client is fully logged in
      // (i.e., token cookie is set by company-server login flow).
      // Otherwise, following can happen:
      // 1. WebSocket connects to shop-server [without proper credentials]
      // 2. shop-server will reject it
      // 3. client is forced to redirect to company-server /login page
      await webSocketReady;
      return {};
    },
  },
});

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
  // Cross cutting detection that GQL query/mutation failed.
  // Can happen on <UserLoader/> render or if tokens expire during the session.
  // TODO handle case ERRORS.INTERNAL_SERVER_ERROR
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.code) {
        const redirect_to = err.extensions.extraInfo?.redirect_to;
        // eslint-disable-next-line default-case
        switch (err.extensions.code) {
          case ERRORS.NOT_FOUND:
            setLocationIfDifferent(ROUTES.NOT_FOUND);
            break;
          case ERRORS.FORBIDDEN:
            setLocationIfDifferent(ROUTES.ROOT);
            break;
          case ERRORS.COMPANY_NOT_FOUND:
            if (redirect_to) {
              setLocationIfDifferent(redirect_to);
            }
            return forward(operation);
          case ERRORS.UNAUTHENTICATED:
          case ERRORS.INVALID_CREDENTIALS:
            // Can't use UserProvider.setAsLoggedOut because useContext is not supported yet
            // See https://github.com/apollographql/apollo-feature-requests/issues/64
            if (redirect_to) {
              const loginLoc = redirect_to + window.location;
              setLocationIfDifferent(loginLoc);
            }
            return forward(operation);
          case ERRORS.LICENSE_EXPIRED:
            setLocationIfDifferent(ROUTES.EXPIRED);
            break;
          case ERRORS.NO_LICENSE:
            setLocationIfDifferent(ROUTES.NO_LICENSE);
            break;
          case ERRORS.NO_CUSTOMER_LICENSE:
            setLocationIfDifferent(ROUTES.NO_LICENSE);
            break;
        }
      }
    }
  }
});

/**
 * Use this function instead of window.location.replace() directly.
 * Do this to avoid infinite reloads such as:
 * - Already on /expired page
 * - UserLoader.render()
 *    -> get LICENSE_EXPIRED error
 *    -> set window.location
 *    -> trigger another UserLoader.render()
 *    -> infinite loop
 */
function setLocationIfDifferent(path: string): void {
  if (window.location.pathname !== path) {
    window.location.replace(path);
  }
}

const regularLink = ApolloLink.from([errorLink, authLink, uploadLink]);

const link = authLink.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  regularLink
);

export const GraphQLClient = new ApolloClient({
  cache,
  link,
});
