import gql from "graphql-tag";
import { GRAPHQL_MODEL_UNITS } from "@/utils/DropdownUtils";
import { IAsset } from "../Mutations/Upload";

export type AutoEstimationMaterial = {
  isSupport: boolean;
  name: string;
  displayName: string;
  estimate: number;
  __typename: "AutoEstimationMaterial";
};

/**
 * For the purposes of GraphQL and the frontend, we define this merged
 * type of FDM or PJ parameters rather than a union
 * FdmParameters | PjParameters
 * because unions in GraphQL are a pain and overkill for what is actually
 * a set of key/value pairs anyway.
 * Arguably we should not have explicitly declared the property names
 * at all and defined parameters as a generic list of key / values
 */
export type FdmOrPjParameters = {
  // FDM...
  sliceHeight?: number; // in meters
  isAdaptiveSlice?: boolean;
  infillStyle?: string;
  infillDensity?: number; // fraction of 1
  supportStyle?: string;
  // PJ...
  // TODO GC-84021
  surfaceFinish?: string | null;
  printMode?: string | null;
  __typename: "FdmOrPjParameters";
};

export type OrientationDetails = {
  orientation: ORIENTATION;
  transform: number[];
  error?: AutoEstimationError | null;
};

export type AutoEstimationEstimate = {
  error?: AutoEstimationError | null;
  duration: number;
  materials: AutoEstimationMaterial[];
  parameters: FdmOrPjParameters;
  trayModels: ModelInfo[];
  orientationOptions: OrientationDetails[];
  __typename: "AutoEstimationEstimate";
};

export type AutoEstimationError = {
  name: string;
};

export type ModelInfo = {
  orderItemId: number;
  modelUnits: string;
  autoOrient: boolean;
  quantity: number;
  __typename: "ModelInfo";
};

export type GCPVersion = {
  major: number;
  minor: number;
  patch: number;
  meta: number;
};

export type AutoEstimationResultData = {
  error: AutoEstimationError | null;
  estimates: AutoEstimationEstimate[];
  printerModel?: string;
  printerTechnology?: string;
  gcpVersion: GCPVersion | null;
  __typename: "AutoEstimationResultData";
};

export enum ORIENTATION {
  AUTO = "AUTO",
  DEFAULT = "DEFAULT",
  FRONT = "FRONT",
  BACK = "BACK",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  TOP = "TOP",
}

export enum AssetType {
  AUTO_ESTIMATION = "AUTO_ESTIMATION",
  AUTO_ESTIMATION_SNAPSHOT = "AUTO_ESTIMATION_SNAPSHOT",
  ORIENTATION_AUTO = "ORIENTATION_AUTO",
  ORIENTATION_DEFAULT = "ORIENTATION_DEFAULT",
  ORIENTATION_FRONT = "ORIENTATION_FRONT",
  ORIENTATION_BACK = "ORIENTATION_BACK",
  ORIENTATION_LEFT = "ORIENTATION_LEFT",
  ORIENTATION_RIGHT = "ORIENTATION_RIGHT",
  ORIENTATION_TOP = "ORIENTATION_TOP",
}

export type AutoEstimationAsset = {
  id: number;
  // Per fields below we only retrieve a subset of fields for the assets of auto estimations
  asset: Pick<IAsset, "id" | "originalName" | "assetType">;
};

export type AutoEstimation = {
  id: number;
  queuePosition: number | null;
  progress: number | null;
  orderItemId?: number;
  jobId?: number;
  shopMaterialId: number;
  modelUnits: GRAPHQL_MODEL_UNITS | undefined | null;
  autoOrient: boolean;
  dateUpdated: string;
  dateDeleted?: string;
  resultData?: AutoEstimationResultData;
  autoEstimationAssets: AutoEstimationAsset[] | null;
  orientation?: ORIENTATION;
};

export const AutoEstimationFields = gql`
  fragment autoEstimationFields on AutoEstimation {
    id
    queuePosition
    progress
    shopMaterialId
    modelUnits
    autoOrient
    resultData {
      error {
        name
      }
      estimates {
        error {
          name
        }
        duration
        materials {
          isSupport
          name
          displayName
          estimate
        }
        parameters {
          sliceHeight
          isAdaptiveSlice
          infillStyle
          infillDensity
          supportStyle
          surfaceFinish
          printMode
        }
        trayModels {
          orderItemId
          modelUnits
          quantity
        }
        orientationOptions {
          orientation
          transform
          error {
            name
          }
        }
      }
      printerModel
      printerTechnology
      gcpVersion {
        major
        minor
        patch
        meta
      }
    }
    dateUpdated
    autoEstimationAssets {
      id
      asset {
        id
        location
        originalName
        assetType
      }
    }
    orientation
  }
`;
