import { useMatchingLivePrinters } from "./useLivePrinters";

// Takes an AppMachineType, and returns a list of normalized material IDs loaded on matching live printers
// Some materials will be returned as normalized IDs in addition to their original IDs, to account for
// possible inconsistencies in how they're reported by different printers.
export const useMatchingLiveMaterials = (appMachineType?: string) => {
  const matchingPrinters = useMatchingLivePrinters(appMachineType);

  const materialIds = [
    ...new Set(
      matchingPrinters
        .map(p =>
          p
            .getMaterialsList()
            .getMaterials()
            .map(m => m.getName())
        )
        .flat()
    ),
  ].map(id => {
    // Some Ultem 9085 materials+colors are reported using a "nonstandard" prefix with a hyphen
    // instead of an underscore, so we need to normalize them here: (and in the conditionals below.)
    if (id.includes("9058-")) {
      // Use the "ULT9085_" prefix consistently:
      return id.replace("9058-", "ULT9085_");
    }
    return id;
  });
  // Black 9085 is reported as "ULTM-BLK" on some printers, but we want to use "ULT9085_BLK" consistently:
  if (materialIds.includes("ULTM-BLK")) {
    // We're leaving all _these_ original IDs in place as that seems safer than replacing them,
    // since we're not 100% sure which printers exhibit this behavior, and ULTM-BLK may be a
    // valid single-color material on another current or future printer.
    materialIds.push("ULT9085_BLK");
  }
  if (
    materialIds.includes("U9085") || // Not sure if this is a real ID, but it's in the GCP data
    materialIds.includes("ULT9085") || // Seen Fortus 450mc
    materialIds.includes("9085_NA") // Seen on FDM F3300
  ) {
    // Printers report Tan (Natural) as "ULT9085" or "9085_NA", but we want to use "ULT9085_" prefix consistently:
    materialIds.push("ULT9085_TAN");
  }
  if (materialIds.includes("ABS-M30")) {
    // The Fortus 380mc, 400mc, 450mc, and perhaps others, report all other ABS M30 materials with
    // color suffixes, eg: `M30_RED`. We've seen from real printer data however that when Ivory ABS
    // is loaded on these machines, it's reported as `ABS-M30`. Our theory is that this is because
    // it's the default "un-pigmented" color of the material, and all other colors, including white,
    // have pigment additives.
    // Adding it here avoids the need for special matching logic just to handle this odd corner-case:
    materialIds.push("M30_IVR");
  }
  if (materialIds.includes("PC")) {
    materialIds.push("PC_WHT");
  }
  return materialIds;
};

/* 
  For clarity, these are the Ultem 9085, PC, & ABS material IDs we're trying to normalize.
  The first column is derived from GCP's all_printers_and_materials.json, which seems frequently incorrect
  based on the 2nd column, which is a "real" ID we've seen returned from the PrintersAPI. 
  Where the 2nd column is missing indicates we're lacking empirical data to confirm the mapping. 
  The 3rd column is Shop's color display name.

  ** Denotes that that observed real ID is breaking from _color suffix convention

  GCP json  | Real ID     | Display Name     | Current mapping status
  -------------------------------------------------------------------
  ULTM-BLK: | ULTM-BLK    | Black            | Y
  9058-AGR: |             | Aircraft Gray    | Y
  9058-DCR: |             | Dream Gray       | Y
  9058-GGR: |             | Gunship Gray     | Y
  9058-JWT: |             | Jana White       | Y
  9058-RD:  |             | Red              | Y
  9058-WT7: |             | White 7362       | Y
  ULTM9085: | ULT9085     | Tan (Natural)    | Y **  
            | 9085_NA     | Tan (Natural)    | Y **
  U9085:    |             | Tan (Natural)    | Y ** (may be imaginary?)
  -------------------------------------------------------------------
  ABS-M30:  | ABS-M30     | Ivory            | Y **
  -------------------------------------------------------------------
  PC-BK:    |             | Black            | N
  PC-RD:    |             | Red              | N
  PC:       | PC          | White            | Y **
  -------------------------------------------------------------------
  PCABS-BK: | PC-ABS_BLK  | Black            | Y
  PCABS-RD: |             | Red              | Y
  PC-ABS:   | PC-ABS_WHT  | White            | Y
  -------------------------------------------------------------------
  PC-ISO:   | PC-ISO      | ?                | N ** White or Translucent? Currently unclear ;u) 
*/
