import React from "react";
import { PrinterStatus, getPrinterStatus } from "../../../utils/printerUtils";
import styled from "grabcad-ui-elements";
import { PrinterStatusLabel } from "./PrinterStatusLabel";
import { useMatchingLivePrinters } from "../../../utils/useLivePrinters";

export interface PrinterStatusSummaryProps {
  machineType: string;
}

const LabelList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const PrinterStatusSummary: React.FC<PrinterStatusSummaryProps> = ({ machineType }) => {
  const matchingPrinters = useMatchingLivePrinters(machineType);
  if (!matchingPrinters.length) {
    return null;
  }

  const statusCounts: Partial<Record<PrinterStatus, number>> = {};

  for (const p of matchingPrinters) {
    const status = getPrinterStatus(p);

    if (!statusCounts[status]) {
      statusCounts[status] = 0;
    }

    statusCounts[status]!++;
  }

  if (Object.keys(statusCounts).length === 0) {
    return null;
  }

  return (
    <LabelList>
      {(Object.keys(statusCounts) as PrinterStatus[]).map(status => (
        <PrinterStatusLabel status={status} numPrinters={statusCounts[status]!} key={status} />
      ))}
    </LabelList>
  );
};
