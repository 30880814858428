import { ApplicationContext } from "@/components/ApplicationProvider";
import { CREATE_SHOP_MACHINE } from "@/graphql/Mutations";
import { Entity, ImageType } from "@/graphql/Mutations/Upload";
import { SHOP_TECHNOLOGIES_WITH_MACHINES } from "@/graphql/Queries";
import React, { useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ShopFormContext } from "@/screens/Shop/New/New";
import { ROUTES, SHOP } from "@/shopConstants";
import styled, { Button, Header, Form, FormField } from "grabcad-ui-elements";
import { Notifier } from "@/utils/Notifier";
import { TechnologiesDropdown } from "./TechnologiesDropdown";
import { MachinesTypesDropdown } from "./TypesDropdown";
import { ImageUpdateOrDeleteDropzone } from "@/components/Upload/ImageUpdateOrDeleteDropzone";
import { IImage } from "@/graphql/Fragments/Image";
import fallbackImage from "@/assets/default-image-500px-sq.png";
import { getDefaultMachineImage } from "@/screens/Shop/Machine/MachineDetails";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import { ApolloError, useMutation } from "@apollo/client";
import { useShopTechnologies } from "@/utils/queryHooks";
import { IAppMachineType } from "@/graphql/Fragments/AppMachineType";
import { IAppTechnology } from "@/graphql/Fragments/AppTechnology";
import type { IShopMachineInput } from "../../../graphql/Mutations/ShopMachine";

const FormHolder = styled.div`
  .ui.form {
    display: flex;
    max-width: 520px;
    margin: 0 auto;
    .settings {
      flex-grow: 1;
      padding-right: 2em;
    }
    .field {
      margin-bottom: 18px;
    }
    .field > label {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 11px;
    }
    .image-preview {
      width: 110px;
      height: 110px;
    }
  }
  .buttons {
    max-width: 520px;
    margin: 32px auto 0 auto;
    text-align: right;
  }
`;

interface ICreateShopMachineResponse {
  createShopMachine: {
    id: number;
  };
}

interface IShopMachinesFormProps extends RouteComponentProps<{ shopId: string }> {
  isSetup: boolean;
}

export const ShopMachinesForm = withRouter(({ history, isSetup }: IShopMachinesFormProps) => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { shopTechnologies, loadingTechnologies } = useShopTechnologies();
  const [selectedAppTechnology, setSelectedAppTechnology] = useState<IAppTechnology | null>(null);
  const [selectedAppMachineTypeId, setSelectedAppMachineTypeId] = useState<number | null>(null);
  const [selectedAppMachineName, setSelectedAppMachineName] = useState<string | null>(null);
  const [selectedAppMachineTypes, setSelectedAppMachineTypes] = useState<IAppMachineType[]>([]);

  const [imageId, setImageId] = useState<number | null>(null);
  const [isMachineImageEditable, setIsMachineImageEditable] = useState<boolean>(false);
  // TODO: gc-56271
  const emptyImage = {
    medium: { location: fallbackImage },
    __typename: "Image",
  } as Partial<IImage>;
  const [defaultImage, setDefaultImage] = useState<Partial<IImage>>(emptyImage);
  const [shopMachines, setShopMachines] = useState<IShopMachine[]>([]);
  const [image, setImage] = useState<Partial<IImage>>(defaultImage);

  const [createShopMachine] = useMutation<{ createShopMachine: IShopMachine }, IShopMachineInput>(
    CREATE_SHOP_MACHINE
  );
  const { setMachinesCompleted } = useContext(ShopFormContext);
  const [submitting, setSubmitting] = useState(false);

  const setSelectedMachine = (newAppMachineType: IAppMachineType) => {
    setSelectedAppMachineTypeId(newAppMachineType.id);
    setSelectedAppMachineName(newAppMachineType.displayName);
    setIsMachineImageEditable(true);
    const defaultMachineImage = getDefaultMachineImage(newAppMachineType.name) as Partial<IImage>;
    setImage(defaultMachineImage);
    setDefaultImage(defaultMachineImage);
  };

  if (loadingTechnologies) {
    return <p>{t("global.loading")}</p>;
  }

  return (
    <FormHolder data-testid="shopMachinesForm">
      <Header as="h2" className="page-header">
        {t("shop.machines.new.title")}
      </Header>
      <Form>
        <div className="settings">
          <FormField>
            <label>{t("machines.form.appTechnologies.dropdown.title")}</label>
            <TechnologiesDropdown
              selectedAppTechnologyId={selectedAppTechnology?.id || null}
              onTechnologySelect={(newAppTechnology: IAppTechnology) => {
                setSelectedAppTechnology(newAppTechnology);
                setSelectedAppMachineTypes(newAppTechnology.appMachineTypes || []);
                const shopTechnology = (shopTechnologies.find(
                  technology => technology.appTechnology.id === newAppTechnology.id
                ) || {}) as IShopTechnology;

                const currentMachines = shopTechnology.shopMachines
                  ? shopTechnology.shopMachines.filter(machine => !machine.dateDeleted)
                  : [];
                setShopMachines(currentMachines);
                if (newAppTechnology.appMachineTypes?.length === 1) {
                  const theNewMachineType = newAppTechnology.appMachineTypes[0];
                  const existingShopMachineType = currentMachines.find(
                    machine => machine.appMachineType.id === theNewMachineType.id
                  );
                  if (!existingShopMachineType) {
                    // If a technology has only a single machine type, auto select it.
                    setSelectedMachine(theNewMachineType);
                  }
                  return;
                }
                setImage(emptyImage);
              }}
            />
          </FormField>
          <FormField>
            <label>{t("machines.form.appMachineTypes.dropdown.title")}</label>
            <MachinesTypesDropdown
              appMachineTypes={selectedAppMachineTypes}
              selectedAppMachineTypeId={selectedAppMachineTypeId}
              onMachineTypeSelect={setSelectedMachine}
              shopMachines={shopMachines}
            />
          </FormField>
        </div>
        <FormField>
          <label>{t("shop.machine.image")}</label>
          <ImageUpdateOrDeleteDropzone
            onImageUploaded={uploadedImageId => setImageId(+uploadedImageId)}
            onImageReset={() => setImageId(null)}
            imageType={ImageType.MACHINE_IMAGE}
            entity={Entity.MACHINE}
            currentImage={image}
            isImageEditable={isMachineImageEditable}
            menuPosition={"right center"}
            defaultImage={defaultImage}
            imageSize={"medium"}
          />
        </FormField>
      </Form>
      <div className="buttons">
        <Button
          id="qa-machineTypeForm-addMaterialsAndColors"
          primary
          floated="right"
          onClick={async event => {
            event.preventDefault();
            setSubmitting(true);

            await createShopMachine({
              variables: {
                input: {
                  shopId: currentShop.id,
                  imageId,
                  appMachineTypeId: selectedAppMachineTypeId!,
                },
              },
              onCompleted: (response: ICreateShopMachineResponse) => {
                if (setMachinesCompleted) {
                  setMachinesCompleted(true);
                }

                setSubmitting(false);

                // notify user about the newly created machine to avoid creating duplicates inadvertedly
                // TODO: This is too much text to read in 5sec. Perhaps persistent warning UI near 'Finish' button?
                Notifier.success(
                  !!selectedAppMachineName // TODO: WTF is this?
                    ? t("shop.machines.new.notification", {
                        machineName: selectedAppMachineName,
                      })
                    : t("shop.machines.new.notification.noPrinterName"), // TODO: When could this ever happen?
                  { autoClose: 5000 }
                );

                // Shop machine added => now show materials [to associate w/ machine]
                history.push(
                  ROUTES.SHOP(currentShop.id, isSetup).MACHINES.SHOW({
                    machineId: response.createShopMachine.id,
                    subSection: SHOP.SECTIONS.materials,
                  })
                );
              },
              refetchQueries: [
                {
                  query: SHOP_TECHNOLOGIES_WITH_MACHINES,
                  variables: { id: currentShop.id },
                },
              ],
              onError: (err: ApolloError) => Notifier.error(err),
            });
          }}
          disabled={!selectedAppMachineTypeId || submitting}
        >
          {t("machines.form.appMachineTypes.grid.addMaterialsAndColors")}
        </Button>
      </div>
    </FormHolder>
  );
});
