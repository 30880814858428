import React, { useState, useContext, useRef, useLayoutEffect } from "react";
import classnames from "classnames";
import styled, { Button, Checkbox, Popup, Icon, StyleMixins } from "grabcad-ui-elements";
import { withRouter, RouteComponentProps } from "react-router";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { TIME_UNITS, MATERIAL_UNITS, ROUTES } from "@/shopConstants";
import { MATERIALS_BY_MACHINE, SHOPS_LIST } from "@/graphql/Queries";
import { Notifier } from "@/utils/Notifier";
import { IAppMaterialColor } from "@/graphql/Queries/MaterialColor";
import { UPDATE_MATERIALS_AND_COLORS } from "@/graphql/Mutations/MaterialColor";
import { ToastOptions } from "react-toastify";
import { ApolloError, MutationFunction, useMutation, useQuery } from "@apollo/client";
import { LocalizedNumericInput } from "@/components/Shared/LocalizedNumericInput";
import { getMaterialUnitLabel } from "@/utils/DropdownUtils";
import ReactGA from "react-ga";
import { ShopState } from "@/graphql/Fragments/Shop";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { sortByName, useLocalizeDisplayName, useShopTechnologies } from "@/utils/queryHooks";
import { isAnyMaterial, isAnyMaterialColor } from "@/utils/GeneralUtils";
import { IShopMachine, ShopMachineFragments } from "../../../../graphql/Fragments/ShopMachine";
import { MAX_INT32 } from "../../../Order/ItemsList/shopRatesUtils";
import {
  COLOR_DISPLAY_NAMES_BY_ID_PART,
  materialIdsMatchingDisplayName,
} from "@/utils/materialUtils";
import * as Sentry from "@sentry/browser";
import { useMatchingLiveMaterials } from "../../../../utils/useMatchingLiveMaterials";

export interface IAppMaterial {
  id: number;
  name: string;
  displayName: string;
  appMaterialColors?: IAppMaterialColor[];
  __typename: string;
}

export interface IShopMaterialColor {
  id: number;
  dateDeleted: Date | null;
  appMaterialColor: IAppMaterialColor;
  __typename: string;
}
export interface IMachineRateInput {
  shopMachineId: number;
  machineBaseRate?: number;
  machineTimeRate?: number;
  machineTimeUnit: TIME_UNITS;
  materialUnit: MATERIAL_UNITS;
  supportUnit?: MATERIAL_UNITS;
}

export interface IMaterialRateInput {
  shopMachineId: number;
  appMaterialId: number;
  materialRate?: number;
  supportRate?: number;
}

export interface IShopMaterialsFormProps
  extends RouteComponentProps<{ machineId: string; shopId: string }> {
  successMessage: string | JSX.Element;
  onComplete?: () => void;
  imageId: number | undefined;
  notifierOptions?: ToastOptions;
  createShopRates?: MutationFunction;
  machineRateInput?: IMachineRateInput;
  materialUnit: MATERIAL_UNITS;
  supportUnit?: MATERIAL_UNITS;
  showSupportRates: boolean;
  disableRateInputs: boolean;
}

type AppMaterialAndColors = {
  appMaterialId: number;
  appMaterialColorIdsToAdd: number[];
  appMaterialColorIdsToRemove: number[];
};

const MaterialsSelector = styled.div`
  ${StyleMixins.roundAndShadow}
  margin-bottom: 20px;
  min-width: 400px;
  display: flex;

  > div {
    flex: 1 1 auto;
    &:not(:last-child) {
      border-right: 1px solid #e7e7e7;
      background: #f6f6f6;
    }
  }
  .colors-header,
  .materials-header {
    height: 54px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    background: #f6f6f6;
  }
  h3,
  h4 {
    margin: 0;
  }
  h3 {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  h4 {
    font-size: 13px;
  }
  .state {
    min-width: 44px;
    text-align: center;
  }
  .material-rates {
    display: flex;
    h4 {
      text-align: right;
      display: flex;
      flex-direction: row-reverse;
    }
    > * {
      width: 88px;
      padding-left: 10px;
    }
    input {
      text-align: right;
    }
  }
  .material-count {
    margin-left: 10px;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
  }
  .list {
    margin: 0;
    max-height: calc(100vh - 305px);
    overflow: auto;
    .item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      min-height: 46px;
      cursor: pointer;
      &:not(.material):hover {
        background: #dae4f8;
      }
      &.material:not(.selected):hover {
        background: white;
      }
      &.material {
        &:not(:first-child) {
          border-top: 1px solid rgba(34, 36, 38, 0.1);
        }
      }
      &.selected {
        background: #dae4f8;
      }
      .name {
        flex-grow: 1;
        i.icon {
          color: #003393;
          opacity: 0;
        }
        &.is-enabled {
          font-weight: bold;
          i.icon {
            opacity: 1;
          }
        }
      }
      .state {
        color: green;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(.loaded) {
          visibility: hidden;
        }
      }
      .ui.checkbox {
        margin-right: 10px;
      }
    }
  }
`;

const StyledAutoSelectBtn = styled.a`
 margin: "5px 0";
    cursor: "pointer";       
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const ShopMaterialsForm = withRouter(
  ({
    match: { params },
    history,
    successMessage,
    onComplete,
    imageId,
    notifierOptions = {},
    createShopRates,
    machineRateInput,
    materialUnit,
    supportUnit,
    showSupportRates,
    disableRateInputs,
  }: IShopMaterialsFormProps) => {
    const { currentShop, t, formatPrice } = useContext(ApplicationContext);
    if (!currentShop) {
      return null;
    }

    const { allMachines } = useShopTechnologies();
    const machineId = +params.machineId;
    const shopMachine = allMachines.find(m => m.id === +machineId);
    const materialIds = useMatchingLiveMaterials(shopMachine?.appMachineType.name);

    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(0);
    const [selectedMaterialsOG, setSelectedMaterialsOG] = useState<number[][] | null>(null);
    const [selectedMaterials, setSelectedMaterials] = useState<number[][] | null>(null);
    const [imageIdOG, setImageIdOG] = useState<number | undefined | null>(imageId);

    const [materialRates, setMaterialRates] = useState<(number | undefined)[][]>([]);
    const [materialRatesOG, setMaterialRatesOG] = useState<(number | undefined)[][]>([]);

    const localizeDisplayName = useLocalizeDisplayName();

    const { data, loading } = useQuery<
      { loadMaterials?: { appMaterials: IAppMaterial[]; shopMaterials: IShopMaterial[] } },
      { shopMachineId: number }
    >(MATERIALS_BY_MACHINE, { variables: { shopMachineId: machineId } });

    const refetchQueries: { query: any; variables?: any }[] = [];
    if (currentShop.state === ShopState.SAMPLE) {
      refetchQueries.push({ query: SHOPS_LIST });
    }

    const [updateShopMaterialsAndColors] = useMutation(UPDATE_MATERIALS_AND_COLORS, {
      onError: (err: ApolloError) => Notifier.error(err),
      update: (cache, result) => {
        // SHOP_TECHNOLOGIES_WITH_MACHINES can be an expensive query to refetch (> 1s), and it was
        // causing a race condition overriding MachineRates updated simultaneously with Materials.
        // Instead of refetching, we can manually update the cached machine's materials:
        const cachedMachine = cache.readFragment<IShopMachine>({
          id: `ShopMachine:${machineId}`,
          fragment: ShopMachineFragments.shopMachine,
          fragmentName: "shopMachineFields",
        });
        if (cachedMachine) {
          cache.writeFragment({
            id: `ShopMachine:${machineId}`,
            fragment: ShopMachineFragments.shopMachine,
            fragmentName: "shopMachineFields",
            data: {
              ...cachedMachine,
              materials: result.data?.updateShopMaterialsAndColors.materials,
            },
          });
        }
      },
      onCompleted: () => {
        if (onComplete) {
          onComplete();
        } else {
          history.push(ROUTES.SHOP(currentShop.id).MACHINES.INDEX);
        }
        Notifier.success(successMessage, notifierOptions);
      },
      refetchQueries,
    });

    const colorListRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
      if (colorListRef.current) {
        colorListRef.current.scrollTop = 0;
      }
    }, [selectedMaterialIndex]);

    const sentryColorWarningSent = useRef(false);

    // End Hooks

    if (loading || !data || !data.loadMaterials) {
      return <p>{t("global.loading")}</p>;
    }

    const getState = (_appMaterials: IAppMaterial[], _shopMaterials: IShopMaterial[]) =>
      _appMaterials.map(appMaterial => {
        const shopMaterial = _shopMaterials.find(
          material => material.appMaterial.id === appMaterial.id
        );
        return shopMaterial
          ? shopMaterial.shopMaterialColors.map(
              shopMaterialColor => shopMaterialColor.appMaterialColor.id
            )
          : [];
      });

    const getDefaultMaterials = (_appMaterials: IAppMaterial[]) => {
      const selectedDefaultMaterials = [];
      _appMaterials.forEach(() => {
        selectedDefaultMaterials.push([]);
      });
      let index = 0;
      const defaultMaterial =
        _appMaterials.find((appMaterial, i) => {
          index = i;
          return isAnyMaterial(appMaterial);
        }) || _appMaterials[0];
      const defaultMaterialColor = (
        defaultMaterial?.appMaterialColors
          ? defaultMaterial.appMaterialColors.find(appMaterialColor =>
              isAnyMaterialColor(appMaterialColor)
            ) || defaultMaterial.appMaterialColors[0]
          : {}
      ) as IAppMaterialColor;
      selectedDefaultMaterials[index] = defaultMaterialColor.id ? [defaultMaterialColor.id] : [];

      return selectedDefaultMaterials;
    };

    const appMaterials: IAppMaterial[] = [...data.loadMaterials.appMaterials]
      .map(localizeDisplayName)
      .sort(sortByName)
      .map((material: IAppMaterial) => ({
        ...material,
        appMaterialColors: [...(material.appMaterialColors || [])]
          .map(localizeDisplayName)
          .sort(sortByName),
      }));

    const hasSomeLoadedMaterials = appMaterials.find(material => {
      return materialIdsMatchingDisplayName(materialIds, material.displayName).length;
    });

    const shopMaterials: IShopMaterial[] = data.loadMaterials.shopMaterials
      .filter(material => material.shopMaterialColors.some(color => !color.dateDeleted))
      .map(mat => ({
        ...mat,
        shopMaterialColors: mat.shopMaterialColors.filter(color => !color.dateDeleted),
      }));

    const selectedAppMaterial = appMaterials[selectedMaterialIndex];
    const appMaterialColors = selectedAppMaterial.appMaterialColors || [];

    if (!selectedMaterials) {
      setSelectedMaterialsOG(getState(appMaterials, shopMaterials));
      if (shopMaterials.length) {
        setSelectedMaterials(getState(appMaterials, shopMaterials));
      } else {
        setSelectedMaterials(getDefaultMaterials(appMaterials));
      }
    }

    if (!selectedMaterials || !selectedMaterialsOG) {
      return null; // This is just to make TS happy. These will always be defined at this point.
    }

    const selectedColorIds = selectedMaterials[selectedMaterialIndex];
    const toggleSelection = (id: number) => {
      const materials = selectedMaterials.map(arr => arr.slice());
      const colors = materials[selectedMaterialIndex];
      if (colors.includes(id)) {
        colors.splice(colors.indexOf(id), 1);
      } else {
        colors.push(id);
      }
      setSelectedMaterials(materials);
    };

    // MATERIAL RATE STATE
    if (!materialRates.length) {
      const rates = appMaterials.map(appMaterial => {
        const shopMaterial = data.loadMaterials?.shopMaterials.find(
          m => m.appMaterial.id === appMaterial.id
        );
        const shopMaterialRate = shopMaterial?.shopMaterialRate || undefined;
        return [shopMaterialRate?.materialRate, shopMaterialRate?.supportRate];
      });
      setMaterialRatesOG(rates.map(r => [...r]));
      setMaterialRates(rates);
    }

    let isDirty = false;
    for (let i = 0; i < selectedMaterials.length; i += 1) {
      if (
        selectedMaterials[i].length !== selectedMaterialsOG[i].length ||
        !selectedMaterials[i].every(color => selectedMaterialsOG[i].includes(color))
      ) {
        isDirty = true;
        break;
      }
    }
    const numSelectedMaterials = selectedMaterials.filter(mat => mat.length).length;
    if (imageIdOG !== imageId) {
      isDirty = true;
    }

    let ratesDirty = false;
    const updatedMaterialRates: IMaterialRateInput[] = [];
    materialRates.forEach((rate, i) => {
      if (rate[0] !== materialRatesOG[i][0] || rate[1] !== materialRatesOG[i][1]) {
        updatedMaterialRates.push({
          shopMachineId: machineId,
          appMaterialId: appMaterials[i].id,
          materialRate: rate[0] && Math.round(rate[0] || 0),
          supportRate: rate[1] && Math.round(rate[1] || 0),
        });
        ratesDirty = true;
      }
    });

    // Very rarely do we need to show a stand-alone currencySymbol.
    // formatPrice() should be used for all currency formatting.
    // This logic is largely copied from LocalizedNumericInput:
    const formattedZeroPrice = formatPrice(0) as string; // Arabic numerials?
    const currencySymbol =
      formattedZeroPrice.slice(0, formattedZeroPrice.indexOf("0")) || // EG: $0.00
      formattedZeroPrice.slice(formattedZeroPrice.lastIndexOf("0") + 1); // EG: 0.00$ or 0Kr

    // This represents the updated state if the 'Auto-Select Materials' were pressed.
    // It's useful to calculate it ahead of time so we can compare it to the current state,
    // and enable/disable the button accordingly.
    const materialIdsWithUnmatchedColors: string[] = [];
    const addAutoSelectedMaterials = [...selectedMaterials].map((material, i) => {
      const matchingIds = materialIdsMatchingDisplayName(materialIds, appMaterials[i].displayName);
      if (matchingIds.length) {
        const colors = appMaterials[i].appMaterialColors || [];
        if (colors.length === 1) {
          // Select single color materials
          return [colors[0].id];
        } else if (colors.length > 1) {
          // Select all loaded colors
          const loadedAppMateralColorIds = matchingIds.reduce((acc, id) => {
            const colorId = id.split("_")[1];
            const displayName = colorId && COLOR_DISPLAY_NAMES_BY_ID_PART[colorId];
            const appMaterialColor = colors.find(color => color.name === displayName);
            if (appMaterialColor) {
              acc.push(appMaterialColor.id);
            } else {
              materialIdsWithUnmatchedColors.push(id);
            }
            return acc;
          }, [] as number[]);

          if (!loadedAppMateralColorIds.length && !material.length) {
            // We didn't find a matching color for detected but not-enabled material. This may be possible
            // for ULT9085, which has colors in Shop, eg "Dream Grey", that _may_ not reported by the printer?

            // This may only be a "Brad thing" though, as this has only been observed in an emulated Printer.
            // in reality this color may in fact be reported as as "9085-DGR", per GCP's imperfect source of truth.
            // It seems impossible to tell without loading a real spool into a real printer.

            // However we've arrived here, we can try to enable the detected material with the "Any" color,
            // or the first color if "Any" is not available:
            const anyColor = colors.find(color => color.name === "Any");
            return [anyColor?.id || colors[0].id];
          }

          // merge with any existing selections and dedupe
          return [...new Set([...material, ...loadedAppMateralColorIds])];
        }
      }
      return material;
    });
    const autoSelectDisabled =
      addAutoSelectedMaterials.flat().length === selectedMaterials.flat().length;

    // Log a warning in Sentry for any interesting materials that we can't match colors for
    const interestingIds = materialIdsWithUnmatchedColors.filter(
      // Filtering known materials to keep Sentry noise to a minimum:
      // Some of these materials are "colorful" in Shop, but can be reported without a _color suffix (EG "PC-ABS").
      // In the case of PC-ABS specifically, it's not clear if the "default" color is Black or White, so we're
      // falling back to the "Any" color, and not logging a warning.
      id => !["ABS-M30", "ASA", "PC", "PC_S", "PC-ISO", "ULT9085", "PC-ABS"].includes(id)
    );
    if (!sentryColorWarningSent.current && interestingIds.length) {
      // We only want to log this once per page-view, so we're using a ref to track it.
      // useEffect() was proving gnarly to implement down here.
      sentryColorWarningSent.current = true;
      const warning = `Live Material(s) with unknown color: ${interestingIds.join(", ")}`;
      console.log(warning);
      Sentry.captureMessage(warning, {
        level: Sentry.Severity.Warning,
      });
    }

    return (
      <>
        <MaterialsSelector data-testid="materialsForm">
          <div>
            <div className="materials-header">
              <h3>
                {t("materials.form.materials.title")}
                <span className="material-count">
                  {`(${numSelectedMaterials} ${t("materials.form.selected")})`}
                </span>
              </h3>
              <h4 className="state">
                {/* Don't show "State" header if no loaded matrials, or API request is in flight.
                    Live printers can take some time to load. Rendering this fixed-width h4 
                    as an empty placeholder will prevent the UI from jumping around. */}
                {hasSomeLoadedMaterials && t("materials.form.state")}
              </h4>
              <div className="material-rates">
                <h4>
                  {`${currencySymbol}/${getMaterialUnitLabel(t, materialUnit)}`}
                  <br />
                  {t("order.items.rates.popup.table.material")}
                </h4>
                {showSupportRates && (
                  <h4>
                    {`${currencySymbol}/${getMaterialUnitLabel(t, supportUnit || materialUnit)}`}
                    <br />
                    {t("order.items.rates.popup.table.support")}
                  </h4>
                )}
              </div>
            </div>

            <div className="list" data-testid="list-material">
              {appMaterials.map((appMaterial, i) => (
                <div
                  key={i}
                  className={classnames("qa-materialsForm-materialRow", "item", "material", {
                    selected: i === selectedMaterialIndex,
                  })}
                  onClick={() => setSelectedMaterialIndex(i)}
                  data-testid="materialRow"
                >
                  <span
                    className={classnames("name", {
                      "is-enabled": selectedMaterials[i].length,
                    })}
                    data-testid="materialName"
                  >
                    <Icon name="check circle" />
                    {appMaterial.displayName}
                  </span>
                  <div
                    className={classnames("state", {
                      loaded: materialIdsMatchingDisplayName(materialIds, appMaterial.displayName)
                        .length,
                    })}
                    data-tooltip={t("materials.form.materialLoaded")}
                    data-position="left center"
                    data-testid="materialLoadedState"
                  >
                    ⬤
                  </div>
                  <div className="material-rates">
                    <LocalizedNumericInput
                      disabled={disableRateInputs}
                      min={0}
                      max={MAX_INT32} // limited by db/gql capability, amounts to $21474836.47
                      value={materialRates[i]?.[0] != null ? materialRates[i][0] : undefined}
                      placeholder={formatPrice(0) as string}
                      currency
                      className={"qa-materialsForm-materialUnitInput"}
                      onChange={rate => {
                        const newMaterialRates = [...materialRates];
                        newMaterialRates[i][0] = rate;
                        setMaterialRates(newMaterialRates);
                      }}
                      onSubmit={rate => {
                        const newMaterialRates = [...materialRates];
                        newMaterialRates[i][0] = rate;
                        setMaterialRates(newMaterialRates);
                      }}
                    />
                    {showSupportRates && (
                      <LocalizedNumericInput
                        disabled={disableRateInputs}
                        min={0}
                        max={MAX_INT32} // limited by db/gql capability, amounts to $21474836.47
                        value={materialRates[i]?.[1] != null ? materialRates[i][1] : undefined}
                        placeholder={formatPrice(0) as string}
                        currency
                        className={"qa-materialsForm-supportUnitInput"}
                        onChange={rate => {
                          const newMaterialRates = [...materialRates];
                          newMaterialRates[i][1] = rate;
                          setMaterialRates(newMaterialRates);
                        }}
                        onSubmit={rate => {
                          const newMaterialRates = [...materialRates];
                          newMaterialRates[i][1] = rate;
                          setMaterialRates(newMaterialRates);
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="colors-header">
              <h3>{t("materials.form.colors.title")}</h3>
              <Button
                id="qa-materialsForm-selectAllColors"
                className="select-all secondary mini"
                disabled={
                  selectedMaterials[selectedMaterialIndex].length === appMaterialColors.length
                }
                onClick={() => {
                  const materials = selectedMaterials.map(arr => arr.slice());
                  materials[selectedMaterialIndex] = appMaterialColors.map(color => color.id);
                  setSelectedMaterials(materials);
                }}
              >
                {t("materials.form.colors.selectAll")}
              </Button>
            </div>
            <div className="list" ref={colorListRef} data-testid="list-color">
              {appMaterialColors.map((appMaterialColor, i) => (
                <div
                  className="qa-materialsForm-colorRow item color"
                  key={i}
                  onClick={() => toggleSelection(appMaterialColor.id)}
                  data-testid="colorRow"
                >
                  <Checkbox checked={selectedColorIds.includes(appMaterialColor.id)} />
                  {appMaterialColor.displayName}
                </div>
              ))}
            </div>
          </div>
        </MaterialsSelector>
        <div
          style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}
        >
          {appMaterials && hasSomeLoadedMaterials && (
            <div
              data-tooltip={autoSelectDisabled ? t("materials.form.autoSelect.disabled") : null}
              data-position="left center"
              style={{ paddingLeft: 8 }}
            >
              <StyledAutoSelectBtn
                className={classnames({ disabled: autoSelectDisabled })}
                onClick={() => {
                  !autoSelectDisabled && setSelectedMaterials(addAutoSelectedMaterials);
                }}
                data-testid="autoSelectMaterialsBtn"
              >
                {t("materials.form.autoSelect")}
              </StyledAutoSelectBtn>
            </div>
          )}
          <Popup
            content={t("materials.form.selectAtLeastOne")}
            position="left center"
            disabled={!!numSelectedMaterials}
            trigger={
              <Button
                id="qa-materialsForm-submit"
                primary
                className="finish right floated"
                disabled={!((isDirty && !!numSelectedMaterials) || machineRateInput || ratesDirty)}
                onClick={async () => {
                  if (isDirty) {
                    const materialsAndColors: AppMaterialAndColors[] = [];
                    const input = { materialsAndColors, imageId, shopMachineId: machineId };

                    for (let i = 0; i < selectedMaterials.length; i += 1) {
                      const selection = selectedMaterials[i];
                      const selectionOG = selectedMaterialsOG[i];
                      if (
                        selection.length !== selectionOG.length ||
                        !selection.every(color => selectionOG.includes(color))
                      ) {
                        input.materialsAndColors.push({
                          appMaterialId: appMaterials[i].id,
                          appMaterialColorIdsToAdd: selection.filter(
                            id => !selectionOG.includes(id)
                          ),
                          appMaterialColorIdsToRemove: selectionOG.filter(
                            id => !selection.includes(id)
                          ),
                        });
                      }
                    }
                    setImageIdOG(null);
                    setSelectedMaterialsOG(selectedMaterials.map(arr => arr.slice()));
                    await updateShopMaterialsAndColors({ variables: { input } });
                  }

                  if (machineRateInput || updatedMaterialRates.length) {
                    if (createShopRates) {
                      createShopRates({
                        variables: {
                          input: {
                            machineRateInput,
                            materialRateInput: updatedMaterialRates,
                            shopId: currentShop.id,
                          },
                        },
                      });
                      ReactGA.event({
                        category: "GcShop MachineRates",
                        action: "Creating a rate for a machine",
                        label: `Shop ${currentShop.id}`,
                      });
                    }
                    setMaterialRatesOG(materialRates.map(r => [...r]));
                  }
                  ReactGA.event({
                    category: "GcShop Materials",
                    action: "Added new materials",
                    label: `Shop ${currentShop.id}`,
                  });
                }}
              >
                {t("shop.materials.form.finish")}
              </Button>
            }
          />
        </div>
      </>
    );
  }
);
ShopMaterialsForm.displayName = "ShopMaterialsForm";
