// TODO GC-100243: Share with company server
import type { IPrinter } from "grabcad-printers-api";
import * as Sentry from "@sentry/browser";

enum PrinterStatusColor {
  Gray = "#999999",
  Blue = "#33aadd",
  Green = "#57b857",
  Orange = "#ff8800",
  Red = "#ff4433",
}

export type PrinterStatus = ReturnType<typeof getPrinterStatus>;

export function getPrinterStatus(printer: IPrinter) {
  const tech = printer.getTechnology();
  const isFDM = tech.toUpperCase() === "FDM";
  const status = printer.getStatus().getTechnologyStatus()?.toUpperCase();

  switch (status) {
    case "READY":
      return isFDM ? "Idle" : "PendingStart";
    case "PENDINGSTART":
    case "PENDING_START":
      return "PendingStart";
    case "IDLE":
    case "ARCHIVE":
    case "FINISH":
      return "Idle";
    case "PROCESSING":
    case "PRINT":
    case "QUEUE":
    case "PRINTING":
    case "CALIBRATING":
      return "Printing";
    case "STOP":
    case "STOPPING":
    case "PAUSED":
      return "Paused";
    case "HARVESTING":
    case "PART_DONE":
    case "COOL_DOWN":
      return "Harvesting";
    case "MAINTENANCE":
    case "AUTO_CHANGEOVER":
    case "REBOOTING":
    case "UPGRADE":
      return "Maintenance";
    case "UPDATE":
      return "Update";
    case "OFFLINE":
    case "DISCONNECTED":
      return "Disconnected";
    case "OUTOFMATERIAL":
    case "OUT_OF_MATERIAL":
      return "OutOfMaterial";
    case "CANCELLED":
      return "Cancelled";
    case "SCHEDULED":
      return "Scheduled";
    case "WARNING":
      return "Warning";
    case "WAITING_FOR_PART":
      return "WaitingForPart";
    case "UNKNOWN":
    case "ERROR":
      return "Error";
    default: {
      Sentry.captureMessage(`Unknown printer status: ${status}`, {
        extra: {
          printer,
          technology: printer.getTechnology(),
          status: printer.getStatus().getTechnologyStatus(),
          serial: printer.getSerial(),
        },
        level: Sentry.Severity.Warning,
      });
      return "Error";
    }
  }
}

export function getPrinterStatusColor(status: PrinterStatus): PrinterStatusColor {
  switch (status) {
    case "Idle":
    case "Disconnected":
      return PrinterStatusColor.Gray;
    case "Harvesting":
    case "Scheduled":
      return PrinterStatusColor.Green;
    case "OutOfMaterial":
    case "Paused":
    case "Update":
    case "Maintenance":
    case "Warning":
      return PrinterStatusColor.Orange;
    case "Cancelled":
    case "Error":
      return PrinterStatusColor.Red;
    case "PendingStart":
    case "Printing":
    case "WaitingForPart":
    default:
      return PrinterStatusColor.Blue;
  }
}

// TODO GC-98289: SSOT
export const machineTypeMatchesLivePrinterModelName = (machineType: string, printer: IPrinter) => {
  const printerModelName = printer.getModelName();
  if (printerModelName.toLocaleLowerCase() === machineType.toLowerCase()) {
    return true;
  }
  if (printerModelName === "FDM F3300" && machineType === "Stratasys F3300") {
    // This brute force fix is necessary for this specific printer, which breaks
    // from the established naming conventions that allow exact matches.
    return true;
  }

  return false;
};
