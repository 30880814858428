// TODO GC-100243: Share with company-server
import React, { useContext } from "react";
import styled, { ColorLabel, IBackgroundColor } from "grabcad-ui-elements";
import { type PrinterStatus, getPrinterStatusColor } from "../../../utils/printerUtils";
import { ApplicationContext } from "../../../components/ApplicationProvider";

interface PrinterStatusLabelProps {
  numPrinters: number;
  status: PrinterStatus;
}

const StyledStatus = styled(ColorLabel)<IBackgroundColor>`
  display: flex;
  font-size: 12px;
`;

export const PrinterStatusLabel: React.FC<PrinterStatusLabelProps> = ({ numPrinters, status }) => {
  const { t } = useContext(ApplicationContext);
  const color = getPrinterStatusColor(status);

  return (
    <StyledStatus color={color} data-testid="printerStatusLabel">
      {numPrinters} {t(`printer.status.${status}`)}
    </StyledStatus>
  );
};
