import gql from "graphql-tag";
import { ShopMachineFragments } from "@/graphql/Fragments";
import { ShopMachineState } from "../Fragments/ShopMachine";

export const CREATE_SHOP_MACHINE = gql`
  mutation createShopMachineMutation($input: ShopMachineInput!) {
    createShopMachine(input: $input) {
      ...shopMachineFields
    }
  }
  ${ShopMachineFragments.shopMachine}
`;

export const DELETE_SHOP_MACHINE = gql`
  mutation deleteShopMachineMutation($id: ID!) {
    deleteShopMachine(id: $id) {
      id
      dateDeleted
    }
  }
`;

// TODO GC-81780: Share code
export interface IShopMachineInput {
  input: {
    displayName?: string;
    shopId: number;
    appMachineTypeId: number;
    imageId?: number | null;
    state?: ShopMachineState;
  };
}
