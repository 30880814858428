import React, { ReactElement, useContext, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled, { Button, Grid, GridColumn, Header, Loader, Image } from "grabcad-ui-elements";
import { Link } from "react-router-dom";
import { ROUTES, SHOP } from "@/shopConstants";
import { ScreensShopMachinesNew } from "@/screens/Shop/Machine/New";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import { Mutation } from "@apollo/client/react/components";
import { PlaceHolder } from "@/components/Shared/Placeholder";
import PlaceholderSVG from "@/assets/placeholder_machines.svg";
import { ShopMachineCard } from "@/components/Shop/Machines/Card";
import { DELETE_SHOP_MACHINE } from "@/graphql/Mutations/ShopMachine";
import { ApolloError, useQuery } from "@apollo/client";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { Notifier } from "@/utils/Notifier";
import { useShopTechnologies } from "@/utils/queryHooks";
import {
  APP_MACHINE_TYPES_LIST,
  type AppMachineTypesList,
} from "../../../graphql/Queries/AppMachineType";
import { useLivePrinters } from "../../../utils/useLivePrinters";
import { machineTypeMatchesLivePrinterModelName } from "../../../utils/printerUtils";
import { PotentialShopMachineCard } from "../../../components/Shop/Machines/PotentialCard";

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 1em;
  h1 {
    flex: 1;
  }
`;

interface IScreensShopMachineListProps {
  subSection: string;
  technologyId: string;
}

export const ScreensShopMachineList = withRouter(
  ({ match: { params }, history }: RouteComponentProps<IScreensShopMachineListProps>) => {
    const { subSection, technologyId } = params;
    const { t, currentShop } = useContext(ApplicationContext);
    if (!currentShop) {
      return null;
    }
    const { loadingTechnologies, shopTechnologies, allMachines, loadingMachineImages } =
      useShopTechnologies();
    const [shopMachineToDelete, setShopMachineToDelete] = useState<IShopMachine | undefined>(
      undefined
    );
    const { data: allAppMachineTypes } = useQuery<AppMachineTypesList>(APP_MACHINE_TYPES_LIST);
    const { printers } = useLivePrinters();

    if (subSection === SHOP.SECTIONS.materials) {
      // TODO I think this is dead code (Think it's a weird route only used for onboarding?)
      return <ScreensShopMachinesNew />;
    }

    const currentTechnology = shopTechnologies.find(tech => tech.id === +technologyId);
    const header = (
      <StyledHeader>
        <Header as="h2" className="page-header">
          {currentTechnology?.appTechnology.displayName || t("machines.list.title")}
        </Header>
        <UiCan do={Permission.CREATE_MACHINE} on={currentShop}>
          <Link to={ROUTES.SHOP(currentShop.id).MACHINES.NEW}>
            <Button className="qa-button-addMachineType" primary>
              {t("machines.form.appMachineTypes.grid.addMachine")}
            </Button>
          </Link>
        </UiCan>
      </StyledHeader>
    );

    const { machineCards, potentialMachineCards } = useMemo(() => {
      const allConnectedMachineTypes =
        printers && allAppMachineTypes?.appMachineTypes
          ? allAppMachineTypes.appMachineTypes.filter(a =>
              printers.find(p => machineTypeMatchesLivePrinterModelName(a.name, p))
            )
          : [];

      const potentialMachineTypes = allConnectedMachineTypes.filter(
        a => !allMachines.some(m => m.appMachineType.id === a.id && !m.dateDeleted)
      );

      const machineCardMap: Record<number, ReactElement[]> = {};
      // Potential machine cards are grouped by technology and are shown after
      // all machine cards
      const potentialMachineCardMap: Record<number, ReactElement[]> = {};

      for (const tech of shopTechnologies) {
        if (currentTechnology && tech.id !== currentTechnology?.id) {
          continue;
        }

        for (const machine of tech.shopMachines) {
          if (machine.dateDeleted) {
            continue;
          }

          if (!machineCardMap[tech.appTechnology.id]) {
            machineCardMap[tech.appTechnology.id] = [];
          }

          machineCardMap[tech.appTechnology.id].push(
            <GridColumn
              key={machine.appMachineType.id}
              mobile={16}
              tablet={8}
              computer={5}
              largeScreen={4}
              widescreen={3}
            >
              <ShopMachineCard
                machine={machine}
                loadingImage={loadingMachineImages}
                technology={tech}
                openDeleteModal={(shopMachine: IShopMachine) => {
                  setShopMachineToDelete(shopMachine);
                }}
              />
            </GridColumn>
          );
        }
      }

      for (const potentialMachine of potentialMachineTypes) {
        if (
          currentTechnology &&
          potentialMachine.appTechnology.id !== currentTechnology?.appTechnology.id
        ) {
          continue;
        }

        if (!potentialMachineCardMap[potentialMachine.appTechnology.id]) {
          potentialMachineCardMap[potentialMachine.appTechnology.id] = [];
        }

        potentialMachineCardMap[potentialMachine.appTechnology.id].push(
          <GridColumn
            key={potentialMachine.id}
            mobile={16}
            tablet={8}
            computer={5}
            largeScreen={4}
            widescreen={3}
          >
            <PotentialShopMachineCard
              appMachineType={potentialMachine}
              appTechnology={potentialMachine.appTechnology}
            />
          </GridColumn>
        );
      }

      return {
        machineCards: Object.values(machineCardMap),
        potentialMachineCards: Object.values(potentialMachineCardMap),
      };
    }, [printers, allAppMachineTypes, shopTechnologies, currentTechnology, loadingMachineImages]);

    if (loadingTechnologies) {
      return (
        <>
          {header}
          <Loader active={true} size="large" />
        </>
      );
    }

    if (machineCards.length === 0 && potentialMachineCards.length === 0) {
      return (
        <>
          {header}
          <PlaceHolder id="qa-placeHolder">
            <Image src={PlaceholderSVG} />
            <h2>{t("shop.machines.placeholder.header")}</h2>
            <p>{t("shop.machines.placeholder.copy")}</p>
            <UiCan do={Permission.CREATE_MACHINE} on={currentShop}>
              <Link to={ROUTES.SHOP(currentShop.id).MACHINES.NEW}>
                <Button className="qa-button-addMachineType" primary>
                  {t("machines.form.appMachineTypes.grid.addMachine")}
                </Button>
              </Link>
            </UiCan>
          </PlaceHolder>
        </>
      );
    }

    let deleteMachineHeaderKey: string;
    let deleteMachineWarningKey: string;
    if (machineCards.length > 1) {
      deleteMachineHeaderKey = "shop.machine.delete.header";
      deleteMachineWarningKey = "shop.machine.delete.warning";
    } else {
      deleteMachineHeaderKey = "shop.machine.delete.header.last";
      deleteMachineWarningKey = "shop.machine.delete.warning.last";
    }

    return (
      <>
        {header}
        <Grid stretched>{[...machineCards, ...potentialMachineCards]}</Grid>
        <Mutation<any, { id: number | undefined }>
          mutation={DELETE_SHOP_MACHINE}
          update={() => {
            Notifier.success(
              `${t("shop.machine.deleted")} ${shopMachineToDelete?.appMachineType.displayName}`
            );
            if (technologyId && currentTechnology && currentTechnology.shopMachines.length === 1) {
              // If deleting last machine from technology, navigate back to full machines list
              history.push(`${ROUTES.SHOP(currentShop.id).MACHINES.INDEX}`);
            }
          }}
          onError={(error: ApolloError) => Notifier.error(error)}
          variables={{ id: shopMachineToDelete?.id }}
        >
          {deleteMachine => (
            <ConfirmationModal
              headerIcon="trash"
              headerCopy={t(deleteMachineHeaderKey)}
              bodyTitle={shopMachineToDelete?.appMachineType.displayName}
              bodyCopy={t(deleteMachineWarningKey)}
              cancelTranslationKey="general.cancel"
              confirmTranslationKey="shop.machine.delete"
              open={!!shopMachineToDelete}
              onClose={() => setShopMachineToDelete(undefined)}
              submitAction={deleteMachine}
            />
          )}
        </Mutation>
      </>
    );
  }
);
ScreensShopMachineList.displayName = "ScreensShopMachineList";
