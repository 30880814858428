import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import ReactGA from "react-ga";
import StackGrid from "react-stack-grid";

import { ROUTES } from "@/shopConstants";
import styled, {
  Avatar,
  Button,
  Header,
  Image,
  Tab,
  Loader,
  StyleMixins,
  Table,
  TableCell,
  TableRow,
  TableHeader,
  TableHeaderCell,
  TableBody,
} from "grabcad-ui-elements";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { PlaceHolder } from "@/components/Shared/Placeholder";
import { ApplicationContext } from "@/components/ApplicationProvider";
import PlaceholderClipboards from "@/assets/placeholder_orders.svg";
import { IShopJob, IShopRoute, JOB_COUNTS, SHOP_JOBS, SHOP_ROUTES } from "@/graphql/Queries/Job";
import { ApolloQueryResult, useQuery, useApolloClient } from "@apollo/client";
import { getCadModelName } from "@/utils/GeneralUtils";
import { getItemQuantitiesByStep, getSummedQuantitiesByStep } from "./Job";
import { ContextMenu } from "@/components/Shop/Machines/ContextMenu";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { DELETE_JOB } from "@/graphql/Mutations/Job";
import { useShopTechnologies } from "@/utils/queryHooks";
import { updateCadModelQueryCache } from "@/graphql/Utils/updateCadModelQueryCacheUtil";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import _ from "lodash";
import { updateCachedOrderItemsJob } from "./New/JobForm";
import { getBrowserSettings, saveBrowserSettings } from "@/utils/localStorageSettings";
import classnames from "classnames";

const JobsList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-row {
    flex: 0 0 auto;
    margin-bottom: 1em;
    display: flex;
    h2 {
      flex-grow: 1;
    }
    .button {
      margin-top: 14px !important;
    }
  }
  a.action:hover {
    text-decoration: underline;
  }
  // Shared with company-server\frontend\src\screens\Printer\Printers.tsx
  .display-modes {
    display: flex;

    .ui.button {
      width: 38px;
      height: 38px;
      padding: 0;

      rect {
        fill: #cccccc;
      }

      &:not(.primary) {
        cursor: pointer;

        &:hover rect {
          fill: #444;
        }
      }

      &.primary {
        cursor: default;

        rect {
          fill: white;
        }
      }

      > div {
        // Override some .flex-button fanciness
        overflow: visible;
        padding: 9px 0;
      }
    }
  }
  .list {
    ${StyleMixins.roundAndShadow}
    flex: 1 1 100px;
    padding: 20px;
    min-width: 400px;
    .list-header {
      display: flex;
      .tabs {
        position: relative;
        top: -12px;
        margin-bottom: 5px;
        flex: 1 1 auto;
        padding-right: 15px;
      }
    }
    .job-card {
      cursor: pointer;
      background: #f4f5f7;
      border: 1px solid #dddddd;
      border-radius: 4px;
      &:hover {
        box-shadow: 0 0 10px rgba(34, 36, 38, 0.25) !important;
      }
      .num-parts {
        opacity: 0.7;
      }
      .header {
        border-bottom: 1px solid #dddddd;
        padding: 10px 15px;
        display: flex;
        .job-info {
          max-width: 100%;
          flex-grow: 1;
          h3 {
            font-size: 12px;
            font-weight: normal;
            margin: 0 0 2px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .icon.ellipsis {
          top: 1px;
          right: 0;
        }
      }
      .progress-bar {
        height: 6px;
        border-radius: 3px;
        background: #ffffff;
        border: 1px solid #dddddd;
        margin: 4px -0;
        > div {
          height: 6px;
          border-radius: 3px;
          background: #003393;
          position: relative;
          top: -1px;
          left: -1px;
        }
      }
      .order-items {
        max-height: calc(78px * 5 + 10px);
        overflow: auto;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 6px;
        }
      }
      .order-item {
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 3px;
        display: flex;
        margin: 10px;
        padding: 8px;
        .details {
          min-width: 10px;
          flex: 1 1 10px;
          margin-left: 10px;
          h4 {
            font-size: 12px;
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
          }
          .num-parts {
            font-size: 10px;
            margin: -2px 0 -3px;
          }
          .full-name {
            height: 15px;
            position: relative;
            left: -3px;
            > div {
              height: 15px;
              padding-top: 2.5px;
            }
          }
        }
      }
    }
  }
`;

export const StyledSmallThumbnail = styled.div`
  border: 1px solid #dddddd;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  width: 54px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 35px;
  }
`;

enum TABS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ALL = "ALL",
}

type jobCountsResponse = {
  jobCountsByShop: {
    activeJobs: number;
    completedJobs: number;
  };
};

export const ScreensShopJobsList = withRouter(({ history }): JSX.Element | null => {
  const { currentShop, t, formatDate } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { shopTechnologies, allMachines, allMaterials } = useShopTechnologies();
  const jobCountsQuery = useQuery<jobCountsResponse, { id: number }>(JOB_COUNTS, {
    variables: { id: currentShop.id },
  });
  const activeJobsCount = jobCountsQuery.data?.jobCountsByShop.activeJobs;
  const completedJobsCount = jobCountsQuery.data?.jobCountsByShop.completedJobs;
  const totalJobsCount =
    activeJobsCount && completedJobsCount && activeJobsCount + completedJobsCount;

  const client = useApolloClient();
  const [currentTab, setCurrentTab] = useState(TABS.ACTIVE);
  const [jobRouteIdsRefetched, setJobRouteIdsRefetched] = useState<number[]>([]);
  const jobRoutesQuery = useQuery<{ loadShopRoutes: IShopRoute[] }, { id: number }>(SHOP_ROUTES, {
    variables: { id: currentShop.id },
  });
  const [jobToDelete, setJobToDelete] = useState<IShopJob | undefined>();

  const fetchJobs =
    (
      active: boolean,
      setJobs: (jobs: IShopJob[]) => void,
      setLoading: (loading: boolean) => void
    ) =>
    async () => {
      setLoading(true);
      const { data, errors }: ApolloQueryResult<{ loadShopJobs: IShopJob[] }> = await client.query({
        // FIXME use updated cache instead of network req after Apollo upgrade
        fetchPolicy: "network-only",
        query: SHOP_JOBS,
        variables: { active, id: currentShop.id },
      });
      if (!errors && data) {
        setJobs(data.loadShopJobs);
      }
      setLoading(false);
      if (data.loadShopJobs) {
        const orderItemIds = _.flatten(data.loadShopJobs.map(job => job.orderItems)).map(
          item => item.id
        );
        updateCadModelQueryCache(client, orderItemIds);
      }
    };

  const [activeJobsLoading, setActiveJobsLoading] = useState(false);
  const [activeJobs, setActiveJobs] = useState([] as IShopJob[]);
  const fetchActiveJobs = fetchJobs(true, setActiveJobs, setActiveJobsLoading);

  const [completedJobsLoading, setCompletedJobsLoading] = useState(false);
  const [completedJobs, setCompletedJobs] = useState([] as IShopJob[]);
  const fetchCompletedJobs = fetchJobs(false, setCompletedJobs, setCompletedJobsLoading);

  const refetchJobs = () => {
    if (currentTab === TABS.ACTIVE) {
      fetchActiveJobs();
    } else if (currentTab === TABS.INACTIVE) {
      fetchCompletedJobs();
    } else if (currentTab === TABS.ALL) {
      fetchActiveJobs();
      fetchCompletedJobs();
    }
  };
  useEffect(() => refetchJobs(), [currentTab]);

  let shopJobs = [] as IShopJob[];
  let showColumn_dateCompleted = true;
  let showColumn_needBy = true;
  if (currentTab === TABS.ACTIVE) {
    shopJobs = activeJobs;
    showColumn_dateCompleted = false;
  } else if (currentTab === TABS.INACTIVE) {
    shopJobs = completedJobs;
    showColumn_needBy = false;
  } else if (currentTab === TABS.ALL) {
    shopJobs = activeJobs.concat(completedJobs);
  }

  // Number of columns is based on available width
  const MIN_COL_WIDTH = 250;
  const GUTTER_WIDTH = 20;
  const gridHolderRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(gridHolderRef.current?.offsetWidth || 1000);
  let timeoutId: NodeJS.Timeout | null = null;
  const handleResize = () => {
    timeoutId && clearTimeout(timeoutId); // Prevent execution of previous setTimeout
    // Change width from the state object after 150 milliseconds
    timeoutId = setTimeout(() => setWidth(gridHolderRef.current?.offsetWidth || 1000), 150);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useLayoutEffect(() => {
    handleResize();
  }, [activeJobsLoading]);
  const cols = Math.max(Math.floor(width / MIN_COL_WIDTH), 1);
  const colWidth = (width - cols * GUTTER_WIDTH) / cols;

  const { jobsTableView } = getBrowserSettings();
  const [isTableView, setIsTableView] = useState(jobsTableView);
  const setTableView = (value: boolean) => {
    setIsTableView(value);
    saveBrowserSettings({ jobsTableView: value });
    handleResize(); // Recalculate grid width in case resize happened and it was not visible
  };

  return (
    <JobsList>
      <ConfirmationModal
        headerIcon="trash"
        headerCopy={t("shop.job.delete.header")}
        bodyTitle={jobToDelete?.name}
        bodyCopy={t("shop.job.delete.warning")}
        cancelTranslationKey="general.cancel"
        confirmTranslationKey="general.delete"
        open={!!jobToDelete}
        onClose={() => setJobToDelete(undefined)}
        submitAction={async () => {
          await client.mutate({
            mutation: DELETE_JOB,
            variables: { id: jobToDelete?.id },
            update: (_cache, response) => {
              if (response.data) {
                const activeJobsAfterDelete = [
                  ...activeJobs.filter(job => job.id !== response.data.deleteJob.id),
                ];
                const completedJobsAfterDelete = [
                  ...completedJobs.filter(job => job.id !== response.data.deleteJob.id),
                ];
                const cachedJobCountsByShopQuery = _cache.readQuery<jobCountsResponse>({
                  query: JOB_COUNTS,
                  variables: { id: currentShop.id },
                });
                if (cachedJobCountsByShopQuery) {
                  const cachedJobCountsByShop = { ...cachedJobCountsByShopQuery.jobCountsByShop };
                  if (activeJobsAfterDelete.length !== activeJobs.length) {
                    // if active job was deleted
                    cachedJobCountsByShop.activeJobs--;
                  } else {
                    // if completed job was deleted
                    cachedJobCountsByShop.completedJobs--;
                  }
                  _cache.writeQuery({
                    data: { jobCountsByShop: cachedJobCountsByShop },
                    query: JOB_COUNTS,
                    variables: { id: currentShop.id },
                  });

                  if (jobToDelete?.orderItems.length) {
                    updateCachedOrderItemsJob(
                      client,
                      null,
                      jobToDelete?.orderItems.map(item => item.id)
                    );
                  }
                }

                setActiveJobs(activeJobsAfterDelete);
                setCompletedJobs(completedJobsAfterDelete);
              }
            },
          });
          ReactGA.event({
            category: "GcShop Jobs",
            action: "Deleted Job",
            label: `Shop ${currentShop.id}`,
          });
        }}
      />
      <div className="header-row">
        <Header as="h2" className="page-header">
          {t("shop.jobs")}
        </Header>
        <UiCan do={Permission.CREATE_JOBS} on={currentShop}>
          <Button
            className="qa-button-newJob"
            primary
            content={t("shop.jobs.new")}
            onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.NEW)}
          />
        </UiCan>
      </div>
      <div className="list">
        <div className="list-header">
          <Tab
            className="tabs"
            menu={{ secondary: true, pointing: true }}
            activeIndex={Object.keys(TABS).indexOf(currentTab)}
            onTabChange={(_event, { activeIndex }) => {
              setCurrentTab(Object.values(TABS)[activeIndex as number]);
            }}
            panes={[
              { menuItem: `${t("shop.jobs.active")} (${activeJobsCount ?? " "})` },
              { menuItem: `${t("shop.jobs.completed")} (${completedJobsCount ?? " "})` },
              { menuItem: `${t("shop.jobs.all")} (${totalJobsCount ?? " "})` },
            ]}
          />
          <div className="display-modes">
            <Button
              type="button"
              primary={!isTableView}
              secondary={!!isTableView}
              onClick={() => setTableView(false)}
              data-testid="cardsViewButton"
            >
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="10" y="0.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                <rect y="0.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                <rect x="10" y="10.5" width="7" height="7" rx="1" fill="#CCCCCC" />
                <rect y="10.5" width="7" height="7" rx="1" fill="#CCCCCC" />
              </svg>
            </Button>
            <Button
              type="button"
              primary={!!isTableView}
              secondary={!isTableView}
              onClick={() => setTableView(true)}
              data-testid="tableViewButton"
            >
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="7.5" width="17" height="3" rx="1" fill="white" />
                <rect y="14.5" width="17" height="3" rx="1" fill="white" />
                <rect y="0.5" width="17" height="3" rx="1" fill="white" />
              </svg>
            </Button>
          </div>
        </div>

        {activeJobsLoading || completedJobsLoading ? (
          <Loader active={true} size="small" data-testid="loader" />
        ) : !shopJobs.length ? (
          <PlaceHolder id="qa-placeHolder">
            <Image src={PlaceholderClipboards} height="145" />
            <h2>
              {currentTab === TABS.ACTIVE
                ? t("shop.jobs.placeholder.header.active")
                : currentTab === TABS.INACTIVE
                ? t("shop.jobs.placeholder.header.completed")
                : t("shop.jobs.placeholder.header.all")}
            </h2>
            <p>{t("shop.jobs.placeholder.copy")}</p>
            <UiCan do={Permission.CREATE_JOBS} on={currentShop}>
              <Button
                id="qa-button-newJob"
                primary
                content={t("shop.jobs.new")}
                onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.NEW)}
              />
            </UiCan>
          </PlaceHolder>
        ) : isTableView ? (
          <Table data-testid="jobsTable">
            <TableHeader>
              <TableRow>
                <TableHeaderCell>{t("shop.jobs.tableHeader.dateCreated")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.name")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.tableHeader.cadFiles")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.tableHeader.parts")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.tableHeader.tech")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.tableHeader.machine")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.tableHeader.material")}</TableHeaderCell>
                {showColumn_needBy && (
                  <TableHeaderCell>{t("shop.jobs.tableHeader.needBy")}</TableHeaderCell>
                )}
                {showColumn_dateCompleted && (
                  <TableHeaderCell>{t("shop.jobs.tableHeader.dateCompleted")}</TableHeaderCell>
                )}
                <TableHeaderCell>{t("shop.jobs.tableHeader.operator")}</TableHeaderCell>
                <TableHeaderCell>{t("shop.jobs.tableHeader.actions")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {shopJobs.map(job => {
                const numParts = job.orderItems.reduce((acc, cur) => acc + cur.quantity, 0);

                const shopTechnology = shopTechnologies.find(
                  tech => tech.id === job?.shopTechnologyId
                );
                const shopMachine = allMachines.find(machine => machine.id === job?.shopMachineId);
                const shopMaterial = allMaterials.find(
                  material => material.id === job?.shopMaterialId
                );
                const earliestNeedBy =
                  job.orderItems.reduce((acc, cur) => {
                    const itemNeedBy = cur.order?.needByDate;
                    return itemNeedBy && (!acc || itemNeedBy < acc) ? itemNeedBy : acc;
                  }, undefined as string | undefined) || "";

                return (
                  <TableRow
                    key={job.id}
                    onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.SHOW(job.id))}
                    className={classnames({
                      // Show blue flash on row if job was created in the last 10 seconds.
                      // This is to help users quickly identify new jobs. The "dumb" timestamp check avoids any
                      // state management or a #fragment polluting the URL. "Good enough" for this bit of UI polish.
                      // _Might not work well in local dev envs? My server is from 5 hours in the future._
                      "just-added": new Date(job.dateCreated).getTime() > Date.now() - 10000,
                    })}
                    data-testid={`jobRow-${job.id}`}
                  >
                    <TableCell>{formatDate(job.dateCreated)}</TableCell>
                    <TableCell>{job.name}</TableCell>
                    <TableCell>{job.orderItems.length}</TableCell>
                    <TableCell>{numParts}</TableCell>
                    <TableCell>{shopTechnology?.appTechnology.displayName}</TableCell>
                    <TableCell>
                      {shopMachine?.appMachineType.displayName || t("entity.any")}
                    </TableCell>
                    <TableCell>
                      {shopMaterial?.appMaterial.displayName || t("entity.any")}
                    </TableCell>
                    {showColumn_needBy && <TableCell>{formatDate(earliestNeedBy)}</TableCell>}
                    {showColumn_dateCompleted && (
                      <TableCell>
                        {job.dateCompleted ? formatDate(job.dateCompleted) : "-"}
                      </TableCell>
                    )}
                    <TableCell>
                      <Avatar name={job.user.name} id={job.user.email} />
                    </TableCell>
                    <TableCell>
                      <a
                        className="action"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setJobToDelete(job);
                        }}
                      >
                        {t("shop.jobs.actions.delete")}
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <div
            ref={gridHolderRef}
            style={{ marginLeft: -GUTTER_WIDTH / 2, marginRight: -GUTTER_WIDTH / 2 }}
          >
            <StackGrid
              columnWidth={colWidth}
              gutterWidth={GUTTER_WIDTH}
              gutterHeight={20}
              duration={0}
            >
              {shopJobs.map(job => {
                const numParts = job.orderItems.reduce((acc, cur) => acc + cur.quantity, 0);

                let percentCompleted = 0;
                const jobRoute = jobRoutesQuery.data?.loadShopRoutes.find(
                  route => route.id === job.shopRouteId
                );
                if (
                  !!jobRoutesQuery.data &&
                  !jobRoute &&
                  !jobRouteIdsRefetched.includes(job.shopRouteId)
                ) {
                  // This can happen for the first job created for a Technology. The new Route is created as needed server side.
                  jobRoutesQuery.refetch();
                  // This is to avoid any potential for looping network requests if something goes wrong:
                  setJobRouteIdsRefetched([job.shopRouteId, ...jobRouteIdsRefetched]);
                }
                if (jobRoute) {
                  const orderItemQuantityByStep = getItemQuantitiesByStep(job, jobRoute);
                  const summedQuantitiesByStep = getSummedQuantitiesByStep(orderItemQuantityByStep);
                  const totalQuantity = summedQuantitiesByStep.reduce((acc, cur) => acc + cur, 0);
                  const quantityInLastStep =
                    summedQuantitiesByStep[summedQuantitiesByStep.length - 1];
                  percentCompleted = (quantityInLastStep / totalQuantity) * 100;
                }

                const shopTechnology = shopTechnologies.find(
                  tech => tech.id === job.shopTechnologyId
                );
                const shopMachine = allMachines.find(machine => machine.id === job.shopMachineId);

                return (
                  <div
                    key={job.id}
                    onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.SHOW(job.id))}
                    data-testid="jobCard"
                    className="job-card"
                  >
                    <div
                      className={classnames({
                        // Show blue flash on card if job was created in the last 10 seconds.
                        // This is to help users quickly identify new jobs. The "dumb" timestamp check avoids any
                        // state management or a #fragment polluting the URL. "Good enough" for this bit of UI polish.
                        // _Might not work well in local dev envs? My server is from 5 hours in the future._
                        // Making a wrapper div just for this is the easiest way to share the style.
                        "just-added": new Date(job.dateCreated).getTime() > Date.now() - 10000,
                      })}
                    >
                      <div className="header">
                        <div className="job-info">
                          <h3>{job.name}</h3>
                          <div className="num-parts" data-testid="numParts">{`${numParts} ${t(
                            "shop.jobs.parts"
                          )}`}</div>
                          <div className="progress-bar">
                            <div style={{ width: `${percentCompleted}%` }}></div>
                          </div>
                          <h3>
                            {shopMachine?.appMachineType.displayName ||
                              shopTechnology?.appTechnology.displayName}
                          </h3>
                        </div>
                        <ContextMenu
                          position="bottom right"
                          menuItems={[
                            <a
                              className="item"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(ROUTES.SHOP(currentShop.id).JOBS.SHOW(job.id));
                              }}
                              key="edit-link"
                            >
                              {t("shop.edit")}
                            </a>,
                            <a
                              className="item delete"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setJobToDelete(job);
                              }}
                              key="delete-link"
                            >
                              {t("shop.delete")}
                            </a>,
                          ]}
                        />
                      </div>
                      <div className="order-items">
                        {job.orderItems.map(item => (
                          <div className="order-item" key={item.id} data-testid="orderItem">
                            <StyledSmallThumbnail>
                              <CadModelPreview itemId={item.id} size={"small"} />
                            </StyledSmallThumbnail>
                            <div className="details">
                              <h4>{getCadModelName(item)}</h4>
                              <div className="num-parts" data-testid="numParts">{`${
                                item.quantity
                              } ${t("shop.jobs.parts")}`}</div>
                              <Avatar
                                fullName
                                name={item.order?.user?.name}
                                id={item.order?.user?.email}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </StackGrid>
          </div>
        )}
      </div>
    </JobsList>
  );
});
ScreensShopJobsList.displayName = "ScreensShopJobsList";
