import gql from "graphql-tag";
import { TECHNOLOGY } from "../../shopConstants";
import { IAppMachineType, AppMachineTypeFragments } from "./AppMachineType";

export const AppTechnologyFragments = {
  appTechnology: gql`
    fragment appTechnologyFields on AppTechnology {
      id
      name
      displayName
      appMachineTypes {
        ...appMachineTypeFields
      }
      __typename
    }
    ${AppMachineTypeFragments.appMachineType}
  `,
};

// See AppTechnologyFragments.appTechnology
export interface IAppTechnology {
  id: number;
  name: TECHNOLOGY;
  displayName: string;
  appMachineTypes?: IAppMachineType[];
  __typename?: "AppTechnology";
}
