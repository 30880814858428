import React, { FunctionComponent, useState } from "react";
import styled, { Button, Icon, Loader } from "grabcad-ui-elements";
import { ApolloClient, ApolloConsumer, ApolloError } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { UPDATE_PREFERENCE } from "@/graphql/Mutations/User";
import { Notifier } from "@/utils/Notifier";
import { COMPLETED_ONBOARDING } from "@/utils/preferences";
import {
  ADMIN_CONSOLE_LINK_ONBOARDING,
  ADMIN_ORDER_LIST_ONBOARDING,
} from "@/components/Order/List/List";
import { CREATE_SHOP_SAMPLE } from "@/graphql/Mutations/ShopSample";
import {
  HistoryEventTree,
  IParentNode,
} from "@/components/Order/CommentsAndHistory/HistoryEventTree";
import { CREATE_DUMMY_ORDERS, DELETE_DUMMY_ORDERS } from "@/graphql/Mutations/Order";
import { useLivePrinters } from "@/utils/useLivePrinters";
import { inspect } from "util";

/*
  This component is not end-user-facing. It's for internal use by QA & Product.
  Accessed via "secret" route: /debugMenu
*/

const DebugRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid grey;
  > label {
    width: 300px;
    text-align: right;
    padding-right: 20px;
  }
`;

export const DebugMenu: FunctionComponent<any> = () => {
  const [shopId, setShopId] = useState(1);
  const [orderCount, setOrderCount] = useState(5);
  const [orderItemCount, setOrderItemCount] = useState(10);
  const [dummyOrdersInFlight, setDummyOrdersInFlight] = useState(false);
  const { printers, errorMessage } = useLivePrinters();
  console.log(printers);

  const treeViewItems: IParentNode[] = [
    {
      title: "Chuck N. - Updated the order",
      subTitle: "10/22/20 8:19 AM",
      children: [
        {
          name: "Order",
          nodes: [
            {
              name: "Delivery By",
              content: (
                <>
                  <Icon name="long arrow alternate right" /> 07/19/2020
                </>
              ),
            },
            {
              name: "Price",
              content: (
                <>
                  0 <Icon name="long arrow alternate right" /> 50
                </>
              ),
            },
          ],
        },
        {
          name: "Moon 4.stl",
          nodes: [
            {
              name: "Material",
              content: (
                <>
                  ABS-M30 <Icon name="long arrow alternate right" /> Makerbot ASA
                </>
              ),
            },
            {
              name: "Color",
              content: (
                <>
                  Black <Icon name="long arrow alternate right" /> White
                </>
              ),
            },
            {
              name: "Quantity",
              content: (
                <>
                  1 <Icon name="long arrow alternate right" /> 2
                </>
              ),
            },
          ],
        },
      ],
    },
  ];

  const setOnboardingStatus = async (client: ApolloClient<any>, value: string[] = []) => {
    try {
      await client.mutate({
        mutation: UPDATE_PREFERENCE,
        variables: { key: COMPLETED_ONBOARDING, value },
      });

      Notifier.success("Onboarding status set!");
    } catch (error) {
      if (error instanceof ApolloError) {
        Notifier.error({ graphQLErrors: error.graphQLErrors });
      } else {
        throw error;
      }
    }
  };

  return (
    <ApolloConsumer>
      {client => (
        <>
          <DebugRow>
            <label>Set Grabcad Control link onboarding to seen:</label>
            <Button onClick={() => setOnboardingStatus(client, [ADMIN_CONSOLE_LINK_ONBOARDING])}>
              Set
            </Button>
          </DebugRow>
          <DebugRow>
            <label>
              Set orders list onboarding to seen (first iteration of auto shop generation):
            </label>
            <Button onClick={() => setOnboardingStatus(client, [ADMIN_ORDER_LIST_ONBOARDING])}>
              Set
            </Button>
          </DebugRow>
          <DebugRow>
            <label>Reset all onboarding:</label>
            <Button onClick={() => setOnboardingStatus(client)}>Reset</Button>
          </DebugRow>
          <DebugRow>
            <label>Create Sample Shop:</label>
            <Mutation<unknown, { force: boolean }>
              mutation={CREATE_SHOP_SAMPLE}
              variables={{ force: true }}
              onCompleted={() => {
                Notifier.success("Sample Shop Created!");
              }}
              onError={err => Notifier.error(err)}
            >
              {generateSampleShop => <Button onClick={() => generateSampleShop()}>Create</Button>}
            </Mutation>
          </DebugRow>
          <DebugRow>
            <label>HistoryEventTree</label>
            <HistoryEventTree items={treeViewItems} />
          </DebugRow>
          <DebugRow>
            <label style={{ position: "relative" }}>
              Create Dummy Orders:{" "}
              {dummyOrdersInFlight && <Loader active={true} size="small" style={{ left: "40%" }} />}
            </label>
            <div>
              <label>Shop Id: </label>
              <input value={shopId} onChange={event => setShopId(+event.currentTarget.value)} />
              <br />
              <label>Order count: </label>
              <input
                value={orderCount}
                onChange={event => setOrderCount(+event.currentTarget.value)}
              />
              <br />
              <label>OrderItems per order: </label>
              <input
                value={orderItemCount}
                onChange={event => setOrderItemCount(+event.currentTarget.value)}
              />
              <br />
              <Mutation<unknown, any>
                mutation={CREATE_DUMMY_ORDERS}
                variables={{ input: { shopId, orderCount, orderItemCount } }}
                onCompleted={() => {
                  Notifier.success(`${orderCount} Dummy Orders Created!`);
                  setDummyOrdersInFlight(false);
                }}
                onError={error => {
                  Notifier.error(error);
                  setDummyOrdersInFlight(false);
                }}
              >
                {createDummyOrders => (
                  <Button
                    onClick={() => {
                      setDummyOrdersInFlight(true);
                      createDummyOrders();
                    }}
                    disabled={dummyOrdersInFlight}
                  >
                    Create
                  </Button>
                )}
              </Mutation>
              <Mutation<unknown, any>
                mutation={DELETE_DUMMY_ORDERS}
                variables={{ shopId }}
                onCompleted={() => {
                  Notifier.success("All Dummy Orders Deleted!");
                  setDummyOrdersInFlight(false);
                }}
                onError={error => {
                  Notifier.error(error);
                  setDummyOrdersInFlight(false);
                }}
              >
                {deleteDummyOrders => (
                  <Button
                    onClick={() => {
                      setDummyOrdersInFlight(true);
                      deleteDummyOrders();
                    }}
                    disabled={dummyOrdersInFlight}
                  >
                    Delete Dummy Orders
                  </Button>
                )}
              </Mutation>
            </div>
          </DebugRow>
          <DebugRow>
            <label>PrintersAPI</label>
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              {errorMessage || inspect(printers)}
            </div>
          </DebugRow>
        </>
      )}
    </ApolloConsumer>
  );
};
