import React, { useCallback, useContext, useState } from "react";
import styled, { Card, CardContent, CardHeader, Icon } from "grabcad-ui-elements";
import { CardLinkWrapper } from "@/components/Shared/CardLink";
import { useHistory } from "react-router-dom";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { MachineImage } from "./MachineImage";
import { PrinterStatusSummary } from "../../../screens/Shop/Printer/PrinterStatusSummary";
import { ROUTES, SHOP } from "../../../shopConstants";
import { CREATE_SHOP_MACHINE, type IShopMachineInput } from "@/graphql/Mutations/ShopMachine";
import { ApolloError, useMutation } from "@apollo/client";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import { SHOP_TECHNOLOGIES_WITH_MACHINES } from "../../../graphql/Queries/Shop";
import { Notifier } from "../../../utils/Notifier";

const StyledAddButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 22px;
  height: 22px;

  .icon {
    margin: 0;
    color: #003393;
  }
`;

interface IAppMachineType {
  id: number;
  name: string;
  displayName: string;
}

interface IAppTechnology {
  displayName: string;
}

interface IPotentialShopMachineCardProps {
  appMachineType: IAppMachineType;
  appTechnology: IAppTechnology;
}

const MachineName = styled.div`
  color: #444;
  font-size: 18px;
`;

const TechnologyName = styled.div`
  color: #999;
  font-size: 14px;
`;

export const PotentialShopMachineCard = ({
  appMachineType,
  appTechnology,
}: IPotentialShopMachineCardProps): JSX.Element | null => {
  const { currentShop, t } = useContext(ApplicationContext);
  const [createShopMachine] = useMutation<{ createShopMachine: IShopMachine }, IShopMachineInput>(
    CREATE_SHOP_MACHINE
  );
  const history = useHistory();
  const [isCreatingMachine, setIsCreatingMachine] = useState(false);

  const createMachine = useCallback(() => {
    if (!currentShop?.id) {
      return;
    }

    // This is meant to prevent someone from spam-clicking a card and creating
    // duplicate machines
    setIsCreatingMachine(true);

    void (async () => {
      if (isCreatingMachine) {
        return;
      }

      await createShopMachine({
        variables: {
          input: {
            shopId: currentShop.id,
            appMachineTypeId: appMachineType.id,
          },
        },
        refetchQueries: [
          {
            query: SHOP_TECHNOLOGIES_WITH_MACHINES,
            variables: { id: currentShop.id },
          },
        ],
        onCompleted: res => {
          history.push(
            ROUTES.SHOP(currentShop.id, false).MACHINES.SHOW({
              machineId: res.createShopMachine.id,
              subSection: SHOP.SECTIONS.materials,
            })
          );
        },
        onError: (err: ApolloError) => {
          Notifier.error(err);
          setIsCreatingMachine(false);
        },
      });
    })();
  }, [currentShop, createShopMachine, history, appMachineType, isCreatingMachine]);

  if (!currentShop) {
    return null;
  }

  return (
    <UiCan do={Permission.CREATE_MACHINE} on={currentShop}>
      <CardLinkWrapper data-testid="potentialMachineCard">
        <a
          onClick={createMachine}
          data-tooltip={t("printer.addAsMachine")}
          data-position="top center"
        >
          <Card link raised>
            <MachineImage machine={{ appMachineType }} />
            <CardContent>
              <CardHeader>
                <MachineName data-testid="potentialMachineName">
                  {appMachineType.displayName}
                </MachineName>
                <TechnologyName>{appTechnology.displayName}</TechnologyName>
              </CardHeader>
              <PrinterStatusSummary machineType={appMachineType.name} />
            </CardContent>
            <StyledAddButton>
              <Icon name="plus circle" size="large" />
            </StyledAddButton>
          </Card>
        </a>
      </CardLinkWrapper>
    </UiCan>
  );
};
