import React, { useContext, useRef, useEffect } from "react";
import styled, { Checkbox } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UPDATE_SHOP_PREFERENCES } from "@/graphql/Mutations/Shop";
import { useMutation, useQuery } from "@apollo/client";
import { SHOP_DETAILS } from "@/graphql/Queries";
import { IShop, IShopPreferences } from "@/graphql/Fragments/Shop";
import { PrefSectionHeader } from "./Page";
import { FeatureKey } from "@/graphql/Fragments/UserLicense";
import { DeepPartial } from "utility-types";

const StyledToggleLabel = styled.label`
  display: flex;
  alignitems: center;
  cursor: pointer;
  margin-top: 10px;
  .ui.checkbox {
    margin-right: 5px;
  }
`;

export const AutoEstimationPrefs = (): JSX.Element | null => {
  const {
    t,
    currentShop,
    currentUser,
    setShopPreferences: updateShopPrefsContext,
  } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { data } = useQuery<{ shop: IShop }>(SHOP_DETAILS, {
    variables: { id: currentShop.id },
  });
  const [updateShopPrefsMutation] = useMutation<
    { updateShopPreferences: IShop },
    { shopId: number; prefs: DeepPartial<IShopPreferences> }
  >(UPDATE_SHOP_PREFERENCES);
  // This prevents the page from stuttering when the context is updated and the app
  // is re-rendered. We need to update the prefs in the context, but we wait until
  // the component unmounts to do so. This can't be component state because the
  // cleanup handler returned by useEffect captures the state's initial value.
  const lastShopPrefsRef = useRef<IShopPreferences | null>(null);

  useEffect(
    () => () => {
      if (lastShopPrefsRef.current) {
        updateShopPrefsContext(lastShopPrefsRef.current);
      }
    },
    []
  );

  const hasAutoEstimationsFeature = currentUser.userProfile?.features?.some(
    f => f.featureKey === FeatureKey.AUTO_ESTIMATION
  );
  if (!hasAutoEstimationsFeature || !data?.shop) {
    return null;
  }

  const updateShopPrefs = async (prefs: Partial<IShopPreferences["autoEstimation"]>) => {
    const result = await updateShopPrefsMutation({
      variables: {
        shopId: currentShop.id,
        prefs: { autoEstimation: prefs },
      },
    });

    if (!result.data) {
      return;
    }

    lastShopPrefsRef.current = result.data.updateShopPreferences.shopPreferences;
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <PrefSectionHeader className="page-header">
        <h2>{t("shop.preferences.autoEstimations.title")}</h2>
      </PrefSectionHeader>
      <StyledToggleLabel>
        <Checkbox
          data-testid="autoEstimationEnabled"
          toggle
          defaultChecked={data.shop.shopPreferences?.autoEstimation.enabled}
          onChange={(_event, { checked }) => updateShopPrefs({ enabled: !!checked })}
        />
        &nbsp;
        {t("shop.preferences.autoEstimations.enabled")}
      </StyledToggleLabel>
      <StyledToggleLabel style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Checkbox
          data-testid="autoEstimationAutoOrient"
          toggle
          defaultChecked={data.shop.shopPreferences.autoEstimation.autoOrient}
          onChange={(_event, { checked }) => updateShopPrefs({ autoOrient: !!checked })}
        />
        &nbsp;
        {t("shop.preferences.autoEstimations.autoOrient.label")}
      </StyledToggleLabel>
      <StyledToggleLabel>
        <Checkbox
          data-testid="autoEstimationGenerateProjectFiles"
          toggle
          defaultChecked={data.shop.shopPreferences.autoEstimation.generateProjectFiles}
          onChange={(_event, { checked }) => updateShopPrefs({ generateProjectFiles: !!checked })}
        />
        &nbsp;
        {t("shop.preferences.autoEstimations.generateProjectFiles.label")}
      </StyledToggleLabel>
      <StyledToggleLabel>
        <Checkbox
          data-testid="autoEstimationGenerateBuildFiles"
          toggle
          defaultChecked={data.shop.shopPreferences.autoEstimation.generateBuildFiles}
          onChange={(_event, { checked }) => updateShopPrefs({ generateBuildFiles: !!checked })}
        />
        &nbsp;
        {t("shop.preferences.autoEstimations.generateBuildFiles.label")}
      </StyledToggleLabel>
    </div>
  );
};
