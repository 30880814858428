import styled, { Popup } from "grabcad-ui-elements";
import React, { useRef, useState } from "react";

const StyledContextMenu = styled.div`
  .icon.ellipsis {
    position: absolute;
    top: -1px;
    right: -9px;
    font-size: 0.8em;
    padding: 5px 20px 25px 10px;
    margin-right: -5px;
    &:before {
      color: #c1c1c1;
    }
    &:hover:before {
      color: #555;
    }
  }
`;

export const PopupMenu = styled(Popup)`
  &.ui.popup {
    padding: 0;
    z-index: 900;
    max-width: 147px;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
    &:before {
      z-index: 0;
    }
    &.ui.menu .item:before {
      background: none;
    }
  }
`;

interface IContextMenuProps {
  menuItems: JSX.Element[];
  position?: string;
}

export const ContextMenu = ({
  menuItems,
  position = "top right",
}: IContextMenuProps): JSX.Element => {
  const [popupMenu, setPopupMenu] = useState(false);
  const popupPosRef = useRef(null);

  return (
    <StyledContextMenu>
      <i
        className="qa-shopCard-ellipsis ellipsis vertical icon"
        onClick={e => {
          setPopupMenu(true);
          e.preventDefault();
          e.stopPropagation();
        }}
        data-testid="shopCardEllipsis"
        ref={popupPosRef}
      />
      <PopupMenu
        on="click"
        open={popupMenu}
        onClose={() => setPopupMenu(false)}
        className="ui vertical menu small"
        position={position}
        context={popupPosRef}
        content={menuItems.map((menuItem: JSX.Element, i) => (
          <React.Fragment key={i}>{menuItem}</React.Fragment>
        ))}
        data-testid="popupMenu"
      />
    </StyledContextMenu>
  );
};
