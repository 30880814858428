import React, { createContext, Component } from "react";
import { IShop, IShopPreferences } from "@/graphql/Fragments/Shop";
import { InjectedIntlProps, injectIntl, MessageValue } from "react-intl";
import { AbilityContext } from "./UiCan";
import { IUser } from "@/graphql/Fragments/User";
import { isArray, mergeWith, cloneDeep } from "lodash";
import { GcAbility } from "@/GcAbility";
import { ErrorBoundary } from "@/ErrorBoundary";
import { LicenseErrorType } from "./UserLoader";
import { IOrderRow, SortDirection } from "../components/Order/List/List";
import { getActiveStatusIds } from "./Order/List/FiltersPopup";
import { DNT_LIST } from "@/i18n/i18n";
import { config, PrinterDataSource } from "../config";

export interface IUserContext {
  isLoggedIn: boolean;
  setAsLoggedIn: (userProfile: IUser) => void;
  setAsLoggedOut: () => void;
  userProfile: IUser | null;
}

export type TranslateFunction = (key: string, values?: { [key: string]: MessageValue }) => string;
export type FeatureResolver = (featureName: string) => boolean;

export type OrderListState = {
  filters: { shopStatuses: number[]; technology: number | null };
  sort: { sortCol: keyof IOrderRow; sortDir: SortDirection };
  scrollPosition: number;
  searchTerms?: string | null;
};

const DEFAULT_ORDER_LIST_STATE: OrderListState = {
  filters: { shopStatuses: [], technology: null },
  sort: { sortCol: "id", sortDir: "descending" },
  scrollPosition: 0,
};

export interface IApplicationContext {
  currentUser: IUserContext;
  ability: GcAbility;
  currentShop: IShop | null;
  setCurrentShop: (shop: IShop | null) => void;
  /** translate */
  t: TranslateFunction;
  /** returns true if a translation exists for a given message (in the current locale) */
  messageExists: (_key: string) => boolean;
  formatDate: (date: string | undefined, formatDateProps?: IFormatDateProps) => string;
  formatTime: (date: string | undefined, formatDateProps?: IFormatDateProps) => string;
  formatPrice: (price: number | undefined) => string | null;
  setLicenseError: ({ code, role }: LicenseErrorType) => void;
  licenseError: LicenseErrorType | null;
  orderListStateByShop: Map<number, OrderListState>;
  hasFeature: (featureKey: string, machineEntitlement?: string) => boolean;
  allEnabledFeatureKeys: () => Set<string>;
  getOrderListStateByShop: () => OrderListState;
  setShopPreferences: (prefs: IShopPreferences) => void;
  printerDataSource: PrinterDataSource;
}

interface IApplicationState {
  context: IApplicationContext;
}

// Note: Need to specify default context only,
// when testing do not mock with <ApplicationContext.Provider>,
// pass a mockedApplicationContext object as second parameter to createMount
const DEFAULT_CONTEXT: IApplicationContext = {
  currentUser: {
    isLoggedIn: false,
    setAsLoggedIn: (userProfile: IUser) => {},
    setAsLoggedOut: () => {},
    userProfile: null,
  },
  ability: createAbility(),
  currentShop: null,
  setCurrentShop: (shop: IShop | null) => {},
  t: (_key, _values) => "",
  messageExists: _key => true,
  formatDate: (date: string | undefined, formatDateProps?: IFormatDateProps) => "",
  formatTime: (date: string | undefined, formatDateProps?: IFormatDateProps) => "",
  formatPrice: (price: number | undefined) => "",
  setLicenseError: ({ code, role }: LicenseErrorType) => {},
  licenseError: null,
  orderListStateByShop: new Map<number, OrderListState>(),
  hasFeature: () => false,
  allEnabledFeatureKeys: () => new Set(),
  getOrderListStateByShop: () => DEFAULT_ORDER_LIST_STATE,
  setShopPreferences: (prefs: IShopPreferences) => {},
  printerDataSource: PrinterDataSource.CloudServer,
};

export const ApplicationContext = createContext(DEFAULT_CONTEXT);
ApplicationContext.displayName = "ApplicationContext";

function createAbility(): GcAbility {
  return new GcAbility();
}

export interface IFormatDateProps {
  ignoreTimezone?: boolean;
}
export class ApplicationProviderBase extends Component<InjectedIntlProps, IApplicationState> {
  constructor(props: InjectedIntlProps) {
    super(props);
    this.state = {
      context: {
        currentUser: {
          isLoggedIn: false,
          setAsLoggedIn: (userProfile: IUser) => {
            this.mergeState({
              context: { currentUser: { userProfile, isLoggedIn: true }, ability: createAbility() },
            });
          },
          setAsLoggedOut: () => {
            this.setAsLoggedOut();
          },
          userProfile: null,
        },
        ability: createAbility(),
        currentShop: null,
        // NB: .bind(this) unnecessary for class property: class X { f = () => {}; }
        // https://babeljs.io/docs/en/babel-plugin-proposal-class-properties
        setCurrentShop: this.setCurrentShop,
        t: (id, values) => props.intl.formatMessage({ id }, Object.assign({}, DNT_LIST, values)),
        messageExists: id => !!props.intl.messages[id],
        formatDate: (date: string | undefined, formatDateProps?: IFormatDateProps) =>
          this.formatDate(date, formatDateProps),
        formatTime: (date: string | undefined, formatDateProps?: IFormatDateProps) =>
          this.formatTime(date, formatDateProps),
        formatPrice: (price: number | undefined) => this.formatPrice(price),
        setLicenseError: this.setLicenseErrorMessage,
        licenseError: null,
        orderListStateByShop: new Map<number, OrderListState>(),
        hasFeature: (featureKey: string, machine?: string) => {
          const userProfile = this.state.context.currentUser.userProfile;
          const enabledFeatures = userProfile?.features || [];
          return !!enabledFeatures.some(
            featureAndEntitlement =>
              featureKey === featureAndEntitlement.featureKey &&
              (!machine ||
                featureAndEntitlement.machineEntitlements?.some(
                  enabledEntitlement =>
                    !enabledEntitlement.model || enabledEntitlement.model === machine
                ))
          );
        },
        allEnabledFeatureKeys: () =>
          new Set(
            this.state.context.currentUser.userProfile?.features?.map(
              featureAndEntitlement => featureAndEntitlement.featureKey
            )
          ),
        getOrderListStateByShop: this.getOrderListStateByShop,
        setShopPreferences: this.setShopPreferences,
        printerDataSource: config.REACT_APP_PRINTER_DATA_SOURCE || PrinterDataSource.CloudServer,
      },
    };
  }

  private getOrderListStateByShop = (): OrderListState => {
    const shopState = this.state.context.orderListStateByShop;
    const defaultOrderListByShopState: OrderListState = cloneDeep(DEFAULT_ORDER_LIST_STATE);
    const shop = this.state.context.currentShop;
    if (!shop?.id) {
      return defaultOrderListByShopState;
    }
    if (!shopState.get(shop.id)) {
      defaultOrderListByShopState.filters.shopStatuses = getActiveStatusIds(shop.shopStatuses);
      shopState.set(shop.id, defaultOrderListByShopState);
    }
    return shopState.get(shop.id) as OrderListState;
  };

  /**
   * Always call this method instead of this.setState()!
   *
   * React Context Provider does shallow reference checks to determine if Consumers should re-render.
   * The best way to do this is to treat state as immutable and call setState() on copies of it.
   */
  private mergeState(newState: RecursivePartial<IApplicationState>): void {
    this.setState(prevState => {
      const newState2 = mergeWith({}, prevState, newState, (dst, src) => {
        if (isArray(dst)) {
          // Normally merge([1, 2, 3], [4, 5]) => [4, 5, 3]
          // We want instead to make it [4, 5] instead
          return src;
        }
        // else do default behavior (i.e., recursive merge objects)
      });
      return newState2;
    });
  }

  private setCurrentShop = (shop: IShop | null) => {
    const currentShop = this.state.context.currentShop;
    // Prevent spurious triggering of Consumers
    if (currentShop !== shop) {
      this.mergeState({
        context: {
          currentShop: shop,
        },
      });
    }
  };

  private setLicenseErrorMessage = (licenseError: LicenseErrorType) => {
    const curMsg = this.state.context.licenseError;
    if (!curMsg || curMsg.code !== licenseError.code) {
      this.mergeState({
        context: {
          licenseError,
        },
      });
    }
  };

  private formatDate = (
    date: string | undefined,
    { ignoreTimezone }: IFormatDateProps = { ignoreTimezone: false }
  ) => {
    const { intl } = this.props;
    if (!date) {
      return "";
    }
    const options = ignoreTimezone ? { timeZone: "UTC" } : {};
    return intl.formatDate(new Date(date).toUTCString(), options);
  };

  private formatTime = (
    date: string | undefined,
    { ignoreTimezone }: IFormatDateProps = { ignoreTimezone: false }
  ) => {
    const { intl } = this.props;
    if (!date) {
      return "";
    }
    const options = ignoreTimezone ? { timeZone: "UTC" } : {};
    return intl.formatTime(new Date(date).toUTCString(), options);
  };

  // NB: This takes cents! (or Yen)
  private formatPrice = (price: number | undefined) => {
    const currentShop = this.state.context.currentShop;
    if (currentShop && price != null) {
      return this.props.intl.formatNumber(price / currentShop.currencyDenominator, {
        style: "currency",
        currency: currentShop.currencyCode,
      });
    }
    return null;
  };

  private setAsLoggedOut = () => {
    this.mergeState({
      context: {
        currentUser: {
          isLoggedIn: false,
          userProfile: null,
        },
        ability: createAbility(),
      },
    });
  };

  private setShopPreferences = (prefs: IShopPreferences) => {
    if (!this.state.context.currentShop) {
      return;
    }

    this.mergeState({
      context: {
        currentShop: {
          ...this.state.context.currentShop,
          shopPreferences: prefs,
        },
      },
    });
  };

  public render(): JSX.Element {
    const { children } = this.props;
    const { context } = this.state;
    // Note: Must keep AbilityContext separate from ApplicationContext because
    // createContextualCan() assumes context only has one value, the Ability.
    return (
      <ErrorBoundary>
        <ApplicationContext.Provider value={context}>
          <AbilityContext.Provider value={context.ability}>{children}</AbilityContext.Provider>
        </ApplicationContext.Provider>
      </ErrorBoundary>
    );
  }
}

export const ApplicationProvider = injectIntl(ApplicationProviderBase);
