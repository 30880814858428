import defaultMachineLogo from "@/assets/default-image-500px-sq.png";
import styled, { Image, Label, Loader } from "grabcad-ui-elements";
import React, { useContext } from "react";
import { ShopMachineState } from "@/graphql/Fragments/ShopMachine";
import { MachinesDefaultImages, DefaultGenericMachineImage } from "./DefaultImages";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ShopImage } from "../../Shared/ShopImage";
import { IImage } from "@/graphql/Fragments/Image";

interface IAppMachineType {
  name: string;
}

interface IShopMachine {
  image?: IImage | null;
  appMachineType?: IAppMachineType;
  state?: ShopMachineState;
}

interface IMachineImageProps {
  machine: IShopMachine;
  loading?: boolean;
  className?: string | undefined;
}

const ImageContainer = styled.div`
  img {
    margin: auto;
  }
`;

const NoImage = styled(Image)`
  height: 200px;
`;

const LoadingSpinnerContainer = styled.div`
  height: 200px;
`;

const getMachineImage = ({ image, appMachineType }: IShopMachine) => {
  if (image) {
    return image;
  }
  const defaultImageKey = appMachineType?.name.replace(/ /g, "_");
  return (defaultImageKey && MachinesDefaultImages[defaultImageKey]) || DefaultGenericMachineImage;
};

export const MachineImage = ({ machine, loading, className }: IMachineImageProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const machineImage = getMachineImage(machine) as IImage;
  const defaultImage = {
    small: { location: defaultMachineLogo },
    medium: { location: defaultMachineLogo },
    original: { location: defaultMachineLogo },
    __typename: "Image",
  } as Partial<IImage>;
  let imgLabel;
  switch (machine.state) {
    case ShopMachineState.DRAFT:
      imgLabel = (
        <Label ribbon="right" color="orange">
          {t("shop.machine.draft.label")}
        </Label>
      );
      break;
    case ShopMachineState.SAMPLE:
      imgLabel = (
        <Label ribbon="right" color="orange">
          {t("shop.machine.sample.label")}
        </Label>
      );
      break;
    default:
      break;
  }

  return (
    <ImageContainer>
      {loading ? (
        <LoadingSpinnerContainer className="loading">
          <Loader active={loading} size="large" />
        </LoadingSpinnerContainer>
      ) : machineImage ? (
        <ShopImage
          style={{ height: "200px" }}
          image={machineImage}
          defaultImage={defaultImage}
          size="medium"
          className={className}
          label={imgLabel}
        />
      ) : (
        <NoImage className={className} label={imgLabel} />
      )}
    </ImageContainer>
  );
};
MachineImage.displayName = "MachineImage";
