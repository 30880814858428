import React, { SyntheticEvent, useContext } from "react";
import styled, {
  Icon,
  Image,
  Loader,
  Popup,
  Radio,
  Segment,
  SegmentGroup,
} from "grabcad-ui-elements";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import {
  useCacheableMachineRate,
  useCacheableMaterialRate,
  useShopTechnologies,
} from "@/utils/queryHooks";
import {
  FRACTIONAL_UNITS,
  previewOrderItemPrice,
  transformMinutesToDdHhMm,
} from "../ItemsList/shopRatesUtils";
import {
  MATERIAL_UNITS,
  TIME_UNITS,
  MATERIAL_QUANTITY,
  ROUTES,
  TECHNOLOGY,
  getMachineTechnologyDefaultMaterialUnit,
  getMachineTechnologyDefaultSupportUnit,
} from "@/shopConstants";
import { getMaterialUnitLabel, getTranslatedUnits } from "@/utils/DropdownUtils";
import { Permission } from "@/utils/Permission";
import { AssetType, AutoEstimation } from "@/graphql/Fragments/AutoEstimation";
import { Link } from "react-router-dom";
import {
  autoEstimateToCustomUnits,
  getMostUsedMaterialName,
  stringifyGCPVersion,
} from "../../Shared/AutoEstimations";
import { StyledProgress } from "../../Shared/StyledProgress";
import { ApolloConsumer } from "@apollo/client";
import {
  type AssetType as DownloadableAssetType,
  getUrlAndDownload,
} from "../../Shared/DownloadButton";
import ReactGA from "react-ga";
import { DownloadIconButton } from "../../Shared/OrderItemDependenciesPanel";
import Download from "@/assets/icons/download.svg";
import { getTechnologyName, isGcpAsset } from "../../../utils/GeneralUtils";
import { Snapshot } from "../../Shared/Snapshot";
import { ExpandAutoEstimationParametersButton } from "./ExpandAutoEstimationParametersButton";
import { IShopPreferences } from "@/graphql/Fragments/Shop";

export interface IAutoEstimationsProps {
  orderItem: IOrderItem;
  onSelect: (autoEstimation: AutoEstimation) => void;
  autoEstimations?: AutoEstimation[];
  onPreviewClick: (autoEstimationId: number) => void;
}

const StyledAutoEstimations = styled.div`
  margin: 10px 10px;
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr 4fr 2fr 2fr;
  .grid-item {
    padding: 30px 10px;
    line-height: 25px;
    border-bottom: 1px solid #d6d6d6;
    span {
      font-weight: 700;
    }
    p.loading {
      color: gray;
    }
    .ui.loader {
      position: relative;
      top: 0px;
      left: 10px;
      z-index: 0; // Puts spinner under tooltips
    }
    [data-tooltip]::after {
      white-space: pre-wrap;
      min-width: 200px;
    }
  }
  .grid-item:last-child,
  .grid-item:nth-last-child(-n + 2),
  .grid-item:nth-last-child(-n + 3),
  .grid-item:nth-last-child(-n + 4),
  .grid-item:nth-last-child(-n + 5) {
    border-bottom: none;
  }
  .centered {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .queue-position {
    font-style: italic;
    opacity: 0.8;
  }
  .preview {
    cursor: pointer;
    color: #003393;
    padding: 5px;
  }
`;

const DownloadButton = styled.div`
  color: #003393;
  cursor: pointer;
  display: flex;
`;

const AutoEstimationProgress = styled(StyledProgress)`
  grid-column: 1 / -1;
  &.ui.progress {
    top: -2px;
    width: 100%;
    position: relative;
    margin: 0px;
    border-radius: 2px;
  }
`;

interface IDownloadableAutoEstimationAsset {
  id: number;
  name: string;
  assetType: DownloadableAssetType;
}

export interface IDownloadAutoEstimationAssetProps {
  assets: IDownloadableAutoEstimationAsset[];
  buttonText?: string;
}

function getAssetExtension(fileName: string): string {
  if (fileName.endsWith(".cmb.gz")) {
    return ".cmb.gz";
  } else if (fileName.endsWith(".objzf.gz")) {
    return ".objzf.gz";
  }

  return fileName.substring(fileName.lastIndexOf("."));
}

function getExpectedOutputExtensions(prefs?: IShopPreferences, technology?: TECHNOLOGY): string[] {
  if (!prefs || !technology) {
    return [];
  }

  const exts = [];

  if (prefs.autoEstimation.generateProjectFiles) {
    exts.push(".print");
  }

  if (prefs.autoEstimation.generateBuildFiles) {
    if (technology === TECHNOLOGY.FDM) {
      exts.push(".cmb");
    } else if (technology === TECHNOLOGY.POLYJET) {
      exts.push(".objzf");
    }
  }

  return exts;
}

export const DownloadAutoEstimationAssetButton = (props: IDownloadAutoEstimationAssetProps) => {
  const { currentShop, t } = useContext(ApplicationContext);
  // Join with a non-breaking space because a break looks strange between extensions
  const extensions = props.assets.map(asset => getAssetExtension(asset.name)).join(",\u00a0");

  if (!props.assets.length) {
    return <></>;
  }

  const tooltipTranslationKey =
    props.assets.length === 1
      ? "auto_estimation.downloads.buttonTooltipOne"
      : "auto_estimation.downloads.buttonTooltipMany";

  return (
    <ApolloConsumer>
      {client => (
        <Popup
          trigger={
            <DownloadButton
              className="download-button"
              onClick={(event: SyntheticEvent) => {
                for (const asset of props.assets) {
                  getUrlAndDownload(client, asset);
                }
                ReactGA.event({
                  category: "GcShop File Dependency Download",
                  action: "Auto estimation asset downloaded",
                  label: `Shop ${currentShop?.id}`,
                });
                event.stopPropagation();
              }}
            >
              {props.buttonText}
              <DownloadIconButton
                className={"qa-download-autoEstimationAsset"}
                style={{ marginRight: "15px" }}
                data-testid="downloadAutoEstimationAssetButton"
              >
                <Image src={Download} />
              </DownloadIconButton>
            </DownloadButton>
          }
          content={t(tooltipTranslationKey, { extensions })}
          offset={[0, 5]}
          position={"bottom right"}
          inverted
        />
      )}
    </ApolloConsumer>
  );
};

export const AutoEstimations = ({
  orderItem,
  onSelect,
  autoEstimations, // Can be passed separately from the orderItem to enable progress bars in stateful forms
  onPreviewClick,
}: IAutoEstimationsProps): JSX.Element | null => {
  if (!orderItem.autoEstimations?.length) {
    return null;
  }

  return (
    <StyledAutoEstimations className="qa-autoEstimations" data-testid="autoEstimations">
      {(autoEstimations || orderItem.autoEstimations).map(autoEstimation => (
        <AutoEstimationRow
          key={autoEstimation.id}
          orderItem={orderItem}
          autoEstimation={autoEstimation}
          selected={orderItem.autoEstimationId === autoEstimation.id}
          onSelect={onSelect}
          onPreviewClick={() => onPreviewClick(autoEstimation.id)}
        />
      ))}
    </StyledAutoEstimations>
  );
};
AutoEstimations.displayName = "AutoEstimations";

export const AutoEstimationRow = ({
  orderItem,
  autoEstimation,
  selected,
  onSelect,
  onPreviewClick,
}: {
  orderItem: IOrderItem;
  autoEstimation: AutoEstimation;
  selected: boolean;
  onSelect: IAutoEstimationsProps["onSelect"];
  onPreviewClick: (autoEstimationId: number) => void;
}): JSX.Element | null => {
  const { currentShop, t, messageExists, formatDate, formatPrice, ability } =
    useContext(ApplicationContext);
  const { shopTechnologies, allMaterials, allMachines } = useShopTechnologies();
  // Note that the following variables refer to the associated properties of the _estimation_
  // and not necessarily to the corresponding values of the order item, as the order item
  // may have been changed since the auto estimation was performed
  const estimateMaterial = allMaterials.find(mat => mat.id === autoEstimation.shopMaterialId);
  const estimateMachine = allMachines.find(m => m.id === estimateMaterial?.shopMachineId);
  const { machineRate } = useCacheableMachineRate(estimateMachine?.newestMachineRateId);

  // get the latest order rates - if they are just updated, we want to use latest for price
  const { machineRate: orderItemMachineRate } = useCacheableMachineRate(orderItem.machineRateId);
  const { materialRate: orderItemMaterialRate } = useCacheableMaterialRate(
    orderItem.materialRateId
  );
  let { materialRate: priceMaterialRate } = useCacheableMaterialRate(
    estimateMaterial?.newestMaterialRateId
  );

  const estimateTechnology = shopTechnologies.find(
    tech => tech.id === estimateMachine?.shopTechnologyId
  );
  const resultData = autoEstimation.resultData;
  const { currentUser } = useContext(ApplicationContext);
  const isAdmin = currentUser?.userProfile
    ? ability.can(Permission.CREATE_SHOP, currentUser.userProfile)
    : false;

  let errorMessage = null;
  const error = resultData?.error || resultData?.estimates.find(e => e.error)?.error;
  if (error) {
    const messageKey = `auto_estimation.error.${error.name}`;
    errorMessage = messageExists(messageKey)
      ? t(messageKey)
      : t("auto_estimation.error.unexpected");
  }

  const firstTray = resultData?.estimates?.[0];
  const firstTrayMaterials = firstTray?.materials;
  const estimatedSeconds = firstTray?.duration;
  const estimatedMinutes = estimatedSeconds ? estimatedSeconds / 60 : NaN;
  const estimateParameters = firstTray?.parameters;
  const hasParameters =
    !!estimateParameters || (firstTray?.materials?.filter(m => !m?.isSupport)?.length || 0) > 1;

  // There are now three downloadable assets, snapshots, projectFiles, and buildFiles.
  // projectFiles and buildFiles are the only two with assetType === AUTO_ESTIMATION
  // It's possible we may add more assets in the future and using the role field on the assets allows us to filter from it
  const estimationOutputs = autoEstimation.autoEstimationAssets?.length
    ? autoEstimation.autoEstimationAssets.filter(
        aeAsset => aeAsset.asset.assetType === AssetType.AUTO_ESTIMATION
      )
    : undefined;
  const snapshotAsset = autoEstimation.autoEstimationAssets?.find(
    aeAsset => aeAsset.asset.assetType === AssetType.AUTO_ESTIMATION_SNAPSHOT
  );

  let technologyName: TECHNOLOGY | undefined;
  let machineDisplayName: string | undefined;
  let materialDisplayName: string | undefined;
  if (isGcpAsset(orderItem.cadModel) && resultData) {
    technologyName = getTechnologyName(resultData);
    machineDisplayName = resultData.printerModel;
    materialDisplayName = getMostUsedMaterialName(resultData);
  } else {
    technologyName = estimateTechnology?.appTechnology.name;
    machineDisplayName = estimateMachine?.appMachineType.name;
    materialDisplayName = estimateMaterial?.appMaterial.name;
  }

  const materialUnit =
    machineRate?.materialUnit ??
    getMachineTechnologyDefaultMaterialUnit(technologyName, machineDisplayName);
  const supportUnit =
    machineRate?.supportUnit ??
    machineRate?.materialUnit ??
    getMachineTechnologyDefaultSupportUnit(technologyName, machineDisplayName);

  const {
    estimatedMaterial: materialInDisplayUnits,
    estimatedSupport: supportInDisplayUnits,
    estimateMaterialQuantity = MATERIAL_QUANTITY.VOLUME,
    supportMaterialQuantity = MATERIAL_QUANTITY.VOLUME,
    materialUnitsAreValid,
    supportUnitsAreValid,
  } = (firstTray &&
    technologyName &&
    autoEstimateToCustomUnits({
      estimate: firstTray,
      technologyName,
      machineDisplayName, // do not care about MakerBot special units (yet?)
      materialUnit,
      supportUnit,
      // We don't really care about the time estimate here
      timeUnit: TIME_UNITS.HOURS,
    })) ??
  {};

  const badUnitsWarning = (content: string, dataTestId: string) => {
    const machineEditLink = estimateTechnology?.id
      ? ROUTES.SHOP(currentShop?.id).MACHINES.MACHINE(estimateTechnology.id, estimateMachine?.id)
      : null;
    const icon = (
      <span data-tooltip={content} data-position="left center" data-testid={dataTestId}>
        <Icon name="warning sign" size="small" color="red" className="qa-no-rates-warning" />
      </span>
    );
    return machineEditLink && isAdmin ? <Link to={machineEditLink}>{icon}</Link> : icon;
  };
  let price: number | undefined;
  let priceMachineRate = machineRate;
  if (materialUnitsAreValid && supportUnitsAreValid) {
    if (selected) {
      // if relevant, and if order item is currently using different rate (including nothing?), use it
      priceMaterialRate = orderItemMaterialRate;
      priceMachineRate = orderItemMachineRate;
    }
    const {
      machineTimeUnit: priceMachineTimeUnit = TIME_UNITS.HOURS,
      materialUnit: priceMaterialUnit = MATERIAL_UNITS.IN3, // TODO GC-84107: Sane default units per tech?
      machineTimeRate: priceMachineTimeRate = 0,
      machineBaseRate: priceMachineBaseRate = 0,
    } = priceMachineRate || {};
    const priceSupportUnit = priceMachineRate?.supportUnit || priceMaterialUnit;
    const { estimatedMaterial, estimatedSupport, estimatedTime } = autoEstimateToCustomUnits({
      estimate: firstTray!,
      technologyName: technologyName!,
      machineDisplayName: machineDisplayName!,
      materialUnit,
      supportUnit: priceSupportUnit,
      timeUnit: priceMachineTimeUnit,
      // round to 2 places here (3 on display?) to match the price in the Modal popup
      materialDecimalPlaces: 2,
    });
    const { materialRate = 0, supportRate = 0 } = priceMaterialRate || {};
    price = previewOrderItemPrice({
      estimatedMaterial,
      estimatedSupport,
      estimatedTime,
      markUp: (orderItem.markupPrice || 0) / FRACTIONAL_UNITS,
      extraCost: orderItem.extraCost || 0,
      materialRate: materialRate || undefined,
      supportRate: supportRate || undefined,
      machineTimeRate: priceMachineTimeRate || undefined,
      machineBaseRate: priceMachineBaseRate || undefined,
    });

    if (isNaN(price)) {
      price = undefined;
    }
  }
  const modelUnits = autoEstimation.modelUnits;
  const readOnly = !!orderItem.jobId || !ability.can(Permission.WRITE, orderItem, "shopMaterialId");
  const disabled = readOnly || !autoEstimation.resultData?.estimates?.length;
  const showLoaders = !autoEstimation.queuePosition && !autoEstimation.resultData && !errorMessage;
  const gcpVersion = autoEstimation.resultData?.gcpVersion
    ? stringifyGCPVersion(autoEstimation.resultData.gcpVersion)
    : null;
  const expectedExtensions = getExpectedOutputExtensions(
    currentShop?.shopPreferences,
    technologyName
  );
  // Join with a non-breaking space because a break looks strange between extensions
  const expectedExtensionsStr = expectedExtensions.join(",\u00a0");

  return (
    <>
      <div
        className="grid-item centered"
        data-testid="estimateSelection"
        style={{ justifyContent: "center" }}
      >
        <Radio
          checked={selected}
          onClick={() => {
            !disabled && onSelect(autoEstimation);
          }}
          disabled={disabled}
          data-tooltip={
            selected && !autoEstimation.resultData
              ? t("auto_estimation.selectedInProgress")
              : !selected && !!orderItem.jobId
              ? t("order.items.job.tooltip.material")
              : null
          }
          data-position="right center"
        />
      </div>
      <div className="grid-item">
        {!errorMessage ? (
          <div style={{ width: "130px" }} data-testid="snapshotPreview">
            <SegmentGroup>
              <Segment compact>
                <Snapshot asset={snapshotAsset}></Snapshot>
              </Segment>
              <Segment className="preview" textAlign="center" compact onClick={onPreviewClick}>
                {t("auto_estimation.preview")}
              </Segment>
            </SegmentGroup>
          </div>
        ) : (
          <div
            className="centered"
            data-testid="estimateError"
            style={{ justifyContent: "center" }}
          >
            <Popup
              trigger={
                <span>
                  <Icon
                    className="qa-estimation-error-warning"
                    data-testid="estimationErrorWarningIcon"
                    name="warning sign"
                    color="red"
                  />
                </span>
              }
              content={t("auto_estimation.error.snapshot")}
              position={"right center"}
              inverted
            />
          </div>
        )}
      </div>
      <div className="grid-item" data-testid="estimateDetails">
        <div>
          <span>{t("auto_estimation.printer")}</span> {machineDisplayName}
        </div>
        <div>
          <span>{t("auto_estimation.date")}</span>{" "}
          {formatDate(autoEstimation.dateUpdated.toString())}
        </div>
        {gcpVersion && (
          <div data-testid="gcpVersion">
            <span>{t("auto_estimation.gcpVersion")}</span>
            {` ${gcpVersion}`}
          </div>
        )}
        <div>
          <span>{t("auto_estimation.material")}</span> {materialDisplayName}
        </div>
        {autoEstimation.orientation && (
          <div>
            <span>{t("auto_estimation.orientation")}</span>
            {t("auto_estimation.orientation.option." + autoEstimation.orientation.toLowerCase()) ??
              autoEstimation.orientation.toString()}
          </div>
        )}
        {modelUnits && (
          <div>
            <span>{t("auto_estimation.units")}</span> {getTranslatedUnits(t, modelUnits)}
          </div>
        )}
        {hasParameters && (
          <ExpandAutoEstimationParametersButton
            parameters={estimateParameters!}
            materialDisplayUnit={materialUnit}
            estimateMaterialQuantity={estimateMaterialQuantity}
            supportMaterialQuantity={supportMaterialQuantity}
            materials={firstTray?.materials}
          ></ExpandAutoEstimationParametersButton>
        )}
      </div>
      {showLoaders && (
        <div className="grid-item estimate-result" data-testid="estimateInProgress">
          <p className="loading">{t("auto_estimation.detailsLoading")}</p>
          <Loader active={true} size="tiny" />
        </div>
      )}
      {errorMessage && (
        <div className="grid-item esimate-result centered" data-testid="estimateError">
          <Popup
            trigger={
              <span>
                <Icon
                  className="qa-estimation-error-warning"
                  data-testid="estimationErrorWarningIcon"
                  name="warning sign"
                  color="red"
                />
              </span>
            }
            content={errorMessage}
            position={"right center"}
            inverted
          />
        </div>
      )}
      {autoEstimation.queuePosition ? (
        <div className="grid-item estimate-result centered" data-testid="estimateInQueue">
          <span className="queue-position qa-queuePosition" data-testid="queuePosition">
            {t("auto_estimation.queue_position", {
              position: autoEstimation.queuePosition,
            })}
          </span>
        </div>
      ) : null}
      {autoEstimation.resultData && !errorMessage && (
        <div className="grid-item estimate-result" data-testid="estimateResult">
          {firstTrayMaterials && firstTrayMaterials.length > 0 && (
            <>
              {materialInDisplayUnits !== undefined && (
                <div data-testid="estimateMaterial">
                  <span>{t("auto_estimation.model")}</span>
                  {materialUnitsAreValid ? (
                    <>
                      {materialInDisplayUnits}
                      {getMaterialUnitLabel(t, materialUnit)}
                    </>
                  ) : (
                    badUnitsWarning(
                      t(
                        isAdmin
                          ? "auto_estimation.materialUnitsInvalidAdmin"
                          : "auto_estimation.materialUnitsInvalidNonAdmin"
                      ),
                      "materialUnitsInvalidWarning"
                    )
                  )}
                </div>
              )}
            </>
          )}
          {!!supportInDisplayUnits && (
            <div data-testid="estimateSupport">
              <span>{t("auto_estimation.support")}</span>
              {supportUnitsAreValid ? (
                <>
                  {supportInDisplayUnits}
                  {getMaterialUnitLabel(t, supportUnit)}
                </>
              ) : (
                badUnitsWarning(
                  t(
                    isAdmin
                      ? "auto_estimation.supportUnitsInvalidAdmin"
                      : "auto_estimation.supportUnitsInvalidNonAdmin"
                  ),
                  "supportUnitsInvalidWarning"
                )
              )}
            </div>
          )}
          {!isNaN(estimatedMinutes) && (
            <div data-testid="estimateTime">
              <span>{t("auto_estimation.time")}</span>
              {transformMinutesToDdHhMm(estimatedMinutes, t)}
            </div>
          )}
          {price !== undefined && (
            <div data-testid="autoEstimationPrice">
              <span>{t("auto_estimation.cost")}</span>
              {formatPrice(price)}
            </div>
          )}
        </div>
      )}
      {showLoaders && !!expectedExtensions?.length && (
        <div className="grid-item download" data-testid="downloadAutoEstimationLoading">
          <p className="loading">
            {expectedExtensions.length === 1
              ? t("auto_estimation.outputLoadingOne", { extensions: expectedExtensionsStr })
              : t("auto_estimation.outputLoadingMany", { extensions: expectedExtensionsStr })}
          </p>
          <Loader active={true} size="tiny" />
        </div>
      )}
      {!showLoaders && (
        <div className="grid-item download centered" data-testid="downloadAutoEstimation">
          {estimationOutputs && !!estimationOutputs?.length && (
            <DownloadAutoEstimationAssetButton
              assets={estimationOutputs.map(asset => ({
                id: asset.id,
                name: asset.asset.originalName,
                assetType: "auto_estimation",
              }))}
            ></DownloadAutoEstimationAssetButton>
          )}
        </div>
      )}
      {!autoEstimation.resultData && (
        <AutoEstimationProgress
          percent={(autoEstimation.progress || 0) * 100}
          size="tiny"
          color={"blue"}
          data-testid="styledProgress"
        />
      )}
    </>
  );
};
