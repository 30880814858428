import gql from "graphql-tag";
import { TECHNOLOGY } from "../../shopConstants";

export const APP_MACHINE_TYPES_LIST = gql`
  query appMachineTypesList {
    appMachineTypes {
      id
      name
      displayName
      appTechnology {
        id
        name
        displayName
      }
    }
  }
`;

export interface AppMachineTypesList {
  appMachineTypes: {
    id: number;
    name: string;
    displayName: string;
    appTechnology: {
      id: number;
      name: TECHNOLOGY;
      displayName: string;
      __typename?: "AppTechnology";
    };
    __typename?: "AppMachineType";
  }[];
}
