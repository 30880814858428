import React, { FunctionComponent, useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { NavLinkProps, Route, Switch } from "react-router-dom";
import { Shell } from "@/screens/Screen";
import { ScreensShopInfoPage } from "@/screens/Shop/Info/Page";
import { ScreensShopList } from "@/screens/Shop/List/List";
import { ScreensShopMachinesNew } from "@/screens/Shop/Machine/New";
import { ScreensShopNew } from "@/screens/Shop/New/New";
import { OrderNew } from "@/screens/Shop/Order";
import { ScreensShopOrderList } from "@/screens/Shop/Order/List/List";
import { ScreensOrderPage } from "@/screens/Shop/Order/Show/OrderPage";
import { ScreensShopMachineList } from "@/screens/Shop/Machine/List";
import { ScreensUserLoginForm } from "@/screens/User/Login/Form/Form";
import { ROUTES, SHOP } from "@/shopConstants";
import { PrivateRoute } from "./PrivateRoute";
import { COMPANY_SERVER_AUTH_ENABLED } from "@/GraphQLClient";
import { MachineDetails } from "@/screens/Shop/Machine/MachineDetails";
import { ScreensShopMachinePage } from "@/screens/Shop/Machine/Page";
import { BREADCRUMBS } from "@/components/Layout/BreadCrumbs";
import { ScreensPageNotFound } from "@/screens/General/PageNotFound";
import { Permission } from "@/utils/Permission";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ThrowException } from "@/screens/Util/ThrowException";
import { ScreensLicenseExpired } from "@/screens/General/LicenseExpiredPage";
import { ScreensNoLicensePage } from "@/screens/General/NoLicensePage";
import { ScreensShopPreferencesPage } from "@/screens/Shop/Preferences/Page";
import { DebugMenu } from "@/screens/Util/DebugMenu";
import { ScreensShopJobsList } from "@/screens/Shop/Jobs/JobsList";
import { ScreensShopJobsNew } from "@/screens/Shop/Jobs/New/New";
import { ScreensNotificationUnsubscribe } from "@/screens/General/UnSubscribeNotification";
import { ScreensShopJob } from "@/screens/Shop/Jobs/Job";

export interface IRoutesProps {
  location: NavLinkProps["location"];
}

export const Routes: FunctionComponent<IRoutesProps> = ({ location }: IRoutesProps) => {
  const {
    currentUser: { userProfile },
    currentShop,
  } = useContext(ApplicationContext);

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, [location]);

  const {
    newShop,
    technology,
    newMachine,
    machines,
    machine,
    newOrder,
    orders,
    order,
    info,
    preferences,
    jobs,
    job,
    newJob,
  } = BREADCRUMBS;
  return (
    <Switch location={location}>
      <PrivateRoute
        exact
        path={ROUTES.ROOT}
        component={Shell(ScreensShopList, SHOP.SECTIONS.orders, [])}
      />
      <PrivateRoute
        exact
        path={ROUTES.SHOP(":shopId?", true).GENERAL}
        component={Shell(ScreensShopNew, "general", [newShop])}
        permissionDo={Permission.CREATE_SHOP}
        permissionOn={userProfile}
      />
      <PrivateRoute
        exact
        path={`${ROUTES.SHOP(":shopId").MACHINES.INDEX}`}
        component={Shell(ScreensShopMachineList, SHOP.SECTIONS.machines, [machines])}
        permissionDo={Permission.WRITE_MACHINES}
        permissionOn={currentShop}
      />
      <PrivateRoute
        exact
        path={`${ROUTES.SHOP(":shopId").MACHINES.TECHNOLOGY(":technologyId")}`}
        component={Shell(ScreensShopMachineList, SHOP.SECTIONS.machines, [machines, technology])}
        permissionDo={Permission.WRITE_MACHINES}
        permissionOn={currentShop}
      />
      <PrivateRoute
        path={`${ROUTES.SHOP(":shopId").MACHINES.MACHINE(":technologyId", ":machineId")}`}
        component={Shell(ScreensShopMachinePage, SHOP.SECTIONS.machines, [
          machines,
          technology,
          machine,
        ])}
      />
      <PrivateRoute
        path={ROUTES.SHOP(":shopId").MACHINES.NEW}
        component={Shell(ScreensShopMachinesNew, SHOP.SECTIONS.machines, [machines, newMachine])}
        permissionDo={Permission.CREATE_MACHINE}
        permissionOn={currentShop}
      />
      <PrivateRoute
        path={ROUTES.SHOP(":shopId").MACHINES.EDIT(":machineId")}
        component={Shell(MachineDetails, undefined, [machines, machine])}
      />
      <PrivateRoute
        path={ROUTES.SHOP(":shopId").INFO}
        component={Shell(ScreensShopInfoPage, SHOP.SECTIONS.info, [info])}
        permissionDo={Permission.SHOP_INFO_VIEW}
        permissionOn={currentShop}
      />
      <PrivateRoute
        // Route to machines and materials for an existing shop
        path={ROUTES.SHOP(":shopId").MACHINES.SHOW()}
        component={Shell(ScreensShopMachineList, SHOP.SECTIONS.machines, [machines])}
        permissionDo={Permission.WRITE_MACHINES}
        permissionOn={currentShop}
      />
      <PrivateRoute
        // Route to machines and materials during shop setup
        path={ROUTES.SHOP(":shopId?", true).MACHINES.SHOW()}
        component={Shell(ScreensShopNew, SHOP.SECTIONS.machines, [newShop, newMachine])}
        permissionDo={Permission.CREATE_SHOP}
        permissionOn={userProfile}
      />
      <PrivateRoute
        strict
        path={ROUTES.SHOP(":shopId").ORDER.NEW}
        component={Shell(OrderNew, SHOP.SECTIONS.orders, [orders, newOrder])}
        permissionDo={Permission.CREATE_ORDER}
        permissionOn={currentShop}
      />
      <PrivateRoute
        strict
        path={ROUTES.SHOP(":shopId").ORDER.SHOW(":orderId")}
        component={Shell(ScreensOrderPage, SHOP.SECTIONS.orders, [orders, order])}
      />
      <PrivateRoute
        path={ROUTES.SHOP(":shopId").PREFERENCES}
        component={Shell(ScreensShopPreferencesPage, SHOP.SECTIONS.preferences, [preferences])}
        permissionDo={Permission.CREATE_SHOP}
        permissionOn={userProfile}
      />
      <PrivateRoute
        strict
        exact
        path={ROUTES.SHOP(":shopId").JOBS.LIST}
        component={Shell(ScreensShopJobsList, SHOP.SECTIONS.jobs, [jobs])}
      />
      <PrivateRoute
        strict
        exact
        path={ROUTES.SHOP(":shopId").JOBS.NEW}
        component={Shell(ScreensShopJobsNew, SHOP.SECTIONS.jobs, [jobs, newJob])}
      />
      <PrivateRoute
        strict
        path={ROUTES.SHOP(":shopId").JOBS.SHOW(":jobId")}
        component={Shell(ScreensShopJob, SHOP.SECTIONS.jobs, [jobs, job])}
      />

      <PrivateRoute
        strict
        path={ROUTES.SHOP(":shopId").ORDERS}
        component={Shell(ScreensShopOrderList, SHOP.SECTIONS.orders, [orders])}
      />

      <PrivateRoute
        strict
        path={ROUTES.UNSUBSCRIBE(":orderId")}
        component={ScreensNotificationUnsubscribe}
      />

      {!COMPANY_SERVER_AUTH_ENABLED && (
        <Route exact path={ROUTES.LOGIN} component={ScreensUserLoginForm} />
      )}

      <PrivateRoute strict path={"/obscure/throw-exception"} component={ThrowException} />
      <PrivateRoute strict path={"/debugMenu"} component={DebugMenu} />

      <Route strict path={ROUTES.EXPIRED} component={ScreensLicenseExpired} />
      <Route strict path={ROUTES.NO_LICENSE} component={ScreensNoLicensePage} />

      {/* No matches => show something instead of empty page */}
      <Route component={ScreensPageNotFound} />
    </Switch>
  );
};
