import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Header,
  Input,
  Modal,
  StyledModal,
} from "grabcad-ui-elements";
import { Notifier } from "@/utils/Notifier";
import { IShop } from "@/graphql/Fragments/Shop";
import React, { useContext, useState } from "react";
import { ROUTES } from "@/shopConstants";
import { ShopLogo } from "../Logo";
import { CardLinkWrapper } from "@/components/Shared/CardLink";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ContextMenu } from "../Machines/ContextMenu";
import { ApolloError, useMutation } from "@apollo/client";
import { CLONE_SHOP, DELETE_SHOP } from "../../../graphql/Mutations/Shop";
import { SHOPS_LIST } from "../../../graphql/Queries";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import ReactGA from "react-ga";
import classnames from "classnames";

interface IShopProps {
  className?: string;
  shop: Partial<IShop>;
}

export const ShopCard = ({ shop, className }: IShopProps): JSX.Element => {
  const { name, id: shopId } = shop;
  const {
    currentUser: { userProfile: user },
    t,
  } = useContext(ApplicationContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const menuItems = [
    <Link to={ROUTES.SHOP(shopId).INFO} className="item edit" key="edit">
      {t("shop.edit")}
    </Link>,
    <a
      className="item delete"
      onClick={e => {
        e.preventDefault();
        setShowDeleteModal(true);
      }}
      key="delete"
    >
      {t("shop.delete")}
    </a>,
    <a
      className="item"
      onClick={e => {
        e.preventDefault();
        setShowCloneModal(true);
      }}
      key="clone"
    >
      {t("shop.clone")}
    </a>,
  ];

  return (
    <>
      <CardLinkWrapper className={className} data-testid="shopCard">
        <Link className="qa-shopCard" to={ROUTES.SHOP(shopId).ORDERS}>
          <Card link raised>
            <ShopLogo shop={shop} size="medium" />
            <CardContent>
              <CardHeader>
                <span className="qa-shopCard-name shop-name" data-testid="shopCardName">
                  {name}
                </span>
                {/* TODO GC-58446 Remove inconsistency */}
                <UiCan do={Permission.CREATE_SHOP} on={user}>
                  <ContextMenu menuItems={menuItems} />
                </UiCan>
              </CardHeader>
            </CardContent>
          </Card>
        </Link>
      </CardLinkWrapper>
      {showDeleteModal && <DeleteShopModal shop={shop} onClose={() => setShowDeleteModal(false)} />}
      {showCloneModal && <CloneShopModal shop={shop} onClose={() => setShowCloneModal(false)} />}
    </>
  );
};

const DeleteShopModal = ({ shop, onClose }: { shop: Partial<IShop>; onClose: () => void }) => {
  const { t } = useContext(ApplicationContext);
  const [deleteShop] = useMutation(DELETE_SHOP, {
    update: () => Notifier.success(`${t("shop.deleted")} ${shop?.name}`),
    refetchQueries: [{ query: SHOPS_LIST }],
    onError: (error: ApolloError) => Notifier.error(error),
    variables: { id: shop.id },
  });
  return (
    <ConfirmationModal
      headerIcon="trash"
      headerCopy={t("shop.delete.header")}
      bodyTitle={shop?.name}
      bodyCopy={t("shop.delete.warning")}
      cancelTranslationKey="general.cancel"
      confirmTranslationKey="shop.delete"
      open={true}
      onClose={onClose}
      submitAction={() => {
        deleteShop();
        ReactGA.event({
          category: "GcShop",
          action: "Deleted Shop",
          label: `Shop ${shop?.id}`,
        });
      }}
    />
  );
};

const CloneShopModal = ({ shop, onClose }: { shop: Partial<IShop>; onClose: () => void }) => {
  const { t } = useContext(ApplicationContext);

  const MAX_LENGTH = 100;
  const [inputValue, setInputValue] = useState(`${shop.name} ${t("shop.clone.suffix")}`);
  const tooLongTooltip =
    inputValue.length > MAX_LENGTH ? t("shop.clone.modal.nameTooLong") : undefined;
  const [cloneShop] = useMutation(CLONE_SHOP, {
    update: () => Notifier.success(`${t("shop.cloned")}: ${inputValue}`),
    refetchQueries: [{ query: SHOPS_LIST }],
    onError: (error: ApolloError) => Notifier.error(error),
    variables: { id: shop.id, newName: inputValue },
  });

  return (
    <StyledModal closeIcon basic={false} open={true} onClose={onClose} data-testid="cloneShopModal">
      <Header content={t("shop.clone.modal.header")} />
      <Modal.Content>
        <b>{t("shop.clone.modal.name")}</b>
        <Input
          className={classnames({ error: !!tooLongTooltip })}
          data-tooltip={tooLongTooltip}
          data-visible
          onChange={e => setInputValue(e.target.value)}
          value={inputValue}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button
          data-testid="confirmCloneButton"
          onClick={async () => {
            cloneShop();
            onClose();
          }}
          disabled={inputValue.length === 0 || !!tooLongTooltip}
        >
          {t("shop.clone")}
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
