import React, { useContext, useState, useEffect } from "react";
import styled, { Image, GridColumn, Header, Button, Loader, Grid } from "grabcad-ui-elements";
import { Notifier } from "@/utils/Notifier";
import { Link } from "react-router-dom";
import { ROUTES } from "@/shopConstants";
import { UiCan } from "../../../components/UiCan";
import { Permission } from "@/utils/Permission";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { IShop, ShopState } from "@/graphql/Fragments/Shop";
import { SHOPS_LIST } from "@/graphql/Queries";
import { ShopCard } from "@/components/Shop/Card/Card";
import Placeholder from "@/assets/placeholder_shops.svg";
import { ApolloError } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import classnames from "classnames";
import { TOTAL_SHOPS_COUNT } from "@/graphql/Queries/Shop";
import { SampleShopGeneration } from "./SampleShopGeneration";
import { Onboarding } from "@/components/Shared/Onboarding";
import { PlaceHolder } from "@/components/Shared/Placeholder";

const SAMPLE_SHOP = "sample-shop";

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 1em;
  h1 {
    flex: 1;
  }
`;

export const ScreensShopList = (): JSX.Element => {
  const {
    currentShop,
    setCurrentShop,
    ability,
    currentUser: { userProfile: user },
    t,
  } = useContext(ApplicationContext);

  const [showOnboarding, setShowOnboarding] = useState(false);
  const [sampleShopGenerationFailed, setSampleShopGenerationFailed] = useState(false);
  const [sampleModalDisplayed, setSampleModalDisplayed] = useState(false);

  useEffect(() => {
    if (currentShop) {
      // Clear out current shop if any
      setCurrentShop(null);
    }
  });

  const createShopButton = (
    <UiCan do={Permission.CREATE_SHOP} on={user}>
      <Link to={ROUTES.SHOP(undefined, true).GENERAL}>
        <Button id="qa-button-createNewShop" primary>
          {t("shop.list.create_new_shop")}
        </Button>
      </Link>
    </UiCan>
  );

  const header = (
    <Header as="h2" className="page-header">
      {t("shop.list.title")}
    </Header>
  );

  const isAdmin = user ? ability.can(Permission.CREATE_SHOP, user) : false;

  return (
    <Query<{ shops?: IShop[] }>
      query={SHOPS_LIST}
      onError={(error: ApolloError) => Notifier.error(error)}
    >
      {({ loading, data, error }) => {
        if (error) {
          return <div />;
        }
        if (loading || !data || !data.shops) {
          return (
            <>
              <StyledHeader>{header}</StyledHeader>
              <Loader active={true} size="large" data-testid="loader" />
            </>
          );
        }
        if (data.shops.length === 0 || sampleModalDisplayed) {
          return (
            <>
              <StyledHeader>{header}</StyledHeader>
              <PlaceHolder id="qa-placeHolder">
                <Image src={Placeholder} />
                <h2>{t("shop.list.placeholder.header" + (isAdmin ? "" : "NonAdmin"))}</h2>
                <p>{t("shop.list.placeholder.copy" + (isAdmin ? "" : "NonAdmin"))}</p>
                <UiCan do={Permission.CREATE_SHOP} on={user}>
                  <Link to={ROUTES.SHOP(undefined, true).GENERAL}>
                    <Button id="qa-button-createNewShop" primary>
                      {t("shop.list.create_new_shop")}
                    </Button>
                  </Link>
                  <Query<{ allShopsCount?: number }> query={TOTAL_SHOPS_COUNT}>
                    {result => {
                      // if admin user has no shops then trigger generation of sample shop, orders and machines
                      if (result.data && result.data.allShopsCount === 0) {
                        setSampleModalDisplayed(true);
                      }
                      return sampleModalDisplayed && !sampleShopGenerationFailed ? (
                        <SampleShopGeneration
                          onClose={(generationFailed: boolean) => {
                            setSampleShopGenerationFailed(generationFailed);
                            setSampleModalDisplayed(false);
                            if (!showOnboarding) {
                              setTimeout(() => setShowOnboarding(true), 1000);
                            }
                          }}
                        />
                      ) : null;
                    }}
                  </Query>
                </UiCan>
              </PlaceHolder>
            </>
          );
        }
        return (
          <>
            <StyledHeader>
              {header}
              {createShopButton}
            </StyledHeader>
            <Grid stretched>
              {data.shops.map((shop: IShop) => {
                if (
                  data.shops &&
                  data.shops.length === 1 &&
                  data.shops[0].state === ShopState.SAMPLE &&
                  !showOnboarding
                ) {
                  setTimeout(() => setShowOnboarding(true), 1000);
                }
                return (
                  <UiCan key={shop.id} do={Permission.LIST} on={shop}>
                    <GridColumn mobile={16} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                      <ShopCard
                        shop={shop}
                        className={classnames({
                          "sample-shop":
                            shop.state === ShopState.SAMPLE &&
                            data.shops &&
                            data.shops.length === 1,
                        })}
                      />
                    </GridColumn>
                  </UiCan>
                );
              })}
            </Grid>
            {showOnboarding && (
              <Onboarding
                onboardingKey={SAMPLE_SHOP}
                steps={[
                  {
                    target: ".sample-shop",
                    title: t("shop.list.admin.onboarding.title"),
                    content: t("shop.list.admin.onboarding.content"),
                    placement: "right",
                  },
                ]}
                dismissCopy={t("general.dismiss")}
              />
            )}
          </>
        );
      }}
    </Query>
  );
};
