import React, { useContext, useRef, useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import ReactGA from "react-ga";
import styled, {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Modal,
  Notifier,
  Popup,
  StyledModal,
  Table,
  TableCell,
  UserSelector,
} from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import {
  IOrderItemJobRouteStepHistory,
  IShopJob,
  IShopRoute,
  JOB_COUNTS,
  JOB_DETAILS,
  SHOP_JOBS,
  SHOP_ROUTES,
} from "@/graphql/Queries/Job";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { MATERIAL_QUANTITY, MATERIAL_UNITS, ROUTES, TIME_UNITS } from "@/shopConstants";

import job_created from "../../../assets/icons/job_created.svg";
import queued from "../../../assets/icons/queued.svg";
import printing from "../../../assets/icons/printing.svg";
import fabricating from "../../../assets/icons/fabricating.svg";
import post_processing from "../../../assets/icons/post_processing.svg";
import completed from "../../../assets/icons/completed.svg";
const stepIcons: { [key: string]: string } = {
  job_created,
  queued,
  printing,
  fabricating,
  post_processing,
  completed,
};

import machineIcon from "@/assets/icons/machine.svg";
import technologyIcon from "@/assets/icons/technology.svg";
import materialIcon from "@/assets/icons/material.svg";
import { useCacheableMachineRate, useShopTechnologies } from "@/utils/queryHooks";
import { getStatusName, OrderStatus, StatusDropdown } from "@/components/Order/Status/Status";
import {
  AddPartsToJobForm,
  removeOrderItemsFromCachedJob,
  updateCachedOrderItemsJob,
} from "./New/JobForm";
import {
  ApolloClient,
  DocumentNode,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { Link } from "react-router-dom";
import { ToPrintButton } from "@/components/Order/OrderItemsActionsOnMultiSelect/ToPrintButton";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import {
  CREATE_JOB_AUTOESTIMATION,
  CREATE_ORDER_ITEM_JOB_ROUTE_STEP_HISTORIES,
  DELETE_JOB,
  IJobHistoriesInput,
  ItemQuantityToMove,
  IUpdateJobInput,
  REMOVE_ORDER_ITEMS_FROM_JOB,
  UPDATE_JOB,
} from "@/graphql/Mutations/Job";
import { LocalizedNumericInput } from "@/components/Shared/LocalizedNumericInput";
import { getCadModelName, isAnyMaterial } from "@/utils/GeneralUtils";
import { DragLayerMonitor, useDrag, useDragLayer, useDrop } from "react-dnd";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { SPLIT_ORDER_ITEMS, UPDATE_ORDER_ITEMS_STATUS } from "@/graphql/Mutations/OrderItem";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import { QuantitiesById } from "@/graphql/Mutations/GcpDownload";
import classNames from "classnames";
import { isEllipsisActive } from "@/utils/InputUtils";
import { useKeyPress } from "@/utils/useKeyPress";
import { getEmptyImage } from "react-dnd-html5-backend";
import { StyledSmallThumbnail } from "./JobsList";
import { CadItemDetails } from "@/components/Order/ItemsList/CadItemDetails";
import { MultiFileDownloadButton } from "@/components/Shared/MultiFileDownloadButton";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import { updateCadModelQueryCache } from "@/graphql/Utils/updateCadModelQueryCacheUtil";
import { getMaterialUnitLabel } from "../../../utils/DropdownUtils";
import { StyledLabeledInput } from "../Order/Show/PriceCalculator";
import {
  AUTOESTIMATIONS_POLL_FREQ,
  AutoEstimationError,
  AutoEstimationErrorType,
  EstimationProgressBar,
  autoEstimateToCustomUnits,
  getEstimationError,
  stringifyGCPVersion,
} from "../../../components/Shared/AutoEstimations";
import { AUTO_ESTIMATION_PROGRESS } from "../../../graphql/Queries/AutoEstimation";
import materialEstimate from "../../../assets/icons/materialEstimate.svg";
import timeEstimate from "../../../assets/icons/timeEstimate.svg";
import { getTranslatedItemUnits } from "../../../utils/DropdownUtils";
import { History } from "history";
import { ORDER_DETAILS } from "../../../graphql/Queries";
import { JobEstimatesModal, JobEstimatesModalTab } from "../../../components/Job/JobEstimatesModal";
import { AssetType, AutoEstimation } from "../../../graphql/Fragments/AutoEstimation";
import { DownloadAutoEstimationAssetButton } from "../../../components/Order/Items/AutoEstimations";
import { FeatureKey } from "../../../graphql/Fragments/UserLicense";

const ITEM_ROW = "itemQuantityRow";

const StyledJobDetails = styled.div`
  .header-row {
    flex: 0 0 auto;
    margin-bottom: 1em;
    display: flex;
    h2 {
      flex-grow: 1;
    }
    .button {
      margin-top: 14px !important;
    }
    .completed-on {
      align-self: flex-end;
      margin: 10px;
    }
  }

  .job-details {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;
    padding: 10px 20px;
    .popup-trigger {
      height: 30px;
    }
    .job-name .ui.input {
      width: 300px;
    }
    /* TODO: move to grabcad-ui-elements?? */
    .ui.input {
      > input {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          border-color: #003393 !important;
        }
      }
    }
  }

  .shop_route {
    position: relative;
    min-height: 78px;
    margin: 20px;
  }
  .job-meta {
    border-top: 1px solid #cccccc;
    display: flex;
    padding: 10px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 30px;
    .meta-col {
      &.not(:last-child) {
        margin-right: 30px;
      }
      .meta-cell {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 4px 10px;
      }
      label {
        margin 0 7px 0 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .label-value {
        font-weight: bold;
      } 
      .estimate-value {
        flex: auto 1 0;
        display: flex;
        padding: 5px 8px;
        align-content: center;
        border: 1px solid #cccccc;
        justify-content: space-between;
        border-radius: 4px 0px 0px 4px;
        min-width: 61px; 
        .estimate {
          width: 100%;
          text-align: left;
        }
      }
    }
    // Buttons are right-aligned
    .meta-actions {
      flex-grow: 1;
      display: flex;
      justify-content: end;
    }

    .estimate-assets {
      flex-basis: 10%;
      min-width: 100px;
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      white-space: nowrap;
    }
  }
`;
const StyledJobActions = styled.div`
  /* TODO: Share styles with order page? */

  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  .part-actions {
    flex-grow: 1;
    display: flex;
    gap: 10px;
  }

  button.ui.button {
    &.circular {
      align-items: center;
      justify-content: space-around;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
    }
    > i.icon {
      color: #767676;
      height: auto;
      &.trash {
        font-size: 1.4em;
        vertical-align: bottom;
        flex: 1 2 auto;
      }
    }
  }
`;
const StyledJobRouteStepParts = styled.div`
  margin-top: 10px;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    .step-title {
      padding: 4px 1px;
      display: flex;
      align-items: center;
      .step-toggle {
        font-size: 1.38em;
        width: 20px;
        margin-right: 0;
      }
      .step-icon {
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .parts-count {
      margin-left: 10px;
    }
    .move-parts-dropdown {
      display: flex;
      align-items: center;
      .dropdown {
        margin: 0 10px;
      }
    }
  }
  &.drag-is-over {
    background: #d5e5fc;
    box-shadow: 0 0 10px rgb(34 36 38 / 45%) !important;
  }
  &:not(.drag-is-over) {
    tr.qa-part-row.selected:hover,
    tr.qa-part-row:hover {
      cursor: pointer;
      background: #ecf4ff; /* TODO: Share this */
    }
  }
`;
const StyledJobRouteStepPartsTable = styled(Table)`
  &.ui.table {
    position: relative;
    border: none;
    flex: 1 1 100%;
    flex-wrap: wrap;
    margin: 0;
    thead th {
      height: 55px;
      background-color: #f4f5f7;
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      border-left: none;
      border-radius: 0 !important;
      position: sticky;
      top: 0px;
      z-index: 2;
      &.sorted,
      &:hover,
      &.sorted:hover {
        background: #f2f2f2; /* Override semantic's default translucent black to support sticky header */
      }
    }

    @media (max-width: 767px) {
      &.ui.table {
        display: block; /* display: table -> block when screen width is small to prevent text from overflowing */
      }
    }

    /* This short-circuits table display to align columns between tables */
    tr {
      display: flex;
      align-items: center;
      flex-direction: row;

      &.selected {
        background: #e0ecfc; /* TODO: Share this */
      }

      &.non-estimated-model,
      &.non-estimated-model:hover {
        &:not(.is-dragging) {
          background-color: lightpink;
        }
      }

      &[data-tooltip] {
        z-index: 50; /* leave room for movePartsTo dropdown above this but under the CustomDragLayer */
      }

      &.is-dragging {
        background: #f4f5f796;
        td {
          opacity: 0;
        }
      }

      th,
      td {
        display: flex;
        align-items: center;
        &:not(.status) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.checkbox-and-arrows {
          flex: 120px 0 0;
          height: 65px;
        }
        &.thumb {
          padding: 0;
          flex: 54px 0 0;
          height: 65px;
        }
        &.name {
          flex: 150px 1 0;
          height: 65px;
          width: 0;

          > div {
            min-width: 0;

            > div {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              > div {
                display: inline-block;
                position: unset;
              }
            }
          }
        }
        &.material {
          flex: 150px 0 1;
          height: 65px;
        }
        &.quantity {
          flex: 100px 0 1;
          height: 65px;
        }
        &.units {
          flex: 80px 0 1;
          height: 65px;
        }
        &.need-by-date {
          flex: 150px 0 1;
          height: 65px;
        }
        &.status {
          flex: 150px 0 0;
          height: 65px;
        }
        &.order {
          flex: 150px 0 1;
          height: 65px;
          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .full-name {
    position: relative;
    height: 24px;
    left: -3px;
    > div {
      font-size: 12px; /* TODO: Make this the default? */
      height: 20px;
      padding-top: 4px;
    }
  }
  .checkbox-and-arrows {
    display: flex;
    align-items: center;
    padding: 0 0.78571429em !important;
    margin-top: 1px;
    .ui.checkbox {
      margin-top: 4px;
      margin-right: 10px;
    }
    .arrow-buttons {
      display: flex;
      gap: 2px;
      flex-direction: column;
      .ui.button {
        margin: 0;
        padding: 8px 10px;
      }
    }
    .part-details-toggle {
      margin-left: 10px;
      .icon {
        font-size: 1.3em;
      }
    }
  }
`;
const StyledMovePartsModal = styled(StyledModal)`
  &.ui.modal {
    .content .item-quantity-to-move {
      padding-bottom: 10px;
      border-bottom: 1px solid rgb(0 0 0 / 20%);
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .input-row {
        display: flex;
        align-items: center;
      }
      .item-name {
        flex: 1 1 0%;
        word-break: break-all;
      }
      .input.quantity-input {
        width: 100px;
      }
    }
    .error {
      color: #ff4433;
      margin-top: 5px;
    }
  }
`;
const EstimatesLoader = styled.div`
  width: 15px;
  padding: 10px 0px;
  .ui.tiny.loader {
    z-index: 0;
  }
`;

const JobEstimationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    padding: 10px 20px;
  }
`;
export interface IOrderItemQuantityByStep {
  [id: number]: number;
}
type ISortedItemQuantityByStep = {
  id: number;
  quantity: number;
}[][];
interface ICheckedItemIds {
  [id: number]: boolean;
}

export const getItemQuantitiesByStep = (
  job: IShopJob,
  jobRoute: IShopRoute
): IOrderItemQuantityByStep[] => {
  // Play through all history events to determine quantities currently in each step
  let orderItemQuantityByStep: Array<{ [id: number]: number }> = jobRoute.shopRouteSteps.map(
    () => ({} as any)
  );
  const stepIndexFromId = (id: number) => jobRoute.shopRouteSteps.findIndex(stp => stp.id === id);
  job.orderItemJobRouteStepHistories
    // .sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime())
    // ^^ It's important that these are sorted server side since both client and server need to accurately
    // count available quantities in steps. Sorting on client may provide a false sense of security.
    .forEach(event => {
      if (!event.dateDeleted) {
        const toStepIndex = stepIndexFromId(event.toShopRouteStepId);
        if (toStepIndex !== -1) {
          if (orderItemQuantityByStep[toStepIndex][event.orderItemId] !== undefined) {
            orderItemQuantityByStep[toStepIndex][event.orderItemId] += event.quantityMoved;
          } else {
            orderItemQuantityByStep[toStepIndex][event.orderItemId] = event.quantityMoved;
          }

          if (event.fromShopRouteStepId) {
            const fromStepIndex = stepIndexFromId(event.fromShopRouteStepId);
            orderItemQuantityByStep[fromStepIndex][event.orderItemId] -= event.quantityMoved;
          }
        }
      }
    });
  orderItemQuantityByStep = orderItemQuantityByStep.map(stepQuantities => {
    const excludeZeroQuantities: { [id: number]: number } = {};
    for (const id in stepQuantities) {
      if (stepQuantities[id] > 0) {
        excludeZeroQuantities[id] = stepQuantities[id];
      }
    }
    return excludeZeroQuantities;
  });
  return orderItemQuantityByStep;
};

export const getSummedQuantitiesByStep = (
  orderItemQuantityByStep: IOrderItemQuantityByStep[]
): number[] =>
  orderItemQuantityByStep.map(step =>
    Object.keys(step).reduce((acc, cur) => acc + step[cur as unknown as number], 0)
  );

const createOrderItemJobRouteStepHistories = async (
  client: ApolloClient<any>,
  jobId: number,
  quantities: ItemQuantityToMove[]
) =>
  client.mutate<
    {
      createOrderItemJobRouteStepHistories: IOrderItemJobRouteStepHistory[];
    },
    { input: IJobHistoriesInput }
  >({
    mutation: CREATE_ORDER_ITEM_JOB_ROUTE_STEP_HISTORIES,
    variables: {
      input: {
        jobId,
        itemQuantitiesToMove: quantities,
      },
    },
  });

export const getCheckedItemIdsWithQtyInJobSteps = (
  orderItemQuantityInJobSteps: IOrderItemQuantityByStep[],
  checkedItemIdsByStep: ICheckedItemIds[]
): QuantitiesById[] => {
  const checkedItemIdsWithQty = new Map<number, number>();
  orderItemQuantityInJobSteps.forEach((step, i) =>
    Object.keys(step).forEach(itemId => {
      if (checkedItemIdsByStep[i] && checkedItemIdsByStep[i][+itemId]) {
        checkedItemIdsWithQty.set(
          +itemId,
          (checkedItemIdsWithQty.get(+itemId) || 0) + step[+itemId]
        );
      }
    })
  );
  const selectedItemIdsWithQty = Array.from(checkedItemIdsWithQty, ([itemId, qty]) => ({
    itemId,
    qty,
  }));
  return selectedItemIdsWithQty;
};

const StyledDraggedPartCount = styled.div`
  position: absolute;
  top: -5px;
  left: 5px;
  z-index: 101;
  padding: 0 8px;
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 15px;
  background: #003393;
  color: white;
`;

export const CustomDragLayer = ({
  checkedItemIdsByStep,
  orderItemQuantityByStep,
}: {
  checkedItemIdsByStep: ICheckedItemIds[];
  orderItemQuantityByStep: IOrderItemQuantityByStep[];
}): JSX.Element | null => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor: DragLayerMonitor) => ({
      item: monitor.getItem(), // TODO: Make this typesafe
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  if (!isDragging || !initialOffset || !currentOffset) {
    return null;
  }

  const { x, y } = currentOffset;

  // Subtracting initialOffset.x helps align dragged row in CustomDragLayer within scrolling .main-content
  const transform = `translate(${x - initialOffset.x}px, ${Math.round(y)}px)`;
  const checkedIdsInStep = checkedItemIdsByStep[item.stepIndexFrom];
  const itemQuantitiesInStep = orderItemQuantityByStep[item.stepIndexFrom];
  const totalQuantityDragged = Object.keys(checkedIdsInStep).reduce(
    (total, id) =>
      checkedIdsInStep[+id] || +id === item.id ? total + itemQuantitiesInStep[+id] : total,
    0
  );

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ position: "relative", transform }}>
        {totalQuantityDragged > 1 && (
          <StyledDraggedPartCount>{totalQuantityDragged}</StyledDraggedPartCount>
        )}
        <StyledJobRouteStepPartsTable
          style={{ marginLeft: 15, width: "calc(100% - 40px)" }}
          className="round-and-shadow"
        >
          <tbody>
            <ItemQuantityRow item={item.item} quantity={item.quantity} checked={item.checked} />
          </tbody>
        </StyledJobRouteStepPartsTable>
      </div>
    </div>
  );
};

export const ScreensShopJob = withRouter(
  ({
    match: {
      params: { jobId },
    },
    history,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  RouteComponentProps<{ jobId: string }>) => {
    const { currentShop, t, messageExists, formatDate, hasFeature } =
      useContext(ApplicationContext);
    if (!currentShop) {
      return null;
    }
    const { shopTechnologies, allMachines, allMaterials } = useShopTechnologies();

    const [showAddPartsModal, setShowAddPartsModal] = useState(false);
    const [showJobEstimatesModal, setShowJobEstimatesModal] = useState(false);
    const [stepsToggleOpen, setStepsToggleOpen] = useState<boolean[]>([]);
    const [checkedItemIdsByStep, setCheckedItemIdsByStep] = useState<ICheckedItemIds[]>([]);
    const [itemQuantitiesToMove, setItemQuantitiesToMove] = useState<ItemQuantityToMove[]>([]);
    const [movingItemsInFlight, setMovingItemsInFlight] = useState(false);

    // Shifty-select logic across steps!
    const [lastSelectedStepIndex, setLastSelectedStepIndex] = useState<number | undefined>(
      undefined
    );
    const [lastSelectedItemIndex, setLastSelectedItemIndex] = useState<number | undefined>(
      undefined
    );
    const shiftPressed = useKeyPress("Shift");

    const client = useApolloClient();
    const jobDetailsQuery = useQuery<{ job: IShopJob }, { id: number }>(JOB_DETAILS, {
      variables: { id: +jobId },
    });
    const job = jobDetailsQuery.data?.job;
    const jobRoutesQuery = useQuery<{ loadShopRoutes: IShopRoute[] }, { id: number }>(SHOP_ROUTES, {
      variables: { id: currentShop.id },
    });
    const jobRoute =
      job &&
      jobRoutesQuery.data &&
      jobRoutesQuery.data.loadShopRoutes.find(route => route.id === job.shopRouteId);
    const [jobRouteRefetched, setJobRouteRefetched] = useState<boolean>(false);
    if (!!jobRoutesQuery.data && !jobRoute && !jobRouteRefetched) {
      // This can happen for the first job created for a Technology. The new Route is created as needed server side.
      jobRoutesQuery.refetch();
      setJobRouteRefetched(true); // This is to avoid any potential for looping network requests if something goes wrong
    }

    // Update cadModel cached queries
    job &&
      updateCadModelQueryCache(
        client,
        job.orderItems.map(item => item.id)
      );

    const [updateJobCompleted] = useMutation<any, { input: IUpdateJobInput }>(UPDATE_JOB, {
      onCompleted: ({ updateJob }) => {
        setUpdateJobCompletedInFlight(false);
        updateJob.dateCompleted
          ? Notifier.success(t("shop.job.complete.success"))
          : Notifier.success(t("shop.job.reopen.success"));
      },
    });
    const [updateJobCompletedInFlight, setUpdateJobCompletedInFlight] = useState(false); // TODO: Shared `mutationInFlight`?

    const [showCompleteJobModal, setShowCompleteJobModal] = useState(false);
    const [showDeleteEmptyJobModal, setShowDeleteEmptyJobModal] = useState(false);
    const [updatePartStatusesChecked, setUpdatePartStatusesChecked] = useState(true);
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [itemIdsToUpdateStatus, setItemIdsToUpdateStatus] = useState<number[]>([]);
    const [selectedStatus, setSelectedStatus] = useState(
      currentShop.shopStatuses.find(status => status.appStatus === "COMPLETED") as IShopStatus
    );
    const [jobName, setJobName] = useState<string | undefined>(job?.name);
    const [updateJobName] = useMutation<any, { input: IUpdateJobInput }>(UPDATE_JOB);
    const [updateOrderItemsStatus] = useMutation<any, any>(UPDATE_ORDER_ITEMS_STATUS);
    const [generateAutoEstimates] = useMutation(CREATE_JOB_AUTOESTIMATION);

    let orderItemQuantityByStep: IOrderItemQuantityByStep[] = [];

    const doUpdateJobCompleted = async (isCompleted: boolean) => {
      // all parts are completed - set job completed
      setUpdateJobCompletedInFlight(true);
      await updateJobCompleted({
        variables: { input: { id: job!.id, isCompleted } },
        refetchQueries: [{ query: JOB_COUNTS, variables: { id: currentShop.id } }],
      });
    };
    useEffect(() => {
      if (!job || !jobRoute) {
        return;
      }
      const totalItemQuantities = job.orderItems.reduce((sum, item) => sum + item.quantity, 0);
      // Count number of parts in the last / final step, which is "completed"
      const quantityInCompletedStep =
        getSummedQuantitiesByStep(orderItemQuantityByStep)[orderItemQuantityByStep.length - 1];
      if (
        !job.dateCompleted &&
        totalItemQuantities === quantityInCompletedStep &&
        !updateJobCompletedInFlight &&
        totalItemQuantities > 0
      ) {
        // all parts are completed - set job completed
        doUpdateJobCompleted(true);
      } else if (
        job.dateCompleted &&
        totalItemQuantities !== quantityInCompletedStep &&
        !updateJobCompletedInFlight
      ) {
        doUpdateJobCompleted(false);
      }
    }, [orderItemQuantityByStep]);

    const inProgressAutoEstimationId = !job?.autoEstimation?.resultData && job?.autoEstimationId;

    const [_, { startPolling, stopPolling }] = useLazyQuery(AUTO_ESTIMATION_PROGRESS, {
      variables: { id: job?.autoEstimationId },
    });

    useEffect(() => {
      if (!!inProgressAutoEstimationId) {
        startPolling(AUTOESTIMATIONS_POLL_FREQ);
      } else {
        stopPolling();
      }
    }, [inProgressAutoEstimationId]);

    const shopTechnology = shopTechnologies.find(tech => tech.id === job?.shopTechnologyId);
    const shopMachine = allMachines.find(machine => machine.id === job?.shopMachineId);
    const shopMaterial = allMaterials.find(material => material.id === job?.shopMaterialId);

    const {
      machineTimeUnit: timeUnit = TIME_UNITS.HOURS,
      materialUnit = MATERIAL_UNITS.KG,
      supportUnit: rateSupportUnit,
    } = useCacheableMachineRate(shopMachine?.newestMachineRateId)?.machineRate || {};
    const supportUnit = rateSupportUnit || materialUnit;
    const resultData = job?.autoEstimation?.resultData;
    const firstEstimate = resultData?.estimates && resultData?.estimates[0];
    const estimateParameters = firstEstimate?.parameters;
    const hasParameters =
      !!estimateParameters ||
      (firstEstimate?.materials?.filter(m => !m?.isSupport)?.length || 0) > 1;

    const {
      estimatedMaterial,
      estimatedSupport,
      estimatedTime,
      estimateMaterialQuantity = MATERIAL_QUANTITY.VOLUME,
      supportMaterialQuantity = MATERIAL_QUANTITY.VOLUME,
    } = (shopTechnology &&
      shopMachine &&
      firstEstimate &&
      autoEstimateToCustomUnits({
        estimate: firstEstimate,
        technologyName: shopTechnology.appTechnology.name,
        machineDisplayName: shopMachine.appMachineType.displayName,
        materialUnit,
        supportUnit,
        timeUnit,
      })) ??
    {};

    const materialUnitLabel = getMaterialUnitLabel(t, materialUnit);
    const supportUnitLabel = getMaterialUnitLabel(t, supportUnit);

    // const { machineByMaterialId } = useShopTechnologies();
    // const shopMachine = machineByMaterialId(item.shopMaterialId);
    // const itemShopMaterial = allMaterials.find(material => material.id === item.shopMaterialId);

    let autoEstimationErrorMessage: string | null = null;
    /**
     * Here we only want to populate the errorMessage if there were errors in the first tray estimate
     * or if there was a top-level estimation error. For other subsequent tray estimations we do not
     * want to show an error message since we only care about the first tray. If there are no estimations
     * whatsoever we want to populate the errorMessage with the error from resultdata
     */
    const autoEstimationError = resultData?.error || firstEstimate?.error;
    if (autoEstimationError) {
      const messageKey = `auto_estimation.error.${autoEstimationError.name}`;
      autoEstimationErrorMessage = messageExists(messageKey)
        ? t(messageKey)
        : t("auto_estimation.error.unexpected");
    }

    if (!job || !jobRoute) {
      return (
        <div className="shop_route">
          <Loader active={true} size="small" />
        </div>
      );
    }

    // "Generate Estimates" button
    let disabledTooltip: string | undefined = undefined;
    if (!shopMaterial || isAnyMaterial(shopMaterial)) {
      // TODO: GC-99865: Remove prohibition against "Any", Check for luckily matching materials.
      disabledTooltip = t("shop.job.autoEstimates.disabledMaterial");
    }
    const machineHasAutoEstimateLicense =
      shopMachine?.appMachineType &&
      hasFeature(FeatureKey.AUTO_ESTIMATION, shopMachine.appMachineType.name);
    if (!machineHasAutoEstimateLicense) {
      disabledTooltip = t("order.items.autoEstimates.unlicensedMachine");
    }

    const machineHasAutoEstimateCapability = !!shopMachine?.appMachineType?.canEstimate;
    const canWriteJob = currentShop.permissions.includes(Permission.CREATE_JOBS);
    const autoEstimateButton =
      canWriteJob && !job.autoEstimationId && machineHasAutoEstimateCapability ? (
        <div className="meta-actions">
          <div
            data-tooltip={disabledTooltip}
            data-position="left center"
            style={{ display: "flex" }}
          >
            <Button
              size="small"
              secondary={true}
              onClick={() => generateAutoEstimates({ variables: { id: job.id } })}
              disabled={!!disabledTooltip}
            >
              {t("order.items.autoEstimates.generate")}
            </Button>
          </div>
        </div>
      ) : null;

    if (!stepsToggleOpen.length) {
      setStepsToggleOpen(jobRoute.shopRouteSteps.map(() => true));
    }

    orderItemQuantityByStep = getItemQuantitiesByStep(job, jobRoute);
    const summedQuantitiesByStep = getSummedQuantitiesByStep(orderItemQuantityByStep);
    const sortedItemQuantityByStep: ISortedItemQuantityByStep = orderItemQuantityByStep.map(step =>
      Object.keys(step)
        .sort((a, b) => {
          const itemA = job.orderItems.find(item => item.id === +a) as IOrderItem;
          const itemB = job.orderItems.find(item => item.id === +b) as IOrderItem;
          if (itemA.order && itemB.order && itemA.order.needByDate && itemB.order.needByDate) {
            return (
              new Date(itemB.order.needByDate).getTime() -
              new Date(itemA.order.needByDate).getTime()
            );
          }
          return 0;
        })
        .map(id => ({ id: +id, quantity: step[+id] }))
    );

    const checkedOrderItemIds: number[] = [];

    if (!checkedItemIdsByStep.length) {
      setCheckedItemIdsByStep(
        sortedItemQuantityByStep.map(step =>
          step.reduce((acc, { id }) => {
            acc[id] = false;
            return acc;
          }, {} as ICheckedItemIds)
        )
      );
    } else {
      orderItemQuantityByStep.forEach((items, stepIndex) =>
        Object.keys(items).forEach(itemId => {
          if (checkedItemIdsByStep[stepIndex][+itemId] && !checkedOrderItemIds.includes(+itemId)) {
            checkedOrderItemIds.push(+itemId);
          }
        })
      );
    }

    const moveItemsOrPrompt = async (itemQuantities: ItemQuantityToMove[]) => {
      if (itemQuantities.every(itemQuantity => itemQuantity.quantity === 1)) {
        // when single/multiple orderItems are moved to the last job step, show the modal to update their part statuses
        if (
          itemQuantities.every(
            itemQuantity =>
              itemQuantity.toStepId ===
              jobRoute.shopRouteSteps[jobRoute.shopRouteSteps.length - 1].id
          )
        ) {
          const idsToUpdateStatus = itemQuantities.map(item => item.id);
          setItemIdsToUpdateStatus(idsToUpdateStatus);
          setShowStatusUpdateModal(true);
        }

        await moveItemQuantities(client, itemQuantities);
      } else {
        setItemQuantitiesToMove(itemQuantities);
      }
    };

    const moveItemQuantities = async (
      apolloClient: ApolloClient<any>,
      quantities: ItemQuantityToMove[]
    ) => {
      setMovingItemsInFlight(true);
      setCheckedItemIdsByStep([]); // By resetting this it should repopulate with no rows checked on next render
      await createOrderItemJobRouteStepHistories(apolloClient, job.id, quantities);
      setItemQuantitiesToMove([]);
      setMovingItemsInFlight(false);
    };

    const stepIndexFromId = (id: number) => jobRoute.shopRouteSteps.findIndex(stp => stp.id === id);
    let updatePartStatusItemIds: number[] = [];

    const blurOnEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        (e.target as HTMLInputElement).blur();
      }
    };
    const MAX_LENGTH = 100;
    const jobNameTooltip =
      jobName && jobName.length > MAX_LENGTH
        ? t("shop.jobs.nameTooLong")
        : isEllipsisActive("qa-job-name")
        ? jobName
        : undefined;
    const estimationOutputs = job.autoEstimation?.autoEstimationAssets?.length
      ? job.autoEstimation.autoEstimationAssets.filter(
          aeAsset => aeAsset.asset.assetType === AssetType.AUTO_ESTIMATION
        )
      : undefined;

    const estimateDiv = (
      estimate: number | undefined,
      tooltipPosition: string,
      qaClassName: string,
      testId: string
    ) => (
      <div className={`estimate-value ${qaClassName}`} data-testid={testId}>
        {autoEstimationErrorMessage ? (
          <span data-tooltip={autoEstimationErrorMessage} data-position={tooltipPosition}>
            <Icon
              name="warning sign"
              size="small"
              color="red"
              className=""
              style={{ marginRight: 0 }}
            />
          </span>
        ) : (
          <div className="estimate">{estimate}</div>
        )}
        {inProgressAutoEstimationId && (
          <EstimatesLoader
            data-tooltip={t("auto_estimation.selectedInProgress")}
            data-position="left center"
          >
            <Loader active={true} size="tiny" />
          </EstimatesLoader>
        )}
      </div>
    );

    const estimationStatusSummary = (autoEstimation?: AutoEstimation | null) => {
      if (!autoEstimation?.resultData) {
        return <></>;
      }

      const gcpVersionObj = autoEstimation?.resultData?.gcpVersion;
      const gcpVersion = (gcpVersionObj && (
        <i className="qa-gcpVersion" data-testid="gcpVersion">
          {` (${t("auto_estimation.job.gcpVersion")} ${stringifyGCPVersion(gcpVersionObj)})`}
        </i>
      )) || <></>;

      return (
        <div>
          {!autoEstimationErrorMessage && <b>{t("auto_estimation.job.complete")}</b>}
          {gcpVersion}
        </div>
      );
    };

    return (
      <div style={{ paddingBottom: 20 }}>
        <CustomDragLayer
          checkedItemIdsByStep={checkedItemIdsByStep}
          orderItemQuantityByStep={orderItemQuantityByStep}
        />
        <Modal open={showAddPartsModal} onClose={() => setShowAddPartsModal(false)}>
          <AddPartsToJobForm
            job={job}
            onAddPartsSuccess={() => {
              setShowAddPartsModal(false);
            }}
          />
        </Modal>
        <JobEstimatesModal
          isOpen={showJobEstimatesModal}
          job={job}
          tab={JobEstimatesModalTab.buildReport}
          onClose={() => {
            setShowJobEstimatesModal(false);
          }}
          estimatedMaterial={estimatedMaterial}
          estimatedSupport={estimatedSupport}
          estimatedTime={estimatedTime}
          shopMaterial={shopMaterial}
          materialUnitLabel={materialUnitLabel}
          supportUnitLabel={supportUnitLabel}
          timeUnit={timeUnit}
          detailedProps={
            hasParameters
              ? {
                  parameters: estimateParameters!,
                  materialDisplayUnit: materialUnit,
                  estimateMaterialQuantity,
                  supportMaterialQuantity,
                  materials: firstEstimate?.materials,
                }
              : undefined
          }
        ></JobEstimatesModal>
        <StyledMovePartsModal
          className="qa-movePartsModal"
          closeIcon
          open={!!itemQuantitiesToMove.length}
          onClose={() => {
            if (itemQuantitiesToMove.length) {
              setItemQuantitiesToMove([]);
            }
          }}
          data-testid="movePartsModal"
        >
          <Header content={t("shop.job.movePartsModal.header")} />
          <Modal.Content>
            {itemQuantitiesToMove.map((itemQuantity, i) => {
              const itemToMove = job.orderItems.find(item => item.id === itemQuantity.id);
              if (!itemToMove) {
                return null;
              }

              const fromStepIndex =
                (itemQuantity.fromStepId && stepIndexFromId(itemQuantity.fromStepId)) || 0;
              const quantityInCurrentStep = orderItemQuantityByStep[fromStepIndex][itemQuantity.id];
              const toStepIndex = itemQuantity.toStepId && stepIndexFromId(itemQuantity.toStepId);
              const totalQuantityOfItem = itemToMove.quantity;
              const currentQuantityOfItemInLastStep =
                orderItemQuantityByStep[orderItemQuantityByStep.length - 1][itemToMove.id] || 0;

              // Show item status update option in the modal when moving the item to last step
              const shouldUpdateStatusInLastStep =
                toStepIndex &&
                toStepIndex === orderItemQuantityByStep.length - 1 &&
                currentQuantityOfItemInLastStep + quantityInCurrentStep === totalQuantityOfItem;

              if (shouldUpdateStatusInLastStep) {
                updatePartStatusItemIds.push(itemQuantity.id);
              }

              return (
                <div className="item-quantity-to-move" key={itemToMove.id}>
                  <div className="input-row">
                    <div className="item-name">{getCadModelName(itemToMove)}</div>
                    <LocalizedNumericInput
                      className="quantity-input"
                      value={itemQuantity.quantity}
                      min={0} // Prevents typing "-"
                      autoFocus
                      onChange={value => {
                        itemQuantitiesToMove[i].quantity = value;
                        setItemQuantitiesToMove([...itemQuantitiesToMove]);
                      }}
                    />
                  </div>
                  {(!!itemQuantity.quantity && itemQuantity.quantity > quantityInCurrentStep && (
                    <p className="error">{t("shop.job.movePartsModal.invalidQuantity")}</p>
                  )) ||
                    null}
                  {shouldUpdateStatusInLastStep ? (
                    <div>
                      <LabeledCheckbox
                        className="qa-update-parts-checkbox"
                        onClick={() => setUpdatePartStatusesChecked(!updatePartStatusesChecked)}
                      >
                        <Checkbox checked={updatePartStatusesChecked} />
                        <b>{t("shop.job.completeModal.updatePartStatus")}</b>
                      </LabeledCheckbox>
                      <StatusDropdown
                        statuses={currentShop.shopStatuses.filter(
                          status =>
                            !status.isHidden && !status.dateDeleted && getStatusName(status, t)
                        )}
                        selectedStatus={selectedStatus}
                        onSelect={setSelectedStatus}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="qa-movePartsModal-submit"
              onClick={async () => {
                await moveItemQuantities(
                  client,
                  itemQuantitiesToMove.filter(itemQuantity => !!itemQuantity.quantity)
                );
                if (updatePartStatusItemIds.length > 0 && updatePartStatusesChecked) {
                  const selectedOrderItems = job.orderItems.filter(item =>
                    updatePartStatusItemIds.includes(item.id)
                  );
                  const partsToUpdateStatus = selectedOrderItems.filter(
                    item => item.shopStatus !== selectedStatus.id
                  );
                  await updateOrderItemsStatus({
                    variables: {
                      ids: partsToUpdateStatus.map(part => part.id),
                      shopStatus: selectedStatus.id,
                    },
                  });
                }
              }}
              disabled={
                itemQuantitiesToMove.some(itemQuantity => {
                  const fromStepIndex =
                    (itemQuantity.fromStepId && stepIndexFromId(itemQuantity.fromStepId)) || 0;
                  const quantityInCurrentStep =
                    orderItemQuantityByStep[fromStepIndex][itemQuantity.id];
                  return itemQuantity.quantity && itemQuantity.quantity > quantityInCurrentStep;
                }) || itemQuantitiesToMove.every(itemQuantity => !itemQuantity.quantity)
              }
            >
              {t("shop.job.movePartsModal.move")}
            </Button>
          </Modal.Actions>
        </StyledMovePartsModal>
        <CompleteJobModal
          job={job}
          jobRoute={jobRoute}
          open={showCompleteJobModal}
          onClose={() => setShowCompleteJobModal(false)}
          doUpdateJobCompleted={doUpdateJobCompleted}
        />
        <UpdateStatusModal
          open={showStatusUpdateModal}
          onClose={() => setShowStatusUpdateModal(false)}
          itemIdsToUpdateStatus={itemIdsToUpdateStatus}
          job={job}
        />
        <DeleteEmptyJobModal
          open={showDeleteEmptyJobModal}
          onClose={() => setShowDeleteEmptyJobModal(false)}
          jobToDelete={job}
          client={client}
          history={history}
        />
        <StyledJobDetails>
          <div className="header-row">
            <Header as="h2" className="page-header">
              {job.name}
            </Header>

            {!job.dateCompleted ? (
              <UiCan do={Permission.CREATE_JOBS} on={currentShop}>
                <Button
                  className="qa-completeJobButton"
                  primary
                  content={t("shop.job.complete")}
                  onClick={() => setShowCompleteJobModal(true)}
                />
              </UiCan>
            ) : (
              <div className="completed-on qa-completedOn">
                {t("shop.job.completedOn")} {formatDate(job.dateCompleted)}
              </div>
            )}
          </div>
          <div className="qa-job-details round-and-shadow" data-testid="jobDetails">
            <div className="job-details">
              <div className="job-name">
                <span data-tooltip={jobNameTooltip} data-position="right center">
                  <Input
                    className={classNames({ error: jobName && jobName.length > MAX_LENGTH })}
                    id="qa-job-name"
                    disabled={!currentShop.permissions.includes(Permission.CREATE_JOBS)}
                    name="jobName"
                    value={jobName === undefined ? job.name : jobName}
                    onChange={event => setJobName(event.currentTarget.value)}
                    onKeyPress={blurOnEnter}
                    onBlur={async () => {
                      if (jobName && jobName.length > 0) {
                        setJobName(jobName?.slice(0, MAX_LENGTH));
                        await updateJobName({
                          variables: { input: { id: job.id, name: jobName.slice(0, MAX_LENGTH) } },
                        });
                      }
                    }}
                  />
                </span>
              </div>
              <UserSelector
                canSet={false}
                placeholder={"Job Operator"}
                noResultsCopy={""}
                assignCopy={""}
                onChange={() => {}}
                id={job.user?.email}
                tooltipPosition={"left center"}
                users={[
                  {
                    id: job.user?.email || "",
                    name: job.user?.name || "",
                  },
                ]}
              />
            </div>
            <JobRoute jobRoute={jobRoute} summedQuantitiesByStep={summedQuantitiesByStep} />
            <div className="job-meta">
              <div className="meta-col">
                <div className="meta-cell">
                  {/* TODO: technology specific technology icons? */}
                  <Image src={technologyIcon} className="qa-image" />
                  <label>{t("shop.job.tech")}</label>
                  <span className="label-value">
                    {shopTechnology?.appTechnology.displayName || t("shop.job.material.any")}{" "}
                  </span>
                </div>
                {job.autoEstimation && (
                  <div className="meta-cell">
                    <Image src={materialEstimate} />
                    <label>{t("order.items.material.estimate")}</label>
                    <StyledLabeledInput data-label={materialUnitLabel}>
                      {estimateDiv(
                        estimatedMaterial,
                        "bottom left",
                        "qa-material-estimate",
                        "materialEstimate"
                      )}
                    </StyledLabeledInput>
                  </div>
                )}
              </div>
              <div className="meta-col">
                <div className="meta-cell">
                  {/* TODO: technology specific machine and material icons? */}
                  <Image src={machineIcon} className="qa-image" />
                  <label>{t("shop.job.machine")}</label>
                  <span className="label-value qa-machine">
                    {shopMachine?.appMachineType.displayName || t("shop.job.material.any")}
                  </span>
                </div>
                {job.autoEstimation && (
                  <div className="meta-cell">
                    <Image src={materialEstimate} />
                    <label>{t("shop.job.support.estimate")}</label>
                    <StyledLabeledInput data-label={supportUnitLabel}>
                      {estimateDiv(
                        estimatedSupport,
                        "bottom center",
                        "qa-support-estimate",
                        "supportEstimate"
                      )}
                    </StyledLabeledInput>
                  </div>
                )}
              </div>
              <div className="meta-col">
                <div className="meta-cell">
                  <Image src={materialIcon} className="qa-image" />
                  <label>{t("shop.job.material")}</label>
                  <span className="label-value qa-material">
                    {shopMaterial?.appMaterial.displayName || t("shop.job.material.any")}
                  </span>
                </div>
                {job.autoEstimation && (
                  <div className="meta-cell">
                    <Image src={timeEstimate} />
                    <label>{t("order.items.time.estimate")}</label>
                    <StyledLabeledInput
                      data-label={t(`order.items.rates.units.${timeUnit.toLowerCase()}`)}
                    >
                      {estimateDiv(
                        estimatedTime,
                        "bottom center",
                        "qa-time-estimate",
                        "timeEstimate"
                      )}
                    </StyledLabeledInput>
                  </div>
                )}
              </div>
              {job.autoEstimation?.resultData && !autoEstimationError && (
                <div className="meta-actions">
                  <Button
                    className="qa-viewJobEstimateButton"
                    secondary
                    disabled={false}
                    onClick={() => {
                      setShowJobEstimatesModal(true);
                    }}
                  >
                    {t("shop.job.jobEstimatesModal.button")}
                  </Button>
                </div>
              )}
              {autoEstimateButton}
            </div>
            <JobEstimationInfo>
              {job.autoEstimation?.queuePosition != null && (
                <div>
                  <b>
                    {t("auto_estimation.header.inQueue", {
                      position: job.autoEstimation?.queuePosition,
                    })}
                  </b>
                </div>
              )}
              {inProgressAutoEstimationId && !job.autoEstimation?.queuePosition && (
                <div>
                  <b>{t("auto_estimation.job.in_progress")}</b>
                </div>
              )}
              {estimationStatusSummary(job.autoEstimation)}
              {job.autoEstimation && (
                <div className="qa-estimatedWith">
                  <div className="estimate-assets">
                    {estimationOutputs ? (
                      <DownloadAutoEstimationAssetButton
                        assets={estimationOutputs.map(asset => ({
                          id: asset.id,
                          name: asset.asset.originalName,
                          assetType: "auto_estimation",
                        }))}
                      ></DownloadAutoEstimationAssetButton>
                    ) : inProgressAutoEstimationId ? (
                      <div style={{ fontStyle: "italic" }}>
                        <Loader inline active size="tiny" /> {t("shop.job.calculating.estimate")}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </JobEstimationInfo>
            <EstimationProgressBar
              inProgress={!!inProgressAutoEstimationId}
              progress={job.autoEstimation?.progress || null}
            />
          </div>
        </StyledJobDetails>
        <StyledJobActions>
          <div className="part-actions">
            <ToPrintButton
              selectedItems={{ OrderItem: checkedOrderItemIds, Image: [], GenericFile: [] }}
              jobId={job.id}
              orderItems={job.orderItems.filter(item => checkedOrderItemIds.includes(item.id))}
              quantitiesById={getCheckedItemIdsWithQtyInJobSteps(
                orderItemQuantityByStep,
                checkedItemIdsByStep
              )}
            />
            <MultiFileDownloadButton
              items={job.orderItems.filter(item => checkedOrderItemIds.includes(item.id))}
            />
            <RemoveOrderItemsFromJobButton
              selectedOrderItemIds={checkedOrderItemIds}
              job={job}
              onSubmit={(nRemaining: number | undefined) => {
                setCheckedItemIdsByStep([]);
                if (nRemaining === 0) {
                  setShowDeleteEmptyJobModal(true);
                }
              }}
            />
            <SplitOrderItemButton
              selectedOrderItemIds={checkedOrderItemIds}
              orderItemQuantityByStep={orderItemQuantityByStep}
              job={job}
              onSubmit={() => {
                setCheckedItemIdsByStep([]);
                // refetch the job
                jobDetailsQuery.refetch();
              }}
            />
          </div>
          <UiCan do={Permission.CREATE_JOBS} on={currentShop}>
            <Button onClick={() => setShowAddPartsModal(true)} secondary className="qa-addPartsBtn">
              {t("shop.job.addMoreParts")}
            </Button>
          </UiCan>
        </StyledJobActions>

        {jobRoute.shopRouteSteps.map((step, stepIndex) => (
          <JobRouteStepParts
            key={step.id}
            job={job}
            jobRoute={jobRoute}
            stepIndex={stepIndex}
            orderItemQuantityByStep={orderItemQuantityByStep}
            sortedItemQuantityByStep={sortedItemQuantityByStep}
            moveItemsOrPrompt={moveItemsOrPrompt}
            movingItemsInFlight={movingItemsInFlight}
            checkedItemIdsByStep={checkedItemIdsByStep}
            setCheckedItemIdsByStep={setCheckedItemIdsByStep}
            onClickRow={(itemIndex: number) => {
              const tempCheckedItemIdsByStep = [...checkedItemIdsByStep];
              if (
                shiftPressed &&
                lastSelectedStepIndex !== undefined &&
                lastSelectedItemIndex !== undefined
              ) {
                if (lastSelectedStepIndex === stepIndex) {
                  // Multi-select within one step
                  const startItemIndex = Math.min(lastSelectedItemIndex, itemIndex);
                  const endItemIndex = Math.max(lastSelectedItemIndex, itemIndex);
                  sortedItemQuantityByStep[stepIndex].forEach(({ id }, i) => {
                    tempCheckedItemIdsByStep[stepIndex][id] =
                      (i >= startItemIndex && i <= endItemIndex) ||
                      tempCheckedItemIdsByStep[stepIndex][id];
                  });
                } else {
                  // Multi-select across steps
                  let startStepIndex = lastSelectedStepIndex;
                  let endStepIndex = stepIndex;
                  let startItemIndex = lastSelectedItemIndex;
                  let endItemIndex = itemIndex;
                  if (lastSelectedStepIndex > stepIndex) {
                    // Click prior to shift-click was on a lower step
                    startStepIndex = stepIndex;
                    endStepIndex = lastSelectedStepIndex;
                    startItemIndex = itemIndex;
                    endItemIndex = lastSelectedItemIndex;
                  }
                  for (let i = startStepIndex; i <= endItemIndex; i = i + 1) {
                    if (i === startStepIndex) {
                      tempCheckedItemIdsByStep[i] = sortedItemQuantityByStep[i].reduce(
                        (acc, { id }, j) => {
                          acc[id] = j >= startItemIndex || tempCheckedItemIdsByStep[i][id];
                          return acc;
                        },
                        {} as ICheckedItemIds
                      );
                    } else if (i < endStepIndex) {
                      tempCheckedItemIdsByStep[i] = sortedItemQuantityByStep[i].reduce(
                        (acc, { id }) => {
                          acc[id] = true;
                          return acc;
                        },
                        {} as ICheckedItemIds
                      );
                    } else if (i === endStepIndex) {
                      tempCheckedItemIdsByStep[i] = sortedItemQuantityByStep[i].reduce(
                        (acc, { id }, j) => {
                          acc[id] = j <= endItemIndex || tempCheckedItemIdsByStep[i][id];
                          return acc;
                        },
                        {} as ICheckedItemIds
                      );
                    }
                  }
                }
              } else {
                // Single select/deselect
                const itemId = sortedItemQuantityByStep[stepIndex][itemIndex].id;
                tempCheckedItemIdsByStep[stepIndex][itemId] =
                  !tempCheckedItemIdsByStep[stepIndex][itemId];

                if (tempCheckedItemIdsByStep[stepIndex][itemId]) {
                  setLastSelectedStepIndex(stepIndex);
                  setLastSelectedItemIndex(itemIndex);
                } else {
                  setLastSelectedStepIndex(undefined);
                  setLastSelectedItemIndex(undefined);
                }
              }

              setCheckedItemIdsByStep(tempCheckedItemIdsByStep);
            }}
          />
        ))}
      </div>
    );
  }
);
ScreensShopJob.displayName = "ScreensShopJob";

const JobRouteStepParts = ({
  job,
  jobRoute,
  stepIndex,
  orderItemQuantityByStep,
  sortedItemQuantityByStep,
  checkedItemIdsByStep,
  setCheckedItemIdsByStep,
  onClickRow,
  moveItemsOrPrompt,
  movingItemsInFlight,
}: {
  job: IShopJob;
  jobRoute: IShopRoute;
  stepIndex: number;
  orderItemQuantityByStep: IOrderItemQuantityByStep[];
  sortedItemQuantityByStep: ISortedItemQuantityByStep;
  checkedItemIdsByStep: ICheckedItemIds[];
  setCheckedItemIdsByStep: React.Dispatch<React.SetStateAction<ICheckedItemIds[]>>;
  onClickRow: (index: number) => void;
  moveItemsOrPrompt: (itemQuantities: ItemQuantityToMove[]) => void;
  movingItemsInFlight: boolean;
}) => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const [stepExpanded, setStepExpanded] = useState(true);
  const [stepIdToMoveto, setStepIdToMoveTo] = useState(
    stepIndex < jobRoute.shopRouteSteps.length - 1
      ? jobRoute.shopRouteSteps[stepIndex + 1].id
      : undefined
  );

  const partQuantityInStep = sortedItemQuantityByStep[stepIndex].reduce(
    (acc, cur) => acc + cur.quantity,
    0
  );

  const targetStepRef = useRef<HTMLTableRowElement>(null);
  const [{ isOver }, drop] = useDrop<
    { type: string; id: number; stepIndexFrom: number },
    unknown,
    { isOver: boolean }
  >({
    accept: ITEM_ROW,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    drop: ({ id, stepIndexFrom }) => {
      if (stepIndexFrom === stepIndex) {
        return;
      } // No-op
      const itemsToMove: ItemQuantityToMove[] = [];
      sortedItemQuantityByStep[stepIndexFrom].forEach(item => {
        if (checkedItemIdsByStep[stepIndexFrom][item.id] || item.id === id) {
          itemsToMove.push({
            id: item.id,
            quantity: item.quantity,
            fromStepId: jobRoute.shopRouteSteps[stepIndexFrom].id,
            toStepId: jobRoute.shopRouteSteps[stepIndex].id,
          });
        }
      });
      moveItemsOrPrompt(itemsToMove);
    },
  });
  drop(targetStepRef);

  const noPartsSelected = !Object.keys(checkedItemIdsByStep[stepIndex]).some(
    id => checkedItemIdsByStep[stepIndex][+id]
  );

  const canMoveParts = currentShop.permissions.includes(Permission.CREATE_JOBS);

  return (
    <StyledJobRouteStepParts
      ref={targetStepRef}
      className={classNames("qa-job-step-parts round-and-shadow", { "drag-is-over": isOver })}
      data-testid="jobRouteStepParts"
    >
      <header>
        <div
          className="step-title"
          onClick={() => {
            if (partQuantityInStep) {
              setStepExpanded(!stepExpanded);
            }
          }}
        >
          <Icon
            name={
              stepExpanded && partQuantityInStep > 0
                ? "chevron circle down"
                : "chevron circle right"
            }
            color={"grey"}
            size={"large"}
            disabled={partQuantityInStep === 0}
            className="step-toggle"
          />
          <div className="step-icon">
            <img
              src={
                stepIcons[
                  jobRoute.shopRouteSteps[stepIndex].appRouteStep.iconFilename.split(".")[0]
                ]
              }
            />
          </div>
          <b>
            {t(
              `shop.job.step.${jobRoute.shopRouteSteps[stepIndex].appRouteStep.translationKey}`
            ).toLocaleUpperCase()}
          </b>

          <div className="parts-count">
            {t("shop.job.step.numParts", { num: partQuantityInStep })}
          </div>
        </div>

        {!!partQuantityInStep && (
          <div className="move-parts-dropdown">
            {t("shop.job.movePartsTo")}
            <Dropdown
              style={{ zIndex: 51 }} // Must go above the tooltip but under the CustomDragLayer
              className="qa-jobRouteStepDropdown"
              selection
              value={stepIdToMoveto}
              placeholder={t("shop.job.movePartsTo.placeholder")}
              onChange={(_evt, { value }) => value && setStepIdToMoveTo(+value)}
              options={jobRoute.shopRouteSteps.map(step => ({
                key: step.id,
                value: step.id,
                text: t(`shop.job.step.${step.appRouteStep.translationKey}`),
              }))}
              disabled={!canMoveParts}
            />
            <div
              data-tooltip={
                noPartsSelected && canMoveParts ? t("shop.job.movePartsTo.disabled") : undefined
              }
              data-position="left center"
              style={{ display: "flex" }}
            >
              <Button
                className="qa-movePartsButton"
                secondary
                size="mini"
                disabled={!canMoveParts || noPartsSelected || !stepIdToMoveto}
                onClick={() => {
                  const itemsToMove: ItemQuantityToMove[] = [];
                  sortedItemQuantityByStep[stepIndex].forEach(item => {
                    if (checkedItemIdsByStep[stepIndex][item.id]) {
                      itemsToMove.push({
                        id: item.id,
                        quantity: item.quantity,
                        fromStepId: jobRoute.shopRouteSteps[stepIndex].id,
                        toStepId: stepIdToMoveto,
                      });
                    }
                  });
                  moveItemsOrPrompt(itemsToMove);
                }}
              >
                {t("shop.job.move")}
              </Button>
            </div>
          </div>
        )}
      </header>

      {!!partQuantityInStep && stepExpanded && (
        <StyledJobRouteStepPartsTable className="unselectable">
          <thead>
            <tr>
              <th className="checkbox-and-arrows">
                <Checkbox // Checkbox for all items in a step
                  checked={sortedItemQuantityByStep[stepIndex].every(
                    item => checkedItemIdsByStep[stepIndex]?.[item.id]
                  )}
                  onClick={() => {
                    const allChecked = sortedItemQuantityByStep[stepIndex].every(
                      item => checkedItemIdsByStep[stepIndex]?.[item.id]
                    );
                    sortedItemQuantityByStep[stepIndex].forEach(item => {
                      checkedItemIdsByStep[stepIndex][item.id] = !allChecked;
                    });
                    setCheckedItemIdsByStep([...checkedItemIdsByStep]);
                  }}
                  className={"qa-step-checkbox"}
                />
              </th>
              <th className="thumb"></th>
              <th className="name">{t("shop.job.partTable.partName")}</th>
              <th className="material">{t("shop.job.partTable.material")}</th>
              <th className="quantity">{t("shop.job.partTable.qty")}</th>
              <th className="units">{t("shop.job.partTable.units")}</th>
              <th className="need-by-date">{t("shop.job.partTable.needByDate")}</th>
              <th className="status">{t("shop.job.partTable.status")}</th>
              <th className="order">{t("shop.job.partTable.order")}</th>
            </tr>
          </thead>
          <tbody>
            {sortedItemQuantityByStep[stepIndex].map(({ id, quantity }, itemIndex) => {
              const item = job.orderItems.find(it => it.id === +id);
              if (!item || quantity === 0) {
                return null;
              }

              let estimationError = getEstimationError(job?.autoEstimation?.resultData, item);

              const getErrorMessage = (
                error: AutoEstimationError | undefined
              ): string | undefined => {
                switch (error?.errorName) {
                  case AutoEstimationErrorType.MODEL_OUTSIDE_TRAY:
                    return t(`auto_estimation.error.${AutoEstimationErrorType.MODEL_OUTSIDE_TRAY}`);
                  case AutoEstimationErrorType.MODEL_QUANTITY_OVERFLOW:
                    const canSplit = canSplitOrderItem(item, orderItemQuantityByStep, error!);
                    return t(
                      canSplit
                        ? `auto_estimation.error.ModelQuantityOverflowCanSplit`
                        : `auto_estimation.error.ModelQuantityOverflowCannotSplit`,
                      { quantity: error.data?.quantity }
                    );
                  default:
                    return undefined;
                }
              };

              return (
                <ItemQuantityRow
                  key={id}
                  item={item}
                  quantity={quantity}
                  stepIndex={stepIndex}
                  jobRoute={jobRoute}
                  checked={checkedItemIdsByStep[stepIndex]?.[id]}
                  highlighted={estimationError != null}
                  tooltipText={getErrorMessage(estimationError)}
                  tooltipPosition="top center"
                  onClick={() => onClickRow(itemIndex)}
                  movingItemsInFlight={movingItemsInFlight}
                  moveToIndex={index => {
                    moveItemsOrPrompt([
                      {
                        id,
                        quantity,
                        fromStepId: jobRoute.shopRouteSteps[stepIndex].id,
                        toStepId: jobRoute.shopRouteSteps[index].id,
                      },
                    ]);
                  }}
                />
              );
            })}
          </tbody>
        </StyledJobRouteStepPartsTable>
      )}
    </StyledJobRouteStepParts>
  );
};

interface ItemQuantityRowProps {
  item: IOrderItem;
  quantity: number;
  stepIndex?: number;
  jobRoute?: IShopRoute;
  checked: boolean;
  highlighted?: boolean;
  tooltipText?: string;
  tooltipPosition?: string;
  onClick?: () => void;
  movingItemsInFlight?: boolean;
  moveToIndex?: (index: number) => void;
}

const ItemQuantityRowCmp = ({
  item,
  quantity,
  stepIndex,
  jobRoute,
  checked,
  highlighted,
  tooltipText,
  tooltipPosition,
  onClick,
  movingItemsInFlight,
  moveToIndex,
}: ItemQuantityRowProps) => {
  const { currentShop, formatDate, t } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { allMaterials } = useShopTechnologies();
  const canMoveJobParts = currentShop.permissions.includes(Permission.CREATE_JOBS);

  const dragRowRef = useRef<HTMLTableRowElement>(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM_ROW,
    item: { id: item.id, stepIndexFrom: stepIndex, item, quantity, checked },
    canDrag: canMoveJobParts,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [partExpanded, setPartExpanded] = useState(false);
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  drag(dragRowRef);
  const material = allMaterials.find(mat => mat.id === item.shopMaterialId);

  return (
    <>
      <tr
        ref={dragRowRef}
        onClick={onClick}
        className={classNames("qa-part-row", {
          selected: checked,
          "is-dragging": isDragging,
          "non-estimated-model": highlighted,
        })}
        data-tooltip={isDragging ? undefined : tooltipText}
        data-position={tooltipPosition}
      >
        <td className="checkbox-and-arrows">
          <Checkbox checked={checked} className={"qa-orderItemRow-checkbox"} />
          <div
            className="arrow-buttons"
            onClick={e => e.stopPropagation()} // Prevent selection/deselection when arrow is disabled
          >
            <Button
              icon={"angle double up"}
              className="borderless mini qa-prevStepBtn"
              disabled={!canMoveJobParts || stepIndex === 0 || movingItemsInFlight}
              onClick={e => {
                moveToIndex && stepIndex !== undefined && moveToIndex(stepIndex - 1);
                e.stopPropagation(); // Prevent selection/deselection
              }}
            />
            <Button
              icon={"angle double down"}
              className="borderless mini qa-nextStepBtn"
              disabled={
                !canMoveJobParts ||
                !jobRoute ||
                stepIndex === jobRoute.shopRouteSteps.length - 1 ||
                movingItemsInFlight
              }
              onClick={e => {
                moveToIndex && stepIndex !== undefined && moveToIndex(stepIndex + 1);
                e.stopPropagation(); // Prevent selection/deselection
              }}
            />
          </div>
          <div
            className="part-details-toggle qa-partDetails"
            onClick={e => {
              setPartExpanded(!partExpanded);
              e.stopPropagation(); // Prevent the checkbox selection
            }}
            data-testid="partDetailsToggleIcon"
          >
            <Icon
              name={partExpanded ? "caret down" : "caret right"}
              color={"black"}
              size={"large"}
            />
          </div>
        </td>
        <td className="thumb">
          <StyledSmallThumbnail>
            <CadModelPreview itemId={item.id} size={"small"} />
          </StyledSmallThumbnail>
        </td>
        <td className="name">
          <div>
            <div>{getCadModelName(item)}</div>
            <Avatar fullName name={item.order?.user?.name} id={item.order?.user?.email} />
          </div>
        </td>
        <td className="material">{material?.appMaterial.displayName}</td>
        <td className="quantity">{quantity + "/" + item.quantity}</td>
        <td className="units">
          {getTranslatedItemUnits(t, item) || <i>{t("shop.job.partTable.noUnits")}</i>}
        </td>
        <td className="need-by-date">{formatDate(item.order?.needByDate)}</td>
        <td className="status">
          <OrderStatus
            entity={item}
            upward={
              jobRoute &&
              stepIndex !== undefined &&
              stepIndex >= Math.floor(jobRoute.shopRouteSteps.length / 2)
            }
          />
        </td>
        <td className="order">
          <Link to={ROUTES.SHOP(currentShop.id).ORDER.SHOW(item.order?.id)}>
            {item.order?.name}
          </Link>
        </td>
      </tr>
      {partExpanded && (
        <tr className="order-part-details" data-testid="orderPartDetails">
          <OrderItemDetails item={item} onClose={() => setPartExpanded(false)} />
        </tr>
      )}
    </>
  );
};

const ItemQuantityRow = React.memo(ItemQuantityRowCmp);

const StyledJobRoute = styled.div`
  .step-icons,
  .route-track,
  .part-counts {
    display: flex;
    justify-content: space-between;
  }
  .route-step-progress-bar {
    position: absolute;
    margin: 10px 21px;
    height: 7px;
    background: #003393;
  }
  .step-icons {
    padding: 0px 2px 12px;
    height: 24px;
    .icon {
      width: 22px;
      position: relative;
      display: flex;
      align-items: center;
      img {
        margin: 0 auto;
      }
    }
  }
  .route-track {
    background: #e1e7f0;
    height: 26px;
    border-radius: 13px;
    .step {
      margin: 4px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: white;
      border: 2px solid #003393;
      display: flex;
      align-items: center;
      justify-content: center;
      .step_progress {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        border: 1px solid #003393;
      }
    }
  }
  .part-counts {
    margin: 8px 13px;
    .step {
      flex: 0px 1 1;
      text-align: center;
      &:first-child {
        flex-grow: 0.5;
        text-align: left;
        position: relative;
        left: -13px;
      }
      &:last-child {
        flex-grow: 0.5;
        text-align: right;
        position: relative;
        right: -13px;
      }
    }
  }
`;

const JobRoute = ({
  jobRoute,
  summedQuantitiesByStep,
}: {
  jobRoute: IShopRoute;
  summedQuantitiesByStep: number[];
}) => {
  if (!jobRoute) {
    return null;
  }
  const totalQuantity = summedQuantitiesByStep.reduce((acc, cur) => acc + cur, 0);
  const cumulativeQuantities = summedQuantitiesByStep.map((quantity, stepIndex) => {
    let cumulativeQuantity = quantity;
    for (let i = stepIndex + 1; i < summedQuantitiesByStep.length; i++) {
      cumulativeQuantity += summedQuantitiesByStep[i];
    }
    return cumulativeQuantity;
  });
  return (
    <StyledJobRoute>
      <div className="shop_route" data-testid="shopJobRoute">
        <div className="step-icons">
          {jobRoute.shopRouteSteps.map((step, i) => (
            <div className="icon" key={`step${i}`}>
              <Image src={stepIcons[step.appRouteStep.iconFilename.split(".")[0]]} />
            </div>
          ))}
        </div>

        <div className="route-track">
          {jobRoute.shopRouteSteps.map((step, i) => {
            const ratio = cumulativeQuantities[i] / totalQuantity;
            const stepProgressPercentage = (cumulativeQuantities[i + 1] / totalQuantity) * 100;
            return (
              <div key={`step${i}-${stepProgressPercentage}`}>
                {i < jobRoute.shopRouteSteps.length - 1 ? (
                  <div
                    className="route-step-progress-bar"
                    style={{
                      width: `calc(${100 / (jobRoute.shopRouteSteps.length - 1)}% - 22px`,
                      opacity: `${
                        stepProgressPercentage === 100 ? 1 : stepProgressPercentage > 1 ? 0.4 : 0
                      }`,
                    }}
                  />
                ) : null}
                <div
                  className="step route-step-progress"
                  key={`step${i}`}
                  data-testid="routeTrackStep"
                >
                  <div
                    className="step_progress"
                    style={{
                      background: `conic-gradient(#003393 ${ratio}turn, #fff ${ratio}turn)`,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="part-counts">
          {jobRoute.shopRouteSteps.map((step, i) => (
            <div className="step" key={`step${i}`} data-testid="partCountStep">
              {summedQuantitiesByStep[i]}
              {"/"}
              {totalQuantity}
            </div>
          ))}
        </div>
      </div>
    </StyledJobRoute>
  );
};

const RemoveOrderItemsFromJobButton = ({
  job,
  selectedOrderItemIds,
  onSubmit,
}: {
  job: IShopJob;
  selectedOrderItemIds: number[];
  onSubmit: (nRemaining: number | undefined) => void;
}): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const [showDeleteModal, setShowDeleteModal]: [boolean, (b: boolean) => void] = useState(
    false as boolean
  );
  const client = useApolloClient();
  const canDeleteParts = currentShop
    ? currentShop.permissions.includes(Permission.CREATE_JOBS)
    : false;
  return (
    <>
      <ConfirmationModal
        headerIcon={"trash"}
        headerCopy={t("shop.job.removeParts.header", { num: selectedOrderItemIds.length })}
        bodyCopy={t("shop.job.removeParts.copy")}
        cancelTranslationKey={"general.cancel"}
        confirmTranslationKey={"general.remove"}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        submitAction={() =>
          client.mutate({
            mutation: REMOVE_ORDER_ITEMS_FROM_JOB,
            variables: {
              input: {
                jobId: job.id,
                orderItemIds: selectedOrderItemIds,
              },
            },
            refetchQueries: job.autoEstimation
              ? [{ query: JOB_DETAILS, variables: { id: job.id } }]
              : [],
            update: () => {
              const nRemaining = removeOrderItemsFromCachedJob(
                client,
                job.id,
                selectedOrderItemIds
              );
              onSubmit(nRemaining);
            },
          })
        }
      />
      <Button
        icon={"trash"}
        disabled={!canDeleteParts || !selectedOrderItemIds.length}
        secondary
        circular
        className={"borderless qa-deleteOrderItems-button trash"}
        onClick={e => {
          e.preventDefault();
          setShowDeleteModal(true);
        }}
      />
    </>
  );
};

// Only allow splitting when all the quantities of the order item are at the first route step
// Otherwise splitting the OrderItem physically when the quantities are already logically split
// by a potentially different amount by route step histories makes my head hurt
const canSplitOrderItem = (
  orderItem: IOrderItem,
  orderItemQuantityByStep: IOrderItemQuantityByStep[],
  autoEstimationError: AutoEstimationError
) => {
  const quantityAtFirstStep = orderItemQuantityByStep[0][orderItem.id];
  return (
    quantityAtFirstStep === orderItem.quantity &&
    autoEstimationError.data!.quantity < orderItem.quantity
  );
};

const SplitOrderItemButton = ({
  job,
  selectedOrderItemIds,
  orderItemQuantityByStep,
  onSubmit,
}: {
  job: IShopJob;
  selectedOrderItemIds: number[];
  orderItemQuantityByStep: IOrderItemQuantityByStep[];
  onSubmit: () => void;
}): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const [showSplitModal, setShowSplitModal]: [boolean, (b: boolean) => void] = useState(
    false as boolean
  );
  const client = useApolloClient();
  // Find those selected order items with overflows (if any)
  const orderItemIds = new Array<number>();
  const nToSplitOff = new Array<number>();
  const refetchQueries: Array<{ query: DocumentNode; variables: any }> = [
    { query: JOB_DETAILS, variables: { id: job.id } },
  ];
  for (const orderItemId of selectedOrderItemIds) {
    const orderItem = job.orderItems.find(o => o.id === orderItemId);
    const autoEstimationError = getEstimationError(job.autoEstimation?.resultData, orderItem);

    if (
      autoEstimationError?.errorName === "ModelQuantityOverflow" &&
      canSplitOrderItem(orderItem!, orderItemQuantityByStep, autoEstimationError)
    ) {
      orderItemIds.push(orderItemId);
      nToSplitOff.push(autoEstimationError.data!.quantity);
      const orderId = orderItem?.order?.id ?? null;
      if (orderId) {
        refetchQueries.push({ query: ORDER_DETAILS, variables: { orderId: orderId } });
      }
    }
  }
  const canSplitOrders =
    currentShop?.permissions.includes(Permission.CREATE_ORDER) &&
    orderItemIds.length === selectedOrderItemIds.length;
  return (
    <>
      <ConfirmationModal
        headerIcon={"cut"}
        headerCopy={t("shop.job.splitOrderItem.header", { num: selectedOrderItemIds.length })}
        bodyCopy={t("shop.job.splitOrderItem.copy")}
        cancelTranslationKey={"general.cancel"}
        confirmTranslationKey={"shop.job.splitOrderItem.confirm"}
        open={showSplitModal}
        onClose={() => setShowSplitModal(false)}
        submitAction={async () => {
          try {
            await client.mutate({
              mutation: SPLIT_ORDER_ITEMS,
              variables: {
                input: {
                  orderItemIds,
                  nToSplitOff,
                },
              },
              refetchQueries,
              update: () => {
                onSubmit();
              },
            });
            Notifier.success(t("shop.job.splitOrderItem.success"));
          } catch (error) {
            Notifier.error(t("shop.job.splitOrderItem.error"));
          }
        }}
      />
      <Popup
        trigger={
          <Button
            icon={"cut"}
            disabled={!canSplitOrders || !selectedOrderItemIds.length}
            secondary
            circular
            className={"borderless qa-splitOrderItems-button trash"}
            onClick={e => {
              e.preventDefault();
              setShowSplitModal(true);
            }}
          />
        }
        content={t("shop.job.splitOrderItem.buttonTooltip")}
        position="bottom center"
        inverted
      />
    </>
  );
};

// TODO: add to grabcad-ui-components?
export const LabeledCheckbox = styled.div`
  margin: 10px 0;
  display: flex;
  .checkbox {
    margin-right: 5px;
  }
  label {
    cursor: pointer;
  }
`;

export const StyledCompleteJobModal = styled(StyledModal)`
  .quantity-in-step {
    margin: 3px 0;
    .step-name {
      background: #ccc;
      border-radius: 3px;
      padding: 2px 5px;
    }
  }
`;

const CompleteJobModal = ({
  job,
  jobRoute,
  open,
  onClose,
  doUpdateJobCompleted,
}: {
  job: IShopJob;
  jobRoute: IShopRoute;
  open: boolean;
  onClose: () => void;
  doUpdateJobCompleted: (isCompleted: boolean) => void;
}): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const [updatePartStatusesChecked, setUpdatePartStatusesChecked] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(
    currentShop.shopStatuses.find(status => status.appStatus === "COMPLETED") as IShopStatus
  );

  const [updatOrderItemsStatus] = useMutation<any, any>(UPDATE_ORDER_ITEMS_STATUS);
  const client = useApolloClient();

  const orderItemQuantityByStep = getItemQuantitiesByStep(job, jobRoute);
  const summedQuantitiesByStep = getSummedQuantitiesByStep(orderItemQuantityByStep);

  return (
    <StyledCompleteJobModal
      closeIcon
      basic={false}
      open={open}
      onClose={onClose}
      data-testid="CompleteJobModal"
    >
      <Header content={t("shop.job.complete")} />
      <Modal.Content>
        <h4>
          {t("shop.job.complete")}
          {": "}
          {job.name}
        </h4>
        {jobRoute.shopRouteSteps.map((step, i) => {
          if (summedQuantitiesByStep[i]) {
            return (
              <div className="quantity-in-step" key={i}>
                <b>{summedQuantitiesByStep[i]}</b>
                {t("shop.job.completeModal.partsInStep", { num: "", step: "" })}
                <span className="step-name">
                  {t(`shop.job.step.${step.appRouteStep.translationKey}`)}
                </span>
              </div>
            );
          }
        })}

        <LabeledCheckbox
          onClick={() => setUpdatePartStatusesChecked(!updatePartStatusesChecked)}
          className="qa-status-update-job-completion"
        >
          <Checkbox checked={updatePartStatusesChecked} />
          <label>{t("shop.job.completeModal.updatePartStatus")}</label>
        </LabeledCheckbox>
        <StatusDropdown
          statuses={currentShop.shopStatuses.filter(
            status => !status.isHidden && !status.dateDeleted && getStatusName(status, t)
          )}
          selectedStatus={selectedStatus}
          onSelect={setSelectedStatus}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="qa-completeJobModal-confirm"
          onClick={async () => {
            if (job.orderItems.length === 0) {
              // special case of completing empty job, which is quite obscure since it can only
              // happen if the user chose not to delete the job when they removed the last order item
              await doUpdateJobCompleted(true);
            } else {
              // Moving all parts to Completed step will have side effect of marking Job as completed,
              // thus no need to call updateJob directly here. See useEffect(.., [orderItemQuantityByStep]) above.
              const uncompletedItemQuantities: ItemQuantityToMove[] = [];
              const lastStepId = jobRoute.shopRouteSteps[jobRoute.shopRouteSteps.length - 1].id;
              orderItemQuantityByStep.forEach((step, i) => {
                if (i < jobRoute.shopRouteSteps.length - 1) {
                  Object.keys(step).forEach(id =>
                    uncompletedItemQuantities.push({
                      id: +id,
                      fromStepId: jobRoute.shopRouteSteps[i].id,
                      toStepId: lastStepId,
                      quantity: step[+id],
                    })
                  );
                }
              });
              await createOrderItemJobRouteStepHistories(client, job.id, uncompletedItemQuantities);

              if (updatePartStatusesChecked) {
                const partsToUpdateStatus = job.orderItems.filter(
                  item => item.shopStatus !== selectedStatus.id
                );
                await updatOrderItemsStatus({
                  variables: {
                    ids: partsToUpdateStatus.map(part => part.id),
                    shopStatus: selectedStatus.id,
                  },
                });
              }
            }

            ReactGA.event({
              category: "GcShop Jobs",
              action: "Completed Job",
              label: `Shop ${currentShop.id}`,
            });

            onClose();
          }}
        >
          {t("shop.job.complete")}
        </Button>
      </Modal.Actions>
    </StyledCompleteJobModal>
  );
};

export const StyledUpdateStatusModal = styled(StyledModal)`
  .part-names {
    word-break: break-all;
  }
`;

export const UpdateStatusModal = ({
  itemIdsToUpdateStatus,
  open,
  onClose,
  job,
}: {
  itemIdsToUpdateStatus: number[];
  open: boolean;
  onClose: () => void;
  job: IShopJob;
}): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const [selectedStatus, setSelectedStatus] = useState(
    currentShop.shopStatuses.find(status => status.appStatus === "COMPLETED") as IShopStatus
  );
  const [updatePartStatusesChecked, setUpdatePartStatusesChecked] = useState(true);
  const [updatOrderItemsStatus] = useMutation<any, any>(UPDATE_ORDER_ITEMS_STATUS);

  const itemsToUpdate = job.orderItems.filter(i => itemIdsToUpdateStatus.includes(i.id));
  let originalFileNames = "";
  // When multiple files are dragged/moved to completed step
  originalFileNames = itemsToUpdate.map(item => getCadModelName(item)).join(" + ");

  return (
    <StyledUpdateStatusModal closeIcon basic={false} open={open} onClose={onClose}>
      <Header content={t("shop.job.item.updateStatus")} />
      <Modal.Content>
        <LabeledCheckbox
          onClick={() => setUpdatePartStatusesChecked(!updatePartStatusesChecked)}
          className="qa-status-update-job-completion"
        >
          <Checkbox checked={updatePartStatusesChecked} />
          <div className="part-names">
            <b>{t("shop.job.item.updateStatus.header", { names: originalFileNames })}</b>
          </div>
        </LabeledCheckbox>
        <StatusDropdown
          statuses={currentShop.shopStatuses.filter(
            status => !status.isHidden && !status.dateDeleted && getStatusName(status, t)
          )}
          selectedStatus={selectedStatus}
          onSelect={setSelectedStatus}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="qa-item-status-update"
          onClick={async () => {
            if (updatePartStatusesChecked) {
              await updatOrderItemsStatus({
                variables: { ids: itemIdsToUpdateStatus, shopStatus: selectedStatus.id },
              });
            }
            onClose();
          }}
        >
          {t("shop.job.item.updateStatus.update")}
        </Button>
      </Modal.Actions>
    </StyledUpdateStatusModal>
  );
};

export const StyledDeleteEmptyJobModal = styled(StyledModal)``;

export const DeleteEmptyJobModal = ({
  open,
  onClose,
  jobToDelete,
  client,
  history,
}: {
  open: boolean;
  onClose: () => void;
  jobToDelete: IShopJob;
  client: ApolloClient<object>;
  history: History;
}): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }

  return (
    <ConfirmationModal
      headerIcon={"trash"}
      headerCopy={t("shop.job.deleteEmpty.header")}
      bodyTitle={t("shop.job.deleteEmpty.bodyTitle", { jobName: jobToDelete.name })}
      bodyCopy={t("shop.job.deleteEmpty.bodyCopy")}
      cancelTranslationKey={"general.cancel"}
      confirmTranslationKey={"general.delete"}
      open={open}
      onClose={() => onClose()}
      submitAction={async () => {
        await client.mutate({
          mutation: DELETE_JOB,
          variables: { id: jobToDelete?.id },
          // It would be nice to update the cache explicitly, but we do not hold the necessary information to do so in this page
          // So take a sledgehammer to them instead...
          refetchQueries: [
            { query: JOB_COUNTS, variables: { id: currentShop.id } },
            { query: SHOP_JOBS, variables: { id: currentShop.id, active: true } },
          ],
          update: (_cache, response) => {
            if (response.data) {
              if (jobToDelete?.orderItems.length) {
                updateCachedOrderItemsJob(
                  client,
                  null,
                  jobToDelete?.orderItems.map(item => item.id)
                );
              }
            }
            // Job has been deleted so go to the shop jobs list
            history.push(ROUTES.SHOP(currentShop.id).JOBS.LIST);
          },
        });
        ReactGA.event({
          category: "GcShop Jobs",
          action: "Deleted Job",
          label: `Shop ${currentShop.id}`,
        });
      }}
    />
  );
};

export const OrderItemDetails = ({
  item,
  onClose,
}: {
  item: IOrderItem;
  onClose: () => void;
}): JSX.Element | null => {
  if (!item.order) {
    return null;
  }
  // Order part details are currently read-only from Jobs Page.
  const isWritable = false;
  const StyledCell = styled(TableCell)`
    padding: 0px !important;
    width: 100%;
  `;
  return (
    <StyledCell>
      <CadItemDetails
        key={`${item.id}}`}
        itemId={item.id}
        isItemWritable={isWritable}
        onClose={onClose}
      />
    </StyledCell>
  );
};
