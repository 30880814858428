/*
  MATERIAL_NAMES_BY_ID was initially generated using the following code to parse the current "best" SSoT from GCP
  const printers = <https://github.com/GrabCAD/eagle-print/blob/master/runtime-tests/diagnostics/config-files/all_printers_and_materials.json>
  const MATERIAL_NAMES_BY_ID = {};
  for (const p in printers) {
      if (printers[p].materials) {
          for (const m in printers[p].materials) {
              const material = printers[p].materials[m];
              if (MATERIAL_NAMES_BY_ID[material.internalName]) {
                  if (!MATERIAL_NAMES_BY_ID[material.internalName].gcpNames.includes(material.name)) {
                      MATERIAL_NAMES_BY_ID[material.internalName].gcpNames.push(material.name)
                  }
              } else {
                  MATERIAL_NAMES_BY_ID[material.internalName] = { gcpNames: [material.name] };
              }
          }
      }
  }
  console.log(MATERIAL_NAMES_BY_ID)
*/

type MaterialNameMap = Record<string, { gcpNames: string[]; shopName?: string }>;

export const MATERIAL_NAMES_BY_ID: MaterialNameMap = {
  P430: {
    gcpNames: ["ABS P430"],
  },
  P400: {
    gcpNames: ["ABS P400"],
  },
  ABS: {
    gcpNames: ["F123 ABS"],
  },
  "ABS-CF10": {
    gcpNames: ["F123 ABS-CF10"],
  },
  ASA: {
    gcpNames: ["F123 ASA", "ASA", "F3000 ASA"],
  },
  PLA: {
    gcpNames: ["F123 PLA"],
  },
  TPU92BLK: {
    gcpNames: ["F123 TPU 92A - Black"],
  },
  "ABS-ESD7": {
    gcpNames: ["F123 ABS-ESD7", "ABS-ESD7"],
  },
  Diran410: {
    gcpNames: ["F123 Diran 410MF07"],
  },
  "PC-ABS": {
    gcpNames: ["F123 PC-ABS", "PC-ABS"],
  },
  "ABS-M30": {
    gcpNames: ["ABS-M30"],
  },
  "ABS-M30i": {
    gcpNames: ["ABS-M30i"],
  },
  NYL12: {
    gcpNames: ["Nylon 12"],
  },
  PC: {
    gcpNames: ["PC", "F3000 PC"],
  },
  "PC-ISO": {
    gcpNames: ["PC-ISO"],
  },
  P430XL: {
    gcpNames: ["ABS P430XL"],
  },
  ABSi: {
    gcpNames: ["ABSi"],
  },
  NYL6: {
    gcpNames: ["Nylon 6"],
  },
  PPSF: {
    gcpNames: ["PPSF"],
  },
  ST130: {
    gcpNames: ["ST130"],
  },
  ULT1010: {
    gcpNames: ["ULTEM™ 1010 Resin"],
  },
  U1010CG: {
    gcpNames: ["ULTEM™ 1010 Resin CG"],
  },
  ULT9085: {
    gcpNames: ["ULTEM™ 9085 Resin"],
  },
  "ULTM-BLK": {
    gcpNames: ["ULTEM™ 9085 Resin Black"],
  },
  U9085CG: {
    gcpNames: ["ULTEM™ 9085 Resin CG"],
  },
  Ant800NA: {
    gcpNames: ["Antero 800NA"],
  },
  Ant840: {
    gcpNames: ["Antero 840CN03"],
  },
  HIPS: {
    gcpNames: ["HIPS"],
  },
  "KPC-FR": {
    gcpNames: ["Kimya PC-FR"],
  },
  NYL12CF: {
    gcpNames: ["Nylon 12 CF (Black)", "Nylon 12 CF", "F3000 Nylon 12 CF"],
  },
  "PC-ESD": {
    gcpNames: ["PC-ESD"],
  },
  "PC-BK": {
    gcpNames: ["PC Black"],
  },
  "PC-RD": {
    gcpNames: ["PC Red"],
  },
  "PCABS-RD": {
    gcpNames: ["PC-ABS Red"],
  },
  "9085-AGR": {
    gcpNames: ["ULTEM™ 9085 Resin Aircraft Gray"],
  },
  "9085-DGR": {
    gcpNames: ["ULTEM™ 9085 Resin Dream Gray"],
  },
  "9085-GGR": {
    gcpNames: ["ULTEM™ 9085 Resin Gunship Gray"],
  },
  "9085-JWT": {
    gcpNames: ["ULTEM™ 9085 Resin Jana White"],
  },
  "9085-RD": {
    gcpNames: ["ULTEM™ 9085 Resin Red"],
  },
  "9085-WT7": {
    gcpNames: ["ULTEM™ 9085 Resin White 7362"],
  },
  VTXAM200: {
    gcpNames: ["VICTREX AM™ 200"],
  },
  U9085: {
    gcpNames: ["ULTEM™ 9085 Resin"],
  },
  "Nylon-CF10": {
    gcpNames: ["F123 Nylon-CF10"],
  },
};

// `shopName` values are then added manually as needed when they differ from the GCP display names.
// These are "injected" so that the generated object above can more easily be kept up-to-date with GCP.
/* eslint-disable @typescript-eslint/dot-notation */
MATERIAL_NAMES_BY_ID["ABS"].shopName = "ABS-M30"; // F370
MATERIAL_NAMES_BY_ID["ABS-ESD7"].shopName = "ABS-ESD7 Static dissipative";
MATERIAL_NAMES_BY_ID["ABS-CF10"].shopName = "ABS-CF10 Carbon Fiber";
MATERIAL_NAMES_BY_ID["Diran410"].shopName = "Diran Nylon Based";
MATERIAL_NAMES_BY_ID["NYL12CF"].shopName = "Nylon 12 Carbon Fiber";
MATERIAL_NAMES_BY_ID["PC-ABS"].shopName = "Polycarbonate-ABS";
MATERIAL_NAMES_BY_ID["PC-ISO"].shopName = "Polycarbonate-ISO Biocompatible";
MATERIAL_NAMES_BY_ID["PC"].shopName = "Polycarbonate";
MATERIAL_NAMES_BY_ID["TPU92BLK"].shopName = "TPU 92A Elastomer";

// Some FDM materials are missing from the GCP data, so they are added manually here:
MATERIAL_NAMES_BY_ID["M30"] = { gcpNames: [], shopName: "ABS-M30" }; // Fortus 450mc, 400mc, 380mc
MATERIAL_NAMES_BY_ID["Ant840NA"] = { gcpNames: [], shopName: "Antero 840NA" };
MATERIAL_NAMES_BY_ID["Ant840CN03"] = { gcpNames: [], shopName: "Antero 840CN03" };
MATERIAL_NAMES_BY_ID["KIM-PEKK"] = {
  gcpNames: [],
  shopName: "Kimya Polycarbonate-Flame Retardant",
};

// SAF materials added manually, as they're not present in the GCP data
MATERIAL_NAMES_BY_ID["PP"] = { gcpNames: [], shopName: "Polypropylene (PP)" };
MATERIAL_NAMES_BY_ID["PA11"] = { gcpNames: [], shopName: "PA11 High Yield" };
MATERIAL_NAMES_BY_ID["PA12"] = { gcpNames: [], shopName: "SAF™ PA12" };

// NB: Polyjet intentionally omitted for now, as multi-material matching is too complex

// This is a "temporary" solution to match the Shop's material display names to the available materials
// We're hoping for a SSoT solution in the future, to be provided by the PrintersAPI.
export const materialIdsMatchingDisplayName = (materialIds: string[], displayName: string) => {
  return materialIds.filter(id => materialIdContainsOrMatchesFamilyDisplay(id, displayName));
};

export const materialIdContainsOrMatchesFamilyDisplay = (id: string, displayName: string) => {
  const materialFamily = id.split("_")[0];
  if (materialFamily === displayName) {
    // This will match ASA to ASA_ORG. Also matches PLA_, ABS_, etc.
    return true;
  }
  // Some materials need both "family" extraction and "shopName" matching, eg PC-ABS_WHT
  const materialEntry = MATERIAL_NAMES_BY_ID[id] || MATERIAL_NAMES_BY_ID[materialFamily];
  if (materialEntry) {
    if (materialEntry.gcpNames.includes(displayName)) {
      return true;
    }
    if (materialEntry.shopName === displayName) {
      return true;
    }
  }
  return false;
};
/*
A large, but probably still incomplete set of "real" material IDs can be extracted from raw PrintersAPI data like so:

let printers = <ep_printers_reply.json>
let REAL_MATERIAL_IDS = [...new Set(printers.map(p => 
  p.printer_status.print_materials
    .filter(m => !m.is_support) // Shop doesn't support support
    .map(m => m.name)).flat())]
    .sort((a, b) => a.localeCompare(b))
console.log(REAL_MATERIAL_IDS)

// To see which printers are reporting a given material, you can use this code:
let REAL_MATERIAL_IDS = []
printers.forEach(p => {
  p.printer_status.print_materials.forEach(m => {
    if (!m.is_support) {
      if (REAL_MATERIAL_IDS.find(r => r.id === m.display_name)) {
        if (!REAL_MATERIAL_IDS.find(r => r.id === m.display_name).printers.includes(p.printer_info.modelName)) {
          REAL_MATERIAL_IDS.find(r => r.id === m.display_name).printers.push(p.printer_info.modelName)
        }
      } else {
        REAL_MATERIAL_IDS.push({id: m.display_name, printers: [p.printer_info.modelName]})
      }      
    }
  })
})
console.log(REAL_MATERIAL_IDS.sort((a, b) => a.id.localeCompare(b.id)))

This may be useful for improving the completeness of MATERIAL_NAMES_BY_ID ^^.
*/

// NB: This list has additional IDs derived from multiple unversioned PrintersAPI data sources.
//     In general it should only be extended, and never reduced.
// export only to appease compiler. This is only used for reference.
export const REAL_MATERIAL_IDS = [
  "",
  "9085_NA",
  "ABS_BLK",
  "ABS_BLU",
  "ABS_DGRY",
  "ABS_GRN",
  "ABS_YEL",
  "ABS-CF10",
  "ABS-M30",
  "Agilus30Black",
  "Agilus30Blk",
  "Agilus30Clr",
  "Agilus30Cyn",
  "Agilus30Mgn",
  "Agilus30Wht",
  "Agilus30Ylw",
  "Ant800NA",
  "Ant840",
  "Ant840CN03",
  "ASA",
  "ASA_BLK",
  "ASA_BLU",
  "ASA_DGRY",
  "ASA_GRN",
  "ASA_IVR",
  "ASA_LGRY",
  "ASA_ORG",
  "ASA_RED",
  "ASA_WHT",
  "ASA_YEL",
  "BoneMatrix",
  "Cleanser",
  "Diran410",
  "GelMatrix",
  "HAF",
  "HIPS",
  "KIM-PEKK",
  "M30_BLK",
  "M30_BLU",
  "M30_GRY",
  "M30_RED",
  "M30_WHT",
  "MED610",
  "MED620",
  "MEDFLX625",
  "NYL12",
  "NYL12CF",
  "Nylon-CF10",
  "P430_IVR",
  "PA-GF-LS",
  "PA11",
  "PA12",
  "PC",
  "PC-ABS",
  "PC-ABS_BLK",
  "PC-ABS_WHT",
  "PC-ISO",
  "PET-CF",
  "PLA_BLK",
  "PLA_BLUT",
  "PLA_NATT",
  "PLA_YELT",
  "PP",
  "PP-GF",
  "PPSF",
  "RGD515",
  "RGD531",
  "RGD821",
  "SUP4000B",
  "TDM100",
  "TDM110",
  "TDM120",
  "TDM130",
  "TDM140",
  "TissueMatrix",
  "ToughWhite",
  "TPU92BLK",
  "U1010CG",
  "U9085CG",
  "ULT1010",
  "ULT9085",
  "ULTM-BLK",
  "VCntFLX625",
  "VeroBlack",
  "VeroClear",
  "VeroCY-V",
  "VeroCyan",
  "VeroMgnt",
  "VeroMGT-V",
  "VeroPureWht",
  "VeroPureWht-D",
  "VeroWhite",
  "VeroYellow",
  "VeroYL-V",
  "VerUltraClr",
  "VUltraBlack",
  "VUltraWhite",
  "Waste",
];

// Uncomment to get a list of "unknown" material (or "family") IDs not present in MATERIAL_NAMES_BY_ID
// const UNKNOWN_IDS = REAL_MATERIAL_IDS.filter(id => !MATERIAL_NAMES_BY_ID[id] && !MATERIAL_NAMES_BY_ID[id.split("_")[0]]);
// console.log(UNKNOWN_IDS.join(", ")); // useful for copy-pasting into .xlsx
// console.log(UNKNOWN_IDS);

// const SHARED_COLOR_SUFFIXES = REAL_MATERIAL_IDS.reduce((acc, id) => {
//   const color = id.split("_")[1];
//   if (color && !acc.includes(color)) {
//     acc.push(color);
//   }
//   return acc;
// }, [] as string[]).sort((a, b) => a.localeCompare(b)));
// console.log(SHARED_COLOR_SUFFIXES)

// ^^ This gets us partway to a full list of color suffixes, but we still need to add some manually:

export const COLOR_DISPLAY_NAMES_BY_ID_PART: Record<string, string> = {
  BLK: "Black",
  BLU: "Blue",
  DGRY: "Dark Gray", // ABS, ASA
  GRY: "Dark Gray", // ABS-M30
  GRN: "Green",
  IVR: "Ivory",
  ORG: "Orange",
  LGRY: "Light Gray",
  MGRY: "Medium Gray", // guessed
  NATT: "Translucent Natural",
  RED: "Red",
  WHT: "White",
  YEL: "Yellow",
  BLUT: "Translucent Blue",
  GRNT: "Translucent Green", // guessed
  REDT: "Translucent Red", // guessed
  YELT: "Translucent Yellow",

  // These are colors of Ultem 9085, which ostensibly have non-standard "family" prefix: "9085-".
  AGR: "Aircraft Gray",
  DCR: "Dream Gray",
  GGR: "Gunship Gray",
  JWT: "Jana White",
  RD: "Red",
  WT7: "White 7362",
  // NB: 9085 also has a ULTM-BLK, which breaks the family prefix pattern of the colors above.
  // These nonstandard & inconsistent prefixes are "normalized" in the useMatchingLiveMaterials hook.

  // NAT isn't a "real" color suffix as reported by the printers!
  // We think Tan Ultem 9085 will be reported as either ULT9085, or 9085_NA depending on the printer.
  // This is apparently because this material was initially launched with a single color.
  TAN: "Tan (Natural)",
};
