import React from "react";

// Runs a callback every intervalMs, until one of:
// - the callback changes
// - the interval changes
// - the component unmounts
export function useInterval(cb: () => void, intervalMs: number) {
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    timeoutRef.current = setInterval(cb, intervalMs);
  }, [cb, intervalMs]);

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);
}
