// TODO GC-100243: Share with company server
import { useContext, useEffect, useState, useCallback } from "react";
import {
  CloudUnauthorizedProvider,
  FixturesProvider,
  getPrintersApi,
  IPrinter,
} from "grabcad-printers-api";
import fakePrinterData from "../testing-utils/ep_printers_reply.json";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { config, PrinterDataSource } from "../config";
import { useInterval } from "./useInterval";
import { machineTypeMatchesLivePrinterModelName } from "./printerUtils";

// This hook largely copypastaed from https://github.com/GrabCAD/company-server/blob/master/frontend/src/screens/Printer/UseGetAllPrinters.tsx

/**
 * By convention with grabcad.com and eagle cloud, this number is added to
 * company server company ids to distinguish them from workbench account ids
 */
const WORKBENCH_COMPANY_ID_OFFSET = 1000000000; // 10^9
export function workbenchifyCompanyId(id: number): number {
  // first `+` must be there to tell javascript these are numbers, not strings to concatenate!
  return +id + WORKBENCH_COMPANY_ID_OFFSET;
}

const PRINTERS_REFRESH_TIME_MS = 1000 /* ms/s */ * 60; /* s/min */
const PRINTERS_ERROR_RETRY_TIME_MS = 1000 /* ms/s */ * 20; /* s */

const getAllPrintersWithCaching = (() => {
  type Cache = {
    // This is a RW lock that prevents different hook invocations from trying to update
    // the cache at the same time and possibly spamming cloud server. Only one of
    // the hooks needs to make the API call; the others will wait and read the result
    // from the cache
    isLocked: boolean;
    printers: IPrinter[];
    updatedAt: number;
    companyId: number;
    expiryTimeMs: number;
  };

  const cache: Cache = {
    isLocked: false,
    printers: [],
    updatedAt: 0,
    companyId: 0,
    expiryTimeMs: PRINTERS_REFRESH_TIME_MS,
  };

  return async (companyId: number, dataSource: PrinterDataSource): Promise<IPrinter[]> => {
    if (dataSource === PrinterDataSource.None) {
      return [];
    }

    // Wait for concurrent cache update to finish
    while (cache.isLocked) {
      await new Promise(res => setTimeout(res, 1));
    }

    const now = Date.now();

    if (now - cache.updatedAt < cache.expiryTimeMs && companyId === cache.companyId) {
      return cache.printers;
    }

    try {
      cache.isLocked = true;

      const workbenchifiedCompanyId = workbenchifyCompanyId(companyId);
      const provider =
        dataSource === PrinterDataSource.Fixtures
          ? new FixturesProvider(fakePrinterData as unknown[])
          : new CloudUnauthorizedProvider(
              config.REACT_APP_CLOUD_API_BASE_URL as string,
              workbenchifiedCompanyId
            );
      const api = getPrintersApi(provider);
      const response = await api.getAll();

      cache.printers = response;
      cache.updatedAt = now;

      return cache.printers;
    } catch (err) {
      cache.updatedAt = now - cache.expiryTimeMs + PRINTERS_ERROR_RETRY_TIME_MS;
      throw err;
    } finally {
      cache.companyId = companyId;
      cache.isLocked = false;
    }
  };
})();

export const useLivePrinters = () => {
  const [printers, setPrinters] = useState<IPrinter[]>([]);
  const [loadingPrinters, setLoadingPrinters] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  // printerDataSource is passed to tests via context to simplify components that use this hook in nested components:
  const { currentUser, printerDataSource } = useContext(ApplicationContext);
  const companyId = currentUser.userProfile?.company.id as number;
  const retryInterval = errorOccurred ? PRINTERS_REFRESH_TIME_MS : PRINTERS_ERROR_RETRY_TIME_MS;

  const loadPrinters = useCallback(() => {
    void (async () => {
      try {
        let allPrinters = await getAllPrintersWithCaching(
          config.REACT_APP_SPOOFED_COMPANY_ID || companyId,
          printerDataSource
        );

        // In case the Printers API gives us back the same reference
        if (allPrinters === printers) {
          allPrinters = [...printers];
        }

        setPrinters(allPrinters);
        setLoadingPrinters(false);
        setErrorOccurred(false);
      } catch (err) {
        setPrinters([]);
        setLoadingPrinters(false);
        setErrorOccurred(true);
        setErrorMessage(`${err}`);

        console.log(JSON.stringify(err));
      }
    })();
  }, [companyId, printerDataSource]);

  useEffect(() => {
    loadPrinters();
  }, []);

  useInterval(() => {
    loadPrinters();
  }, retryInterval);

  return {
    loadingPrinters,
    setLoadingPrinters,
    printers: loadingPrinters ? undefined : printers,
    errorOccurred,
    errorMessage,
  };
};

// Takes an AppMachineType, and returns a list of matching live printers
export const useMatchingLivePrinters = (machineType?: string) => {
  const { printers } = useLivePrinters();

  if (!printers || !machineType) {
    return [];
  }

  const matchingPrinters = printers.filter(p =>
    machineTypeMatchesLivePrinterModelName(machineType, p)
  );
  return matchingPrinters;
};
