import { ApplicationContext } from "@/components/ApplicationProvider";
import { LayoutApplicationSidebar } from "@/components/Layout/ApplicationSidebar/ApplicationSidebar";
import { Breadcrumbs, IBreadCrumbMatch, IBreadCrumbSection } from "@/components/Layout/BreadCrumbs";
import { LayoutShopSidebar } from "@/components/Layout/ShopSidebar/ShopSidebar";
import React, { ComponentType, useContext, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ROUTES } from "@/shopConstants";
import styled, { CollapsibleSidebar, keyframes } from "grabcad-ui-elements";
import { IShop, ShopState } from "@/graphql/Fragments/Shop";
import { IUser } from "@/graphql/Fragments/User";
import { SHOP_DETAILS } from "@/graphql/Queries";
import { NAVBAR_HEIGHT_PX, TopNavbarWrapper } from "@/screens/General/TopNavbarWrapper";
import { Permission } from "@/utils/Permission";
import { getBrowserSettings, saveBrowserSettings } from "@/utils/localStorageSettings";
import { ApolloClient } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  // Global styles can go here
  h1 {
    margin-top: 10px;
  }
  a {
    color: #003393;
  }

  // The blue flash fades to 0 opacity, so it can be overlaid over any parent's background-color.
  .just-added {
    animation: ${keyframes`
      from {
        background:rgba(154, 212, 255, 1);
      }
      to {
        background:rgba(154, 212, 255, 0);
      }
    `} 1s ease-out 1;
  }
`;

const Application = styled.div`
  display: flex;
  flex: 1 1 1px;
  height: calc(100vh - ${NAVBAR_HEIGHT_PX});
  overflow-y: auto;

  // this is a hack most likely and needs to be revisited ¯\\_(ツ)_/¯
  ${(props: any) =>
    props.isInternalCompany &&
    `
      .pusher {
          height: 100%;
          height: -moz-available;
          height: -webkit-fill-available;
          height: fill-available;
        }
    `}
`;
Application.displayName = "Application";

const ShopWrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  .main-content-and-breadcrumbs {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100vh;
    overflow-y: auto;
    padding: 26px 20px 20px 15px;
    width: 100%;
    .main-content {
      position: relative;
      flex: 1;
      min-height: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
      h2.page-header {
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 0;
        flex: 1 1 0;
      }
    }
  }
`;
ShopWrapper.displayName = "ShopWrapper";

export interface IScreenProps {
  history?: RouteComponentProps["history"];
}

interface IShell
  extends RouteComponentProps<{
    shopId: string;
    technologyId: string;
    machineId: string;
    orderId: string;
    jobId: string;
  }> {
  client: ApolloClient<any>;
}

const Screen = (props: {
  ScreenComponent: ComponentType<{ section?: string }>;
  section?: string;
  breadCrumbs?: IBreadCrumbSection[];
  match: IBreadCrumbMatch;
}) => {
  const { section, breadCrumbs, match, ScreenComponent } = props;

  const [userHasCollapsedSideBar, setUserHasCollapsedSideBar] = useState(
    getBrowserSettings().userHasCollapsedSideBar
  );
  useEffect(() => saveBrowserSettings({ userHasCollapsedSideBar }), [userHasCollapsedSideBar]);

  // Hover state _could_ have been stored in context, or in the Routes component, but we're already using localStorage.
  const [sidebarIsHovered, setSidebarIsHovered] = useState(getBrowserSettings().sidebarIsHovered);
  useEffect(() => saveBrowserSettings({ sidebarIsHovered }), [sidebarIsHovered]);

  useEffect(() => {
    // sidebarIsHovered to false on logout, otherwise on log in the menu will be open
    const onUnload = () => {
      if (getBrowserSettings().userHasCollapsedSideBar && getBrowserSettings().sidebarIsHovered) {
        saveBrowserSettings({ sidebarIsHovered: false });
      }
    };
    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);

  return (
    <ShopWrapper>
      <CollapsibleSidebar
        userHasCollapsedSideBar={userHasCollapsedSideBar}
        setUserHasCollapsedSideBar={setUserHasCollapsedSideBar}
        sidebarIsHovered={sidebarIsHovered}
        setSidebarIsHovered={setSidebarIsHovered}
        mainContentComponent={
          <div className="main-content-and-breadcrumbs">
            <Breadcrumbs sections={breadCrumbs} match={match} />
            <div className="main-content">
              <ScreenComponent {...props} />
            </div>
          </div>
        }
        menuComponent={<LayoutShopSidebar section={section} />}
        sidebarOffset={80}
      />
    </ShopWrapper>
  );
};

const loadShop = async (
  {
    client,
    history,
    match,
  }: {
    client: ApolloClient<any>;
    history: RouteComponentProps["history"];
    match: RouteComponentProps["match"];
  },
  shopId: number
) => {
  const { currentShop, setCurrentShop } = useContext(ApplicationContext);

  const {
    data: { shop },
  }: { data: { shop: IShop } } = await client.query({
    query: SHOP_DETAILS,
    variables: { id: shopId },
  });

  if (!currentShop || (currentShop && currentShop.id !== shop.id)) {
    setCurrentShop(shop);
  }

  if (
    shop &&
    shop.state === ShopState.DRAFT &&
    !match.path.includes(ROUTES.SHOP(":shopId?", true).GENERAL)
  ) {
    history.push(ROUTES.SHOP(shop.id, true).GENERAL);
  }
};

const Shell = (
  ScreenComponent: ComponentType<{ section?: string }>,
  section?: string,
  breadCrumbs?: IBreadCrumbSection[]
): React.ComponentClass<Omit<unknown, "client">> => {
  const shell = withApollo(props => {
    const mergedProps = { ...(props as IShell), section, breadCrumbs, ScreenComponent };
    const { match } = props as IShell;
    const shopId = +match.params.shopId;
    const { currentUser, currentShop } = useContext(ApplicationContext);

    // Uses current shop details to fetch user permissions
    const userRoleFromShop = (shop: IShop | null) => {
      const userRoles = [];
      if (shop) {
        if (shop.permissions.includes(Permission.WRITE)) {
          userRoles.push("Admin");
        }
        if (shop.permissions.includes(Permission.SET_OPERATOR_STATUSES)) {
          userRoles.push("Operator");
        }
        if (shop.permissions.includes(Permission.SET_REQUESTER_STATUSES)) {
          userRoles.push("Requester");
        }
        return userRoles.toString();
      }
    };

    // User role in homepage fetched using userProfie. Can only know if the user is an admin or not.
    const userRoleFromHomePage = (user: IUser | null) => {
      const roles = [];
      if (user?.permissions?.includes(Permission.CREATE_SHOP)) {
        roles.push("Admin");
      } else {
        roles.push("Request sent from Home Page");
      }
      return roles.toString();
    };

    // On page reload, parse shop ID from URL and copy stuff from GQL cache into ApplicationContext
    if (shopId) {
      loadShop(props as IShell, shopId);
    }

    const role = currentShop
      ? userRoleFromShop(currentShop)
      : userRoleFromHomePage(currentUser.userProfile);

    return (
      <AppWrapper>
        <TopNavbarWrapper role={role} />
        <Application>
          <LayoutApplicationSidebar />
          <Screen {...mergedProps} />
        </Application>
      </AppWrapper>
    );
  });
  shell.displayName = `Shell(${ScreenComponent.displayName})`;
  return shell;
};

export { Shell };
