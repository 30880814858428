import React, { useContext } from "react";
import { ROUTES } from "@/shopConstants";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import styled, { Card, CardContent, CardHeader } from "grabcad-ui-elements";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { CardLinkWrapper } from "@/components/Shared/CardLink";
import { Link } from "react-router-dom";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ContextMenu } from "./ContextMenu";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { MachineImage } from "./MachineImage";
import { PrinterStatusSummary } from "../../../screens/Shop/Printer/PrinterStatusSummary";

interface IMachineItemProps {
  machine: Partial<IShopMachine>;
  loadingImage?: boolean;
  technology: IShopTechnology;
  openDeleteModal(machine: Partial<IShopMachine> | undefined): void;
}

const MachineName = styled.div`
  color: #444;
  font-size: 18px;
`;

const TechnologyName = styled.div`
  color: #999;
  font-size: 14px;
`;

export const ShopMachineCard = ({
  machine,
  loadingImage,
  technology,
  openDeleteModal,
}: IMachineItemProps): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);

  if (!currentShop) {
    return null;
  }

  const machineEditLink = ROUTES.SHOP(currentShop.id).MACHINES.MACHINE(technology.id, machine.id);

  // Fragments not needed here, nor parentheses, since both wrap a single child
  const menuItems: JSX.Element[] = [
    <Link to={machineEditLink} className="item edit" key="edit">
      {t("shop.machine.edit")}
    </Link>,
    <a
      className="item delete"
      onClick={e => {
        e.preventDefault();
        openDeleteModal(machine);
      }}
      key="edit-link"
    >
      {t("shop.machine.delete")}
    </a>,
  ];

  return (
    <CardLinkWrapper data-testid="machineCard">
      <Link to={machineEditLink}>
        <Card link raised>
          <MachineImage machine={machine} loading={loadingImage} />
          <CardContent>
            <CardHeader>
              <MachineName data-testid="machineName">
                {machine.appMachineType?.displayName}
              </MachineName>
              <TechnologyName className="qa-machineCard-techName">
                {technology.appTechnology.displayName}
              </TechnologyName>
              <UiCan do={Permission.DELETE} on={machine}>
                <ContextMenu menuItems={menuItems} />
              </UiCan>
            </CardHeader>
            {machine.appMachineType?.name && (
              <PrinterStatusSummary machineType={machine.appMachineType.name} />
            )}
          </CardContent>
        </Card>
      </Link>
    </CardLinkWrapper>
  );
};
