import { IAppMaterial, IShopMaterialColor } from "@/components/Shop/Materials/Form/MaterialsForm";
import type {
  IShopTechnologiesWithMachineImages,
  IShopTechnologiesWithMachines,
  IShopTechnologiesWithDeletedMachines,
} from "@/graphql/Fragments/Shop";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { SHOP_TECHNOLOGIES_WITH_MACHINES } from "@/graphql/Queries";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import {
  IMachineRate,
  IMaterialRate,
  MachineRateFields,
  MaterialRateFields,
} from "@/graphql/Fragments/ShopRate";
import {
  MACHINE_RATE,
  MATERIAL_RATE,
  SHOP_TECHNOLOGIES_WITH_MACHINE_IMAGES,
  SHOP_TECHNOLOGIES_WITH_DELETED_MACHINES,
} from "@/graphql/Queries/Shop";
import { STRATASYS_TECHNOLOGIES, TECHNOLOGY } from "../shopConstants";
import { IAppMachineType } from "@/graphql/Fragments/AppMachineType";
import { IAppMaterialColor } from "@/graphql/Queries/MaterialColor";
import { useContext, useMemo } from "react";
import { ApplicationContext } from "../components/ApplicationProvider";
import { IAppTechnology } from "@/graphql/Fragments/AppTechnology";
import type { IImage } from "@/graphql/Fragments/Image";

export const ANY = "ANY";
const CUSTOM = "CUSTOM";
const CUSTOM_MACHINE = "CUSTOM MACHINE";
type nameableAppEntity = IAppTechnology | IAppMachineType | IAppMaterial | IAppMaterialColor;
type shopEntity = IShopTechnology | IShopMachine | IShopMaterial | IShopMaterialColor;

// Used to localize GQL Entities: _Technologies, _Machines, _Materials, and _Colors, 'Shop' or 'App' prefixed.
export const useLocalizeDisplayName = () => {
  // Hook pattern is used to avoid needing to pass `t` to each function call
  const { t } = useContext(ApplicationContext);

  const localizeDisplayName = <T extends nameableAppEntity | shopEntity>(entity: T): T => {
    const localizedAny = t("shop.job.material.any"); // TODO replace with "entity.any" once localzed
    const updatedEntity = { ...entity }; // Entities are readonly so require destructuring

    // All 'App_' prefixed entities can have name === "Any"
    if ((updatedEntity as nameableAppEntity).name?.toUpperCase() === ANY) {
      (updatedEntity as nameableAppEntity).displayName = localizedAny;
    }

    // Each 'Shop_ prefixed entity can have a "Any" `App_` prefixed "parent"
    if ((updatedEntity as IShopTechnology).appTechnology?.name === TECHNOLOGY.ANY) {
      (updatedEntity as IShopTechnology).appTechnology = {
        ...(updatedEntity as IShopTechnology).appTechnology,
        displayName: localizedAny,
      };
    }
    if ((updatedEntity as IShopMachine).appMachineType?.name.toUpperCase() === ANY) {
      (updatedEntity as IShopMachine).appMachineType = {
        ...(updatedEntity as IShopMachine).appMachineType,
        displayName: localizedAny,
      };
    }
    if ((updatedEntity as IShopMaterial).appMaterial?.name.toUpperCase() === ANY) {
      (updatedEntity as IShopMaterial).appMaterial = {
        ...(updatedEntity as IShopMaterial).appMaterial,
        displayName: localizedAny,
      };
    }
    if ((updatedEntity as IShopMaterialColor).appMaterialColor?.name.toUpperCase() === ANY) {
      (updatedEntity as IShopMaterialColor).appMaterialColor = {
        ...(updatedEntity as IShopMaterialColor).appMaterialColor,
        displayName: localizedAny,
      };
    }

    // Only _Technology ENtities can have name === "Custom"
    const localizedCustom = t("entity.custom");
    if ((updatedEntity as IAppTechnology).name === TECHNOLOGY.CUSTOM) {
      (updatedEntity as IAppTechnology).displayName = localizedCustom;
    }
    if ((updatedEntity as IShopTechnology).appTechnology?.name === TECHNOLOGY.CUSTOM) {
      (updatedEntity as IShopTechnology).appTechnology = {
        ...(updatedEntity as IShopTechnology).appTechnology,
        displayName: localizedCustom,
      };
    }

    // Machines can have name === "Custom Machine"
    const localizedCustomMachine = t("machine.custom");
    if ((updatedEntity as IAppMachineType).name?.toUpperCase() === CUSTOM_MACHINE) {
      (updatedEntity as IAppMachineType).displayName = localizedCustomMachine;
    }
    if ((updatedEntity as IShopMachine).appMachineType?.name.toUpperCase() === CUSTOM_MACHINE) {
      (updatedEntity as IShopMachine).appMachineType = {
        ...(updatedEntity as IShopMachine).appMachineType,
        displayName: localizedCustomMachine,
      };
    }

    return updatedEntity;
  };

  return localizeDisplayName;
};

// Used to alphabetize GQL Entities: _Technologies, _Machines, _Materials, and _Colors, 'Shop' or 'App' prefixed.
// All have a derivable `displayName` prop. "Any" and "Custom" are special cases, and are pushed to the end.
// This sorting approach works better than a .splice() approach for flattened lists with multiple "Any"s.
// Sorting as early/upstream as possible centralizes the sorting business logic, and helps achieve consistency across
// all DropDowns and other lists/navigation elements.
export const sortByName = <T extends nameableAppEntity | shopEntity>(a: T, b: T) => {
  const extractNameProp = (entity: any | undefined, propName: string): string =>
    entity?.[propName] ||
    entity?.appTechnology?.[propName] ||
    entity?.appMachineType?.[propName] ||
    entity?.appMaterial?.[propName] ||
    entity?.appMaterialColor?.[propName] ||
    "";

  const nameA = extractNameProp(a, "name").toUpperCase();
  const nameB = extractNameProp(b, "name").toUpperCase();
  if (nameA === ANY) {
    return 1;
  } else if (nameB === ANY) {
    return -1;
  } else if (nameA === CUSTOM) {
    return 1; // Custom is above any
  } else if (nameB === CUSTOM) {
    return -1;
  }

  // Sort by displayName in case we ever decide to localize more entities
  const displayNameA = extractNameProp(a, "displayName").toUpperCase();
  const displayNameB = extractNameProp(b, "displayName").toUpperCase();
  return displayNameA < displayNameB ? -1 : displayNameA > displayNameB ? 1 : 0;
};

// given a collection will place the dropdown item(s) with text "FDM", "PolyJet", "SAF" or "P3" in the beginning of array
export const ssysTechnologiesToBeginning = (array: IShopTechnology[] | IAppTechnology[]): void => {
  STRATASYS_TECHNOLOGIES.forEach(tech => {
    const techIndex = array.findIndex(item => {
      if ("name" in item) {
        return item.name.toLowerCase() === tech.toLowerCase();
      } else {
        return item.appTechnology.name.toLowerCase() === tech.toLowerCase();
      }
    });
    if (techIndex > 0) {
      // vs '-1': covers both no-op, and single-tech lists
      array.splice(0, 0, (array as any).splice(techIndex, 1)[0]);
    }
  });
};

export const useShopTechnologies = (): {
  loadingTechnologies: boolean;
  loadingMachineImages: boolean;
  shopTechnologies: IShopTechnology[];
  allMachines: IShopMachine[];
  allMaterials: IShopMaterial[];
  allMaterialColors: IShopMaterialColor[];
  machineByMaterialId: (materialId?: number | null) => IShopMachine | null;
} => {
  const { currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    // Currently only needed by <Breadcrumbs> Component, which can render w/ or w/o a currentShop
    return {
      loadingTechnologies: true,
      loadingMachineImages: true,
      shopTechnologies: [],
      allMachines: [],
      allMaterials: [],
      allMaterialColors: [],
      machineByMaterialId: () => null,
    };
  }
  const shopTechsQuery = useQuery<IShopTechnologiesWithMachines>(SHOP_TECHNOLOGIES_WITH_MACHINES, {
    variables: { id: currentShop.id },
  });
  const machineImagesQuery = useQuery<IShopTechnologiesWithMachineImages>(
    SHOP_TECHNOLOGIES_WITH_MACHINE_IMAGES,
    {
      variables: { id: currentShop.id },
    }
  );
  const deletedMachinesQuery = useQuery<IShopTechnologiesWithDeletedMachines>(
    SHOP_TECHNOLOGIES_WITH_DELETED_MACHINES,
    {
      variables: { id: currentShop.id },
    }
  );

  const localizeDisplayName = useLocalizeDisplayName();

  // Sort all data immediately upon receipt so order is consistent throughout UI!
  // .map(entity => {...entity}) & [...spread]s necessary for sorting since data is recursively read only.
  // Server-side sorting would be easier, but that precludes future technology localization.
  return useMemo(() => {
    const shopTechnologies = [...(shopTechsQuery.data?.shop.shopTechnologies || [])]
      .map(localizeDisplayName)
      .sort(sortByName)
      .map(tech => ({ ...tech }));
    const images: {
      [machineId: number]: IImage | null;
    } = {};

    if (machineImagesQuery.data) {
      for (const tech of machineImagesQuery.data.shop.shopTechnologies) {
        for (const machine of tech.shopMachines) {
          images[machine.id] = machine.image;
        }
      }
    }

    ssysTechnologiesToBeginning(shopTechnologies);
    shopTechnologies.forEach(tech => {
      const techWithDeletedMachines = deletedMachinesQuery.data?.shop.shopTechnologies.find(
        t => t.id === tech.id
      );

      tech.shopMachines = [...tech.shopMachines, ...(techWithDeletedMachines?.shopMachines || [])]
        .map(localizeDisplayName)
        .sort(sortByName)
        .map(machine => ({ ...machine }));
      tech.shopMachines.forEach(machine => {
        machine.materials = [...machine.materials]
          .map(localizeDisplayName)
          .sort(sortByName)
          .map(material => ({ ...material }));
        machine.materials.forEach(material => {
          material.shopMaterialColors = [...material.shopMaterialColors]
            .map(localizeDisplayName)
            .sort(sortByName);
        });

        if (machine.id in images) {
          machine.image = images[machine.id];
        }
      });
    });

    const allMachines = shopTechnologies
      .reduce(
        (acc, tech) =>
          acc.concat(tech.shopMachines.map(machine => ({ ...machine, shopTechnologyId: tech.id }))),
        [] as IShopMachine[]
      )
      // Machines unfiltered by Tech are re-alphabetized, with Any at the end. This is user-facing in "New Job" form:
      .sort(sortByName);
    const allMaterials = allMachines
      .reduce(
        (acc, machine) =>
          (acc = acc.concat(
            machine.materials.map(material => ({ ...material, shopMachineId: machine.id }))
          )),
        [] as IShopMaterial[]
      )
      // Materials unfiltered by Machine are re-alphabetized, with Any at the end. This is user-facing in "New Job" form:
      .sort(sortByName);
    const allMaterialColors = allMaterials
      .reduce(
        (acc, material) => (acc = acc.concat(material.shopMaterialColors)),
        [] as IShopMaterialColor[]
      )
      .sort(sortByName); // Might as well re-alphabetize colors too? Shouldn't make a difference with current UI

    const machineByMaterialId = (materialId?: number | null) => {
      const material = allMaterials.find(m => m.id === materialId);
      if (!material) {
        return null;
      }
      return allMachines.find(m => m.id === material.shopMachineId) || null;
    };
    return {
      loadingTechnologies: shopTechsQuery.loading,
      loadingMachineImages: machineImagesQuery.loading,
      shopTechnologies,
      allMachines,
      allMaterials,
      allMaterialColors,
      machineByMaterialId,
    };
  }, [shopTechsQuery.data?.shop, machineImagesQuery.data?.shop, deletedMachinesQuery.data?.shop]);
};

export const useShopTechnologyById = (
  shopTechnologyId?: number | null
): { loading: boolean; shopTechnology?: IShopTechnology } => {
  const { loadingTechnologies, shopTechnologies } = useShopTechnologies();

  return {
    loading: loadingTechnologies,
    shopTechnology: shopTechnologies.find(tech => tech.id === shopTechnologyId),
  };
};

export const useCacheableMachineRate = (
  id: number | null | undefined
): { loading?: boolean; machineRate?: IMachineRate } => {
  const [fetch, { called, loading, data }] = useLazyQuery(MACHINE_RATE, {
    variables: { id },
  });
  const client = useApolloClient();
  if (!id) {
    return {};
  }
  // Check the cache first
  const cachedMachineRate = client.cache.readFragment<IMachineRate>({
    id: `MachineRateHistory:${id}`,
    fragment: MachineRateFields,
    fragmentName: "machineRateFields",
  });
  if (cachedMachineRate) {
    return { loading: false, machineRate: cachedMachineRate };
  } else if (!called) {
    fetch(); // If not already cached, fetch rate from server
  }
  return {
    loading,
    machineRate: data?.machineRateById,
  };
};

export const useCacheableMaterialRate = (
  id: number | null | undefined
): { loading?: boolean; materialRate?: IMaterialRate } => {
  const [fetch, { called, loading, data }] = useLazyQuery(MATERIAL_RATE, {
    variables: { id },
  });
  const client = useApolloClient();
  if (!id) {
    return {};
  }
  // Check the cache first
  const cachedMaterialRate = client.cache.readFragment<IMaterialRate>({
    id: `MaterialRateHistory:${id}`,
    fragment: MaterialRateFields,
    fragmentName: "materialRateFields",
  });
  if (cachedMaterialRate) {
    return { loading: false, materialRate: cachedMaterialRate };
  } else if (!called) {
    fetch(); // If not already cached, fetch rate from server
  }
  return {
    loading,
    materialRate: data?.materialRateById,
  };
};
