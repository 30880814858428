import gql from "graphql-tag";
import {
  ShopFragments,
  ShopMachineFragments,
  ShopTechnologyFragments,
  ShopStatusFragments,
} from "@/graphql/Fragments";
import { MachineRateFields, MaterialRateFields } from "../Fragments/ShopRate";

export const SHOPS_LIST = gql`
  query {
    shops {
      ...shopFields
      ...shopLogoFields
    }
  }
  ${ShopFragments.shop}
  ${ShopFragments.shopLogo}
`;

export const SHOP_DETAILS = gql`
  query Shop($id: Int!) {
    shop(id: $id) {
      ...shopFields
      ...shopLogoFields
      ...shopPreferencesFields
      shopStatuses {
        ...shopStatusFields
      }
    }
  }
  ${ShopFragments.shop}
  ${ShopFragments.shopLogo}
  ${ShopFragments.shopPreferences}
  ${ShopStatusFragments.status}
`;

export const SHOP_IMAGE = gql`
  query Image($id: Int!) {
    image(id: $id) {
      id
      small {
        id
        location
      }
      medium {
        id
        location
      }
    }
  }
`;

export const SHOP_TECHNOLOGIES_WITH_MACHINES = gql`
  query ShopTechnologies($id: Int!) {
    shop(id: $id) {
      id
      shopTechnologies {
        ...shopTechnologyFields
        shopMachines(deleted: false) {
          ...shopMachineFields
        }
      }
    }
  }
  ${ShopTechnologyFragments.shopTechnology}
  ${ShopMachineFragments.shopMachine}
`;

export const SHOP_TECHNOLOGIES_WITH_DELETED_MACHINES = gql`
  query ShopTechnologiesWithDeletedMachines($id: Int!) {
    shop(id: $id) {
      id
      shopTechnologies {
        id
        shopMachines(deleted: true) {
          ...shopMachineFields
        }
      }
    }
  }
  ${ShopMachineFragments.shopMachine}
`;

export const SHOP_TECHNOLOGIES_WITH_MACHINE_IMAGES = gql`
  query ShopTechnologiesWithImages($id: Int!) {
    shop(id: $id) {
      id
      shopTechnologies {
        id
        shopMachines(deleted: false) {
          id
          image {
            id
            medium {
              id
              location
            }
          }
        }
      }
    }
  }
`;

export const SHOP_ORDER_LIST_COLUMNS = gql`
  query ShopOrderListColumns($id: Int!) {
    shop(id: $id) {
      id
      shopPreferences {
        columns
      }
    }
  }
`;

export const TOTAL_SHOPS_COUNT = gql`
  query AllShopsCount {
    allShopsCount
  }
`;

export const MATERIAL_RATE = gql`
  query materialRateById($id: Int!) {
    materialRateById(id: $id) {
      ...materialRateFields
    }
  }
  ${MaterialRateFields}
`;

export const MACHINE_RATE = gql`
  query machineRateById($id: Int!) {
    machineRateById(id: $id) {
      ...machineRateFields
    }
  }
  ${MachineRateFields}
`;

export const SHOP_OPERATORS = gql`
  query shopOperators($id: Int!) {
    shop(id: $id) {
      id
      operators {
        id
        name
        email
      }
    }
  }
`;
